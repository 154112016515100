import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { DeleteDepositionalWiki } from '~/components/upload/depositional/DeleteDepositionalWiki';
import { UpdateDepositionalWikiForm } from '~/components/upload/depositional/UpdateDepositionalWikiForm';
import { uploadGeologyRoute } from '~/paths';
import { useDepositionalWikiUpdateOutletContext } from '~/routes/upload/depositional/$depositionalId';

export default function DepositionalWikiUpdateOverviewRoute() {
  const { depositionalWiki } = useDepositionalWikiUpdateOutletContext();
  const navigate = useNavigate();

  function handleDeleteSuccess() {
    navigate(uploadGeologyRoute(), { replace: true });
  }

  return (
    <>
      <UpdateDepositionalWikiForm wikiPage={depositionalWiki} />

      <div className="text-center mt-6">
        <DeleteDepositionalWiki
          id={depositionalWiki.id}
          onDeleteSuccess={handleDeleteSuccess}
        >
          {(deleteWiki, loading) => (
            <Button
              type="button"
              variant="link"
              onClick={deleteWiki}
              disabled={loading}
              className="text-error hover:text-error gap-1"
            >
              <FontAwesomeIcon icon={faTrash} /> Delete Wiki Page
            </Button>
          )}
        </DeleteDepositionalWiki>
      </div>
    </>
  );
}
