import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CompanyPartsFragment,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { UPDATE_COMPANY } from '~/apollo/operations/company';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import CompanyFormFields from '~/components/company/CompanyFormFields';
import { useModalState } from '~/hooks/modal';
import type { CompanyFormValues } from '~/utils/modules/company';
import {
  companyValidationSchema,
  initialCompanyValues,
} from '~/utils/modules/company';

type Props = {
  children: (showModal: () => void) => JSX.Element;
  company: CompanyPartsFragment;
};

function UpdateCompanyModal({ children, company }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [updateCompany, { loading, error }] = useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UPDATE_COMPANY, {});

  async function handleSubmit(values: CompanyFormValues) {
    try {
      await updateCompany({
        variables: {
          id: company.id,
          company: values,
        },
      });
      toast.success('Company updated successfully.');
      hideModal();
    } catch (err) {
      console.log(err);
      toast.error('There was a problem updating the company.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialCompanyValues(company)}
          validationSchema={companyValidationSchema}
        >
          <Form>
            <Modal.Body heading="Edit Company">
              <CompanyFormFields isSSO={company.isAadConfigured} />
              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

export default UpdateCompanyModal;
