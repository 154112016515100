import { Navigate } from 'react-router';
import type { Role } from '~/apollo/generated/v3/graphql';
import { useAuth } from '~/contexts/auth';
import { forbiddenRoute, loginRoute } from '~/paths';

type Props = {
  roles?: Role[];
  children: JSX.Element;
};

export function Authorize({ roles, children }: Props) {
  const { authority, hasAnyRole } = useAuth();

  if (!authority) {
    return <Navigate to={loginRoute()} />;
  }

  if (roles && roles.length > 0 && !hasAnyRole(roles)) {
    return <Navigate to={forbiddenRoute()} />;
  }

  return children;
}
