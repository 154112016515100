import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-daisyui';
import { DiagramParent } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { DiagramRenderer } from '~/components/diagram/DiagramRenderer';
import { DeleteDiagram } from '~/components/upload/diagram/DeleteDiagram';
import { DiagramEditor } from '~/components/upload/diagram/DiagramEditor';
import { UploadDiagramImageForm } from '~/components/upload/diagram/UploadDiagramImageForm';
import { useDepositionalWikiUpdateOutletContext } from '~/routes/upload/depositional/$depositionalId';

export default function DepositionalUpdateDiagramRoute() {
  const { depositionalWiki, refetchQueries } =
    useDepositionalWikiUpdateOutletContext();
  const { id: wikiPageId, diagram } = depositionalWiki;

  const [hoveredAreaId, setHoveredAreaId] = React.useState<string>();

  const handleHover = (areaId?: string) => setHoveredAreaId(areaId);

  if (!diagram) {
    return (
      <div className="lg:w-1/2 mx-auto space-y-2">
        <Heading level={3}>Create a Diagram</Heading>
        <p>
          This wiki page doesn't have a diagram yet. Get started by uploading
          one below.
        </p>

        <UploadDiagramImageForm
          parentType={DiagramParent.DepositionalWiki}
          parentId={wikiPageId}
          refetchQueries={refetchQueries}
        />
      </div>
    );
  }

  return (
    <>
      <DiagramEditor
        diagram={diagram}
        onHover={handleHover}
        hoveredAreaId={hoveredAreaId}
      />

      <div className="text-center my-6">
        <DeleteDiagram diagramId={diagram.id} refetchQueries={refetchQueries}>
          {confirmDelete => (
            <Button
              type="button"
              variant="link"
              className="text-error hover:text-error gap-1"
              onClick={confirmDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
              Delete Diagram
            </Button>
          )}
        </DeleteDiagram>
      </div>

      <Panel>
        <Panel.Heading>
          <Panel.Title>Preview</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <DiagramRenderer diagram={diagram} />
        </Panel.Body>
      </Panel>
    </>
  );
}
