import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import type { FieldHookConfig } from 'formik';
import { useField } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import type {
  DiagramAreaPartsFragment,
  PolygonVertexInput,
} from '~/apollo/generated/v3/graphql';
import { Tooltip } from '~/components/common/Tooltip';

const round = (num: number) => Math.round(num * 10000) / 10000;
const toPercent = (num: number) => {
  const rounded = round(num);
  const to4Dec = rounded * 100;
  return parseFloat(to4Dec.toFixed(2));
};

type PolygonPoint = {
  x: number;
  y: number;
};

type Props = FieldHookConfig<PolygonVertexInput[]> & {
  areas: DiagramAreaPartsFragment[];
  image: string;
  hoveredAreaId?: DiagramAreaPartsFragment['id'];
  onHover: (areaId?: DiagramAreaPartsFragment['id']) => () => void;
  isDrawing: boolean;
};

export function DiagramPathDrawingField({
  areas,
  image,
  hoveredAreaId,
  onHover,
  isDrawing,
  ...props
}: Props) {
  const [, meta, helpers] = useField<PolygonVertexInput[]>(props);
  const path = meta.value;

  const container = React.useRef<HTMLDivElement>(null);

  function handleContainerClick(event: React.MouseEvent<HTMLDivElement>) {
    if (!container.current) return;

    const { clientX, clientY } = event;
    const rect = container.current.getBoundingClientRect();

    const rx = (clientX - rect.x) / rect.width;
    const ry = (clientY - rect.y) / rect.height;

    const coords: PolygonPoint = { x: toPercent(rx), y: toPercent(ry) };
    const nextPath = [...path, coords];
    console.log('Setting path to:', nextPath);
    helpers.setValue(nextPath);
  }

  function handleUndo(event: React.MouseEvent<HTMLButtonElement>) {
    // Prevent click event bubbling into container
    event.stopPropagation();

    // Remove last item in path
    helpers.setValue(path.slice(0, path.length - 1));
  }

  const clipPath = (p: PolygonVertexInput[]) =>
    p.map(({ x, y }) => `${x}% ${y}%`).join(', ');

  const hasPath = path.length > 0;

  return (
    <>
      <div
        ref={container}
        onClick={isDrawing ? handleContainerClick : () => {}}
        className={cn('relative max-w-full', {
          'cursor-crosshair': isDrawing,
        })}
      >
        {isDrawing && path.length > 0 && (
          <Tooltip
            message="Undo last point"
            className="absolute top-0 right-0 float-right p-2"
          >
            <Button type="button" size="xs" onClick={handleUndo}>
              <FontAwesomeIcon icon={faUndo} />
            </Button>
          </Tooltip>
        )}

        {/* Background image that can fade when drawing */}
        <img
          alt="diagram source image"
          src={image}
          style={{
            width: '100%',
            opacity: hasPath ? 0.3 : 1,
            filter: hasPath ? 'grayscale(50%)' : undefined,
          }}
        />

        {/* Show the clipping path of the drawing polygon */}
        {hasPath && (
          <div
            className="absolute top-0 left-0 right-0 bottom-0 bg-center bg-cover"
            style={{
              backgroundImage: `url(${image})`,
              clipPath: `polygon(${clipPath(path)})`,
            }}
          />
        )}

        {/* Show dots at each vertex of the drawing polygon */}
        {path.map(({ x, y }, i) => (
          <div
            key={i}
            className={cn('absolute inline-block h-2 w-2 rounded-md', {
              'bg-success': i === 0, // first point is green
              'bg-error': i === path.length - 1, // last point is red
              'bg-warning': i > 0 && i < path.length - 1, // all other points
            })}
            style={{
              top: `calc(${y}% - 3px)`,
              left: `calc(${x}% - 3px)`,
            }}
          />
        ))}

        {/* Show existing areas */}
        {areas.map(area => (
          <div
            key={area.id}
            onMouseOver={onHover(area.id)}
            onMouseOut={onHover()}
            className={cn(
              'absolute top-0 left-0 right-0 bottom-0 opacity-50 bg-secondary',
              { 'bg-secondary-focus': area.id === hoveredAreaId },
            )}
            style={{
              clipPath: `polygon(${clipPath(area.path)})`,
            }}
          />
        ))}
      </div>
    </>
  );
}
