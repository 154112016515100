import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router';
import { outcropSubregionParts } from '~/apollo/fragments';
import type {
  UploadOutcropSubregionsPageQuery,
  UploadOutcropSubregionsPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useSortFilter } from '~/hooks/data';
import {
  uploadOutcropSubregionCreateRoute,
  uploadOutcropSubregionUpdateRoute,
  uploadOutcropUpdateRoute,
} from '~/paths';

const UPLOAD_OUTCROP_SUBREGIONS_PAGE = gql`
  query UploadOutcropSubregionsPage {
    outcropSubregionList {
      ...outcropSubregionParts
      project {
        id
        name
      }
      outcrops {
        id
        name
      }
      author {
        id
        name
      }
    }
  }

  ${outcropSubregionParts}
`;

export function UploadOutcropSubregionsPage() {
  const { data, loading } = useQuery<
    UploadOutcropSubregionsPageQuery,
    UploadOutcropSubregionsPageQueryVariables
  >(UPLOAD_OUTCROP_SUBREGIONS_PAGE);

  const unsortedItems = data?.outcropSubregionList ?? [];

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    unsortedItems,
    'name',
    'name',
    'uploadOutcropSubregions',
  );

  type OM = UploadOutcropSubregionsPageQuery['outcropSubregionList'][number];
  type OMOutcrop = OM['outcrops'][number];

  function sortOutcrops(outcrops: OMOutcrop[]) {
    return R.sortBy(oc => oc.name, outcrops);
  }

  if (loading) {
    return <SpinnerPlaceholder />;
  }

  return (
    <>
      <Panel>
        <Panel.Heading>
          <div className="flex justify-between items-center gap-6">
            <Panel.Title>Outcrop Subregions</Panel.Title>

            <Link
              to={uploadOutcropSubregionCreateRoute()}
              className="btn btn-xs btn-primary"
            >
              <div className="space-x-2">
                <FontAwesomeIcon icon={faPlus} />
                <span>Create Subregion</span>
              </div>
            </Link>
          </div>
        </Panel.Heading>

        <Panel.Body>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>Outcrops</th>
                <th>
                  <SortTrigger
                    colName="author.name"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Author
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {items.map(sr => (
                <tr key={sr.id}>
                  <td>{sr.id}</td>
                  <td>
                    <Link
                      to={uploadOutcropSubregionUpdateRoute(sr.id)}
                      className="link"
                    >
                      {sr.name}
                    </Link>
                  </td>
                  <td>
                    {sortOutcrops(sr.outcrops).map(oc => (
                      <div key={oc.id}>
                        <Link
                          to={uploadOutcropUpdateRoute(oc.id)}
                          className="link"
                        >
                          {oc.name}
                        </Link>
                      </div>
                    ))}
                  </td>
                  <td>{sr.author.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
