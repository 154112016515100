import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import * as R from 'ramda';

import { fileParts, pictureParts } from '~/apollo/fragments';
import type {
  UploadVomUpdatePicturesTabQuery,
  UploadVomUpdatePicturesTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { PictureParent } from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { UploadVomPicturesModal } from '~/components/upload/vom/UploadPicturesModal';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';

import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { VomPictureManager } from '~/components/upload/vom/VomPicture';

const UPLOAD_VOM_UPDATE_PICTURES_TAB = gql`
  query UploadVomUpdatePicturesTab($id: Int!) {
    virtualOutcropModelList(id: $id) {
      id
      pictures {
        ...pictureParts
        file {
          ...fileParts
        }
      }
    }
  }

  ${pictureParts}
  ${fileParts}
`;

export default function VomPicturesPage() {
  const ctx = useUpdateVomOutletContext();
  const vomId = ctx.vom.id;

  const { data, loading } = useQuery<
    UploadVomUpdatePicturesTabQuery,
    UploadVomUpdatePicturesTabQueryVariables
  >(UPLOAD_VOM_UPDATE_PICTURES_TAB, {
    variables: { id: vomId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadVomUpdatePicturesTabQueryVariables>,
  ] = [
    {
      query: UPLOAD_VOM_UPDATE_PICTURES_TAB,
      variables: { id: vomId },
    },
  ];

  const vomList = data?.virtualOutcropModelList ?? [];
  const vom = vomList.find(vom => vom.id === vomId);
  const pictures = R.sortBy(R.prop('id'), vom?.pictures ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2 space-y-4">
        {pictures.map(p => (
          <VomPictureManager
            key={p.id}
            vomId={vomId}
            picture={p}
            refetchQueries={refetchQueries}
          />
        ))}

        {!pictures && (
          <i className="text-muted">No pictures have been added to this VOM.</i>
        )}
      </div>

      <div>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Upload Pictures</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="text-center">
              <UploadVomPicturesModal
                parentType={PictureParent.Vom}
                parentId={vom.id}
                refetchQueries={refetchQueries}
              >
                {showUploadModal => (
                  <Button
                    type="button"
                    color="primary"
                    onClick={showUploadModal}
                    className="gap-1"
                  >
                    <FontAwesomeIcon icon={faUpload} /> Upload Pictures
                  </Button>
                )}
              </UploadVomPicturesModal>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
