import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faPencil, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import * as fragments from '~/apollo/fragments';
import type { UploadPaleomapListPageQuery } from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import CreatePaleomapModal from '~/components/upload/paleomap/CreatePaleomapModal';
import { useSortFilter } from '~/hooks/data';
import * as routes from '~/paths';

const UPLOAD_PALEOMAP_LIST_PAGE = gql`
  query UploadPaleomapListPage {
    paleoMapList {
      ...paleoMapParts
      outcrops {
        id
        name
        region {
          id
          name
        }
      }
      age {
        ...lithostratAgeParts
      }
    }
  }

  ${fragments.paleoMapParts}
  ${fragments.lithostratAgeParts}
`;

export default function UploadPaleomapIndexRoute() {
  const { data, loading } = useQuery<UploadPaleomapListPageQuery>(
    UPLOAD_PALEOMAP_LIST_PAGE,
    {},
  );
  const paleoMapList = data?.paleoMapList ?? [];

  const refetchQueries: PureQueryOptions[] = [
    { query: UPLOAD_PALEOMAP_LIST_PAGE },
  ];

  type PaleoMapType = UploadPaleomapListPageQuery['paleoMapList'][number];
  type SortablePaleoMap = PaleoMapType & {
    outcropName: string | null;
    regionName: string | null;
    series: string | null;
    system: string | null;
  };
  const paleoMapsWithMeta: SortablePaleoMap[] = paleoMapList.map(pm => {
    const pmOutcrops = R.sortBy(oc => oc.name, pm.outcrops);
    const topOutcrop = pmOutcrops[0] ?? undefined;

    const outcropName = topOutcrop?.name ?? null;
    const regionName = topOutcrop?.region?.name ?? null;
    const series = pm.age?.series ?? '- none set -';
    const system = pm.age?.system ?? '- none set -';

    return { ...pm, outcropName, regionName, series, system };
  });

  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    paleoMapsWithMeta,
    'outcropName',
    'outcropName',
    'uploadPaleomapList',
  );

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Paleomaps</PageHeading>

      <Panel>
        <Panel.Heading className="flex justify-between">
          <Panel.Heading>
            Paleomaps
            <small className="text-muted" style={{ marginLeft: '10px' }}>
              (<strong>{items.length}</strong> results displayed)
            </small>
          </Panel.Heading>

          <CreatePaleomapModal refetchQueries={refetchQueries}>
            {showCreateModal => (
              <Button
                type="button"
                color="primary"
                size="xs"
                onClick={showCreateModal}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Create Paleomap
              </Button>
            )}
          </CreatePaleomapModal>
        </Panel.Heading>

        <Panel.Body>
          <FilterSearch {...filterSearchProps} />

          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>
                  <SortTrigger
                    colName="outcropName"
                    sortIndicatorProps={sortIndicatorProps}
                  >
                    Outcrops
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="regionName"
                    sortIndicatorProps={sortIndicatorProps}
                    filterable
                  >
                    Regions
                  </SortTrigger>
                </th>
                <th>Description</th>
                <th>
                  <SortTrigger
                    colName="system"
                    sortIndicatorProps={sortIndicatorProps}
                    filterable
                  >
                    System
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="series"
                    sortIndicatorProps={sortIndicatorProps}
                    filterable
                  >
                    Series
                  </SortTrigger>
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <NoItemsRow show={!items.length} colSpan={3} />

              {items.map(paleoMap => (
                <tr key={paleoMap.id}>
                  <td>
                    {paleoMap.outcrops?.map(outcrop => (
                      <div key={outcrop.id}>
                        <Link
                          to={routes.uploadOutcropUpdateRoute(outcrop.id)}
                          className="link"
                        >
                          {outcrop.name}
                        </Link>
                      </div>
                    ))}
                  </td>
                  <td>
                    {paleoMap.outcrops?.map(outcrop => (
                      <div key={outcrop.id}>
                        <Link
                          to={routes.uploadRegionUpdateRoute(outcrop.region.id)}
                          className="link"
                        >
                          {outcrop.region.name}
                        </Link>
                      </div>
                    ))}
                  </td>
                  <td>{paleoMap.description}</td>
                  <td>
                    {paleoMap.system === '- none set -' ? '' : paleoMap.system}
                  </td>
                  <td>
                    {paleoMap.series === '- none set -' ? '' : paleoMap.series}
                  </td>
                  <td className="text-right w-1/12">
                    <Link
                      to={routes.uploadPaleoMapUpdateRoute(paleoMap.id)}
                      className="btn btn-ghost btn-xs gap-1"
                    >
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
