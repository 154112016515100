import { useLazyQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { gql } from '~/apollo/client-v3';
import {
  StudyDataSourceType,
  type OutcropGeologyTypesQuery,
  type OutcropGeologyTypesQueryVariables,
  type StudyEnumerationsQuery,
} from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { FormikTagsinputField } from '~/components/common/FormikField/FormikTagsinputField';
import { FormikTinyMceField } from '~/components/common/FormikField/FormikTinyMceField';
import { Panel } from '~/components/common/Panel';
import { OutcropSelect } from '~/components/upload/outcrop/OutcropSelect';
import { ProjectSelect } from '~/components/upload/project/ProjectSelect';
import { StudyQualityParametersFormFields } from '~/components/upload/study/StudyQualityParametersFormFields';
import type { StudyFormValues } from '~/utils/modules/study';
import { snakeToHuman } from '~/utils/text';

const OUTCROP_GEOLOGY_TYPES = gql`
  query OutcropGeologyTypes($id: Int!) {
    outcropList(id: $id) {
      id
      geologyType
    }
  }
`;

type Props = {
  action: 'create' | 'update';
  enumOptions: StudyEnumerationsQuery;
  geologyTypes?: string[];
};

export function StudyOverviewFormFields({
  action,
  enumOptions,
  geologyTypes,
}: Props) {
  const { values, setFieldValue } = useFormikContext<StudyFormValues>();
  const isQCd = !!values.qcCompleted;
  const isRedundant = !!values.redundant;
  const showQCdToggle = !isQCd && isRedundant;

  const [showQCd, setShowQCd] = useState(showQCdToggle);

  const [loadOutcropGeologyTypes, { data, loading }] = useLazyQuery<
    OutcropGeologyTypesQuery,
    OutcropGeologyTypesQueryVariables
  >(OUTCROP_GEOLOGY_TYPES, {});

  const ocGeologyTypes =
    data?.outcropList.find(oc => oc.id === values.outcropId)?.geologyType ?? [];

  const domGTOptions = (geologyTypes ?? ocGeologyTypes ?? []).map(gt => ({
    value: gt,
    label: gt,
  }));

  useEffect(() => {
    if (isQCd || !isRedundant) {
      setShowQCd(false);
    }
  }, [action, geologyTypes, showQCdToggle, isQCd, isRedundant]);

  useEffect(() => {
    if (action === 'create' && !geologyTypes && values.outcropId) {
      loadOutcropGeologyTypes({ variables: { id: values.outcropId } });
    }
  }, [values.outcropId, action, geologyTypes, loadOutcropGeologyTypes]);

  useEffect(() => {
    const validOptions = domGTOptions.map(opt => opt.value);
    if (
      values.dominantGeologyType &&
      !validOptions.includes(values.dominantGeologyType)
    ) {
      setFieldValue('dominantGeologyType', '');
    }
  }, [domGTOptions, values.dominantGeologyType, setFieldValue]);

  return (
    <>
      <div className="space-y-2">
        <Field name="name" label="Name" component={FormikField} required />
        <Field
          name="type"
          label="Study Type"
          component={FormikField}
          type="select"
          options={enumOptions.studyType.values.map(opt => ({
            value: opt,
            label: opt,
          }))}
          required
        />
        <Field
          name="projectId"
          label="Project"
          component={FormikField}
          type={ProjectSelect}
          required
        />
        {/* Sets the first outcrop linked to the study */}
        {/* After create, outcrop links may be managed via the Outcrops tab */}
        {action === 'create' && (
          <Field
            name="outcropId"
            label="Outcrop"
            component={FormikField}
            type={OutcropSelect}
            required
          />
        )}

        <Field
          name="dominantGeologyType"
          label="Dominant geology type"
          component={FormikField}
          type="select"
          options={domGTOptions}
          disabled={loading || !domGTOptions.length}
        />

        <Field
          name="dataSourceType"
          label="Data Source Type"
          component={FormikField}
          type="select"
          options={Object.values(StudyDataSourceType).map(value => ({
            value,
            label: snakeToHuman(value).toLowerCase(),
          }))}
          required
        />

        <Field
          name="keywords"
          label="Keywords"
          component={FormikField}
          type={FormikTagsinputField}
          className="w-full"
          textField
        />

        <Field
          name="abstract"
          label="Abstract"
          component={FormikField}
          type={FormikTinyMceField}
        />

        <Field
          name="numericalDataAspects"
          label="Numerical data and other notable aspects"
          component={FormikField}
          type={FormikTinyMceField}
        />
        <Field
          name="keyReferences"
          label="Citation"
          component={FormikField}
          type={FormikTinyMceField}
        />
      </div>

      <div className="space-y-6 mt-6">
        <Panel>
          <Panel.Heading>
            <Panel.Title>Data History</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Field
              name="dataHistory.collectedBy"
              label="Collected by"
              helpText="Add the individual and or group who are responsible for this dataset."
              component={FormikField}
              required
            />
            <Field
              name="dataHistory.date"
              label="Date"
              helpText="The date when the study began"
              component={FormikField}
              type="number"
              placeholder="YYYY"
              required
            />

            <Field
              name="publicationLicense"
              label="Publication license"
              component={FormikField}
            />
          </Panel.Body>
        </Panel>

        <Panel>
          <Panel.Heading>
            <Panel.Title>Quality Parameters</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <StudyQualityParametersFormFields enumOptions={enumOptions} />
          </Panel.Body>
        </Panel>

        <Panel variant={values.approved ? 'success' : 'default'}>
          <Panel.Heading>
            <Panel.Title>Approval</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <p>
              Select whether this outcrop is ready to be reviewed and approved.
            </p>
            <Field
              name="readyForApproval"
              label="Ready for approval"
              component={FormikField}
              type="checkbox"
            />

            <p>
              Select to publish this study. Approved studies will are able to be
              indexed and viewed by Safari users.
            </p>

            <Field
              name="approved"
              label="Approved"
              component={FormikField}
              type="checkbox"
            />
          </Panel.Body>
        </Panel>

        <Panel variant={values.qcCompleted ? 'success' : 'default'}>
          <Panel.Heading>
            {showQCdToggle && (
              <Button
                color="ghost"
                size="xs"
                type="button"
                className="float-right"
                onClick={() => setShowQCd(!showQCd)}
              >
                {showQCd ? 'hide' : 'show'}
              </Button>
            )}

            <Panel.Title>Quality Control Completed</Panel.Title>
          </Panel.Heading>
          {(!showQCdToggle || (showQCdToggle && showQCd)) && (
            <Panel.Body>
              <p>Select to indicate that this study has been QC'd.</p>

              <Field
                name="qcCompleted"
                label="QC Completed"
                component={FormikField}
                type="checkbox"
              />
            </Panel.Body>
          )}
        </Panel>

        <Panel variant={values.redundant ? 'warning' : 'default'}>
          <Panel.Heading>
            <Panel.Title>Redundant</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <p>
              Select to indicate if this study has been merged and does not need
              to be QC'd.
            </p>

            <Field
              name="redundant"
              label="Study Merged"
              component={FormikField}
              type="checkbox"
            />
          </Panel.Body>
        </Panel>
      </div>
    </>
  );
}
