import { gql } from '~/apollo/client-v3';
import {} from '@apollo/client';
import { auditLogParts } from '~/apollo/fragments';
import type { AuditLogTableFragmentFragment } from '~/apollo/generated/v3/graphql';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import { snakeCapsToHuman } from '~/utils/text';

export const auditLogTableFragment = gql`
  fragment auditLogTableFragment on AuditLog {
    ...auditLogParts
    user {
      id
      name
      company {
        id
        name
      }
    }
  }

  ${auditLogParts}
`;

type Props = {
  auditLogs: AuditLogTableFragmentFragment[];
  sortStorageName: string;
};

export function AuditLogTable({ auditLogs, sortStorageName }: Props) {
  const { items, sortIndicatorProps } = useSortFilter(
    auditLogs,
    'insertedAt',
    'insertedAt',
    sortStorageName,
  );

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger
              colName="insertedAt"
              sortIndicatorProps={sortIndicatorProps}
            >
              Date
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="entity"
              filterable
              sortIndicatorProps={sortIndicatorProps}
              renderFilterOption={snakeCapsToHuman}
            >
              Entity
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="action"
              filterable
              sortIndicatorProps={sortIndicatorProps}
              renderFilterOption={snakeCapsToHuman}
            >
              Action
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="user.name"
              filterable
              sortIndicatorProps={sortIndicatorProps}
            >
              User
            </SortTrigger>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(auditLog => (
          <tr key={auditLog.id}>
            <td>{new Date(auditLog.insertedAt).toLocaleString()}</td>
            <td>{snakeCapsToHuman(auditLog.entity)}</td>
            <td>{snakeCapsToHuman(auditLog.action)}</td>
            <td>
              {auditLog.user && (
                <>
                  {auditLog.user?.name}{' '}
                  <div className="badge badge-outline border-slate-200 bg-slate-50 text-slate-400">
                    {auditLog.user.company.name}
                  </div>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
