import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  UpdateLithostratGroupMutation,
  UpdateLithostratGroupMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { UPDATE_LITHOSTRAT_GROUP } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { GroupFormFields } from '~/components/upload/lithostrat/group/GroupFormFields';
import { useGroupUpdateOutletContext } from '~/routes/upload/lithostrat/group/$groupId';
import type { GroupFormValues } from '~/utils/modules/lithostrat';
import {
  defaultGroup,
  formValuesToGroupInput,
  groupValidationSchema,
} from '~/utils/modules/lithostrat';

export default function GroupUpdateDetailsRoute() {
  const { group } = useGroupUpdateOutletContext();

  const [updateGroup, { loading, error }] = useMutation<
    UpdateLithostratGroupMutation,
    UpdateLithostratGroupMutationVariables
  >(UPDATE_LITHOSTRAT_GROUP, {});

  async function handleSubmit(values: GroupFormValues) {
    const groupInput = formValuesToGroupInput(values);
    try {
      await updateGroup({
        variables: {
          id: group.id,
          group: groupInput,
        },
      });
      toast.success('Group updated successfully.');
    } catch (err) {
      console.log('Error updating group', err);
      toast.error('There was a problem updating the group.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultGroup(group)}
      validationSchema={groupValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Group Details</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="space-y-4">
            <GroupFormFields />
            <FormErrors graphQLError={error} />
          </Panel.Body>

          <Panel.Footer className="text-right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
