import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import {
  faEye,
  faEyeSlash,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';

import * as fragments from '~/apollo/fragments';
import type {
  UpdateVomInterpretationsRouteQuery,
  UpdateVomInterpretationsRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteVomInterpretation } from '~/components/upload/vom/DeleteVomInterpretation';
import { PublishVomInterpretation } from '~/components/upload/vom/PublishVomInterpretation';
import { useSortFilter } from '~/hooks/data';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';
import { UpdateInterpretationModal } from '~/components/upload/vom/UpdateInterpretationModal';

export const VOM_INTERPRETATIONS = gql`
  query UpdateVomInterpretationsRoute($id: Int!) {
    virtualOutcropModelList(id: $id) {
      ...vomParts
      interpretations {
        ...interpretationParts
        study {
          ...studyParts
        }
      }
    }
  }

  ${fragments.vomParts}
  ${fragments.interpretationParts}
  ${fragments.studyParts}
`;

export default function UpdateVomInterpretationsRoute() {
  const ctx = useUpdateVomOutletContext();
  const vomId = ctx.vom.id;

  const { data, loading } = useQuery<
    UpdateVomInterpretationsRouteQuery,
    UpdateVomInterpretationsRouteQueryVariables
  >(VOM_INTERPRETATIONS, {
    variables: { id: vomId },
  });

  const refetchQueries: [
    PureQueryOptions<UpdateVomInterpretationsRouteQueryVariables>,
  ] = [
    {
      query: VOM_INTERPRETATIONS,
      variables: { id: vomId },
    },
  ];

  const vomList = data?.virtualOutcropModelList ?? [];
  const vom = vomList.find(v => v.id === vomId);
  const interpretations = vom?.interpretations ?? [];

  const { items, sortIndicatorProps } = useSortFilter(
    interpretations,
    'id',
    'study.name',
    'uploadVomInterpretationList',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!vom) return <NotFound />;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Virtual Outcrop Interpretations</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger
                  colName="id"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="study.name"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Study
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="published"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Published
                </SortTrigger>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <NoItemsRow show={!items.length} colSpan={4} />
            {items.map(interp => (
              <tr key={interp.id}>
                <td>{interp.id}</td>
                <td>{interp.study?.name}</td>
                <td className="text-center">
                  {interp.published ? (
                    <Tooltip message="Interpretation IS published">
                      <FontAwesomeIcon icon={faEye} className="text-success" />
                    </Tooltip>
                  ) : (
                    <Tooltip message="Interpretation is NOT published">
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        className="text-error"
                      />
                    </Tooltip>
                  )}
                </td>
                <td className="text-right space-x-1">
                  <UpdateInterpretationModal interpretation={interp}>
                    {showUpdateModal => (
                      <Button
                        type="button"
                        color="ghost"
                        size="xs"
                        onClick={showUpdateModal}
                        className="gap-1"
                      >
                        <FontAwesomeIcon icon={faPencil} /> Edit
                      </Button>
                    )}
                  </UpdateInterpretationModal>

                  <PublishVomInterpretation
                    interpretationId={interp.id}
                    isPublished={interp.published}
                  >
                    {confirmPublish => (
                      <Button
                        type="button"
                        color="ghost"
                        size="xs"
                        onClick={confirmPublish}
                        className="gap-1"
                        disabled={loading}
                      >
                        <FontAwesomeIcon
                          icon={interp.published ? faEyeSlash : faEye}
                        />{' '}
                        {interp.published ? 'Unpublish' : 'Publish'}
                      </Button>
                    )}
                  </PublishVomInterpretation>

                  <DeleteVomInterpretation
                    interpretationId={interp.id}
                    isPublished={interp.published}
                    refetchQueries={refetchQueries}
                  >
                    {showDeleteModal => (
                      <Tooltip message="Delete this interpretation and its files. A window will appear to confirm deletion.">
                        <Button
                          type="button"
                          color="ghost"
                          size="xs"
                          onClick={showDeleteModal}
                          disabled={interp.published}
                          className="gap-1"
                        >
                          <FontAwesomeIcon icon={faTrash} /> Delete...
                        </Button>
                      </Tooltip>
                    )}
                  </DeleteVomInterpretation>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
