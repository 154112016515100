import { gql } from '~/apollo/client-v3';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { machineTokenParts } from '~/apollo/fragments';
import type {
  CompanyAdminMachineTokenDetailPageQuery,
  CompanyAdminMachineTokenDetailPageQueryVariables,
  UpdateMachineTokenMutation,
  UpdateMachineTokenMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { MachineTokenFormFields } from '~/components/company/MachineTokenFormFields';
import { useAuth } from '~/contexts/auth';
import { companyAdminMachineTokensRoute } from '~/paths';
import type { MachineTokenFormValues } from '~/utils/modules/company';
import { machineTokenValidationSchema } from '~/utils/modules/company';

const COMPANY_ADMIN_MACHINE_TOKEN_DETAIL_PAGE = gql`
  query CompanyAdminMachineTokenDetailPage($companyId: Int!) {
    companyMachineTokens(companyId: $companyId, isDeleted: false) {
      ...machineTokenParts
    }
  }

  ${machineTokenParts}
`;

const UPDATE_MACHINE_TOKEN = gql`
  mutation UpdateMachineToken($id: Int!, $input: UpdateMachineTokenInput!) {
    updateMachineToken(id: $id, input: $input) {
      ...machineTokenParts
    }
  }

  ${machineTokenParts}
`;

export function CompanyAdminUpdateMachineTokenPage() {
  const navigate = useNavigate();

  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');
  const params = useParams<{ tokenId: string }>();
  invariant(params.tokenId);
  const tokenId = parseInt(params.tokenId);

  const { data, loading } = useQuery<
    CompanyAdminMachineTokenDetailPageQuery,
    CompanyAdminMachineTokenDetailPageQueryVariables
  >(COMPANY_ADMIN_MACHINE_TOKEN_DETAIL_PAGE, {
    variables: { companyId: authority.user.companyId },
  });
  const token = data?.companyMachineTokens.find(t => t.id === tokenId);

  const [updateMachineToken, { error, loading: loadingUpdate }] = useMutation<
    UpdateMachineTokenMutation,
    UpdateMachineTokenMutationVariables
  >(UPDATE_MACHINE_TOKEN);

  async function handleSubmit(values: MachineTokenFormValues) {
    invariant(token, 'Token could not be found');

    try {
      await updateMachineToken({
        variables: {
          id: token.id,
          input: values,
        },
      });
      toast.success('Token details updated successfully.');
      navigate(companyAdminMachineTokensRoute());
    } catch (err) {
      toast.error(
        'There was a problem updating the token details. Please try again.',
      );
    }
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!token) return <NotFound />;

  return (
    <Formik
      initialValues={{ description: token.description }}
      onSubmit={handleSubmit}
      validationSchema={machineTokenValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Update Machine Token Details</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <MachineTokenFormFields />
            <FormErrors graphQLError={error} />
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" loading={loadingUpdate}>
                Save Changes
              </Button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
