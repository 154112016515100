import type { PureQueryOptions } from '@apollo/client';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import type { FileParent } from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { SOFileUpload } from '~/components/upload/file/SOFileUpload';
import { useModalState } from '~/hooks/modal';

type Props = {
  outcropId?: number;
  studyId?: number;
  parentType: FileParent;
  parentId: number;
  refetchQueries: PureQueryOptions[];
};

export function SOFileUploadModal({
  outcropId,
  studyId,
  parentId,
  parentType,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      <Button
        type="button"
        color="ghost"
        size="xs"
        onClick={showModal}
        className="inline-block space-x-1"
      >
        <FontAwesomeIcon icon={faUpload} /> Upload
      </Button>
      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Upload File">
          <SOFileUpload
            parentType={parentType}
            parentId={parentId}
            refetchQueries={refetchQueries}
            // onUploadSuccess={() => {}}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
