import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faFileExport, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';

import type { UploadOutcropListPageQuery } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UploadOutcropList } from '~/components/upload/outcrop/UploadOutcropList';
import { useSortFilter } from '~/hooks/data';
import { exportOutcropsRoute, uploadOutcropCreateRoute } from '~/paths';
import { toSortableOutcrop } from '~/utils/modules/outcrop';

const UPLOAD_OUTCROP_LIST_PAGE = gql`
  query UploadOutcropListPage {
    outcropList {
      id
      name
      geologyType
      approved
      readyForApproval
      qcCompleted
      region {
        id
        name
        location {
          id
          country
        }
      }
      studies {
        id
      }
      paleoMaps {
        id
      }
      virtualOutcropModels {
        id
      }
    }
  }
`;

export default function OutcropListPage() {
  const { data, loading } = useQuery<UploadOutcropListPageQuery>(
    UPLOAD_OUTCROP_LIST_PAGE,
    {},
  );

  const outcropList = data?.outcropList ?? [];
  const sortableOutcrops = outcropList.map(toSortableOutcrop);

  const { items, filterSearchProps, sortIndicatorProps } = useSortFilter(
    sortableOutcrops,
    'name',
    'name',
    'uploadOutcropList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Outcrops
          {!loading && (
            <small className="text-muted" style={{ marginLeft: '10px' }}>
              (<strong>{items.length}</strong> results displayed)
            </small>
          )}
        </Panel.Title>

        <div className="space-x-2">
          <Link
            to={exportOutcropsRoute()}
            className="btn btn-ghost btn-xs gap-1"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFileExport} /> Export Outcrops
          </Link>

          <Link
            to={uploadOutcropCreateRoute()}
            className="btn btn-primary btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Create Outcrop
          </Link>
        </div>
      </Panel.Heading>
      <Panel.Body>
        {loading && <SpinnerPlaceholder />}
        {!loading && (
          <UploadOutcropList
            outcrops={items}
            sortIndicatorProps={sortIndicatorProps}
            filterSearchProps={filterSearchProps}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
