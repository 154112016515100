import type { ReactNode } from 'react';
import { createContext, Fragment, useContext, useState } from 'react';

type FullWidthContextValue = {
  isFullWidth: boolean;
  setIsFullWidth: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FullWidthContext = createContext<FullWidthContextValue>({
  isFullWidth: false,
  setIsFullWidth: () => {},
});

export function FullWidthProvider({ children }: { children: ReactNode }) {
  const [isFullWidth, setIsFullWidth] = useState(false);

  return (
    <FullWidthContext.Provider value={{ isFullWidth, setIsFullWidth }}>
      <Fragment>{children}</Fragment>
    </FullWidthContext.Provider>
  );
}

export function useFullWidthContext() {
  return useContext(FullWidthContext);
}
