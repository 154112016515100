import { cn } from '~/utils/common';
import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';

type Props = ComponentPropsWithoutRef<'div'> & {
  children: React.ReactNode;
  show?: boolean;
  stickTo: 'bottom' | 'top';
  stick?: boolean;
};

export function StickyBar({
  children,
  show = true,
  stickTo,
  stick = true,
  className,
  ...divProps
}: Props) {
  if (!show) return null;
  if (!stick) return <>{children}</>;

  return (
    <div
      {...divProps}
      className={cn(
        'sticky w-full p-2 glass flex justify-center items-center shadow-md',
        {
          'top-0': stickTo === 'top',
          'bottom-0': stickTo === 'bottom',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}
