import { gql } from '~/apollo/client-v3';
import { useMutation, useQuery } from '@apollo/client';
import { faCodeMerge, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useCallback, useState } from 'react';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  MergeStudiesMutation,
  MergeStudiesMutationVariables,
  MergeStudyListQuery,
  MergeStudyListQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { uploadStudyUpdateRoute } from '~/paths';
import { useUpdateStudyOutletContext } from '~/routes/upload/model/study/$studyId';

const MERGE_STUDY_LIST = gql`
  query MergeStudyList {
    outcropList {
      id
      name
      studies {
        id
        name
        type
      }
    }
  }
`;

const MERGE_STUDIES = gql`
  mutation MergeStudies($sourceId: Int!, $targetId: Int!) {
    mergeStudies(sourceId: $sourceId, targetId: $targetId) {
      id
    }
  }
`;

export default function MergeStudyRoute() {
  const { study } = useUpdateStudyOutletContext();
  useBreadcrumb('routes/upload/model/study/$studyId/merge', 'Merge Study');
  const navigate = useNavigate();

  const [targetId, setTargetId] = useState('');

  const { data, loading: loadingList } = useQuery<
    MergeStudyListQuery,
    MergeStudyListQueryVariables
  >(MERGE_STUDY_LIST, {});

  const [mergeStudies, { loading }] = useMutation<
    MergeStudiesMutation,
    MergeStudiesMutationVariables
  >(MERGE_STUDIES, {});

  const handleChange = (val: any) => {
    if (val?.value) {
      setTargetId(String(val.value));
    } else {
      setTargetId('');
    }
  };

  async function handleMerge() {
    const target = getTarget(targetId);
    invariant(target);

    try {
      const result = await mergeStudies({
        variables: {
          sourceId: study.id,
          targetId: target.id,
        },
      });
      invariant(result.data);

      toast.success('Studies merged successfully.');
      navigate(uploadStudyUpdateRoute(result.data.mergeStudies.id));
    } catch (e) {
      console.log('Error merging studies:', e);
      toast.error(
        'Something went wrong merging the studies. Please check the console for more information.',
      );
    }
  }

  const mergeStudyList = R.sortBy(R.prop('name'), data?.outcropList ?? []);

  const studyOptions = (
    studies: (typeof mergeStudyList)[number]['studies'],
  ) => {
    return R.pipe(
      () => studies.filter(s => s.type === study.type),
      R.sortBy(s => s.id),
      R.map(s => ({
        value: s.id,
        label: `(${s.id}) ${s.name}`,
        disabled: s.type !== study.type || s.id === study.id,
      })),
    )();
  };

  const getTarget = useCallback(
    (tid: string) => {
      if (!tid.length) return null;
      const studies = mergeStudyList
        .map(oc => oc.studies)
        .reduce((acc, cur) => acc.concat(cur), []);

      return studies.find(s => s.id === parseInt(tid)) ?? null;
    },
    [mergeStudyList],
  );

  // I cba to figure out react-select's option types right now, just 'any' it
  const options = mergeStudyList.map(oc => ({
    outcrop: oc.name,
    options: studyOptions(oc.studies),
  }));

  const target = getTarget(targetId);

  return (
    <>
      <PageHeading>Merge Study</PageHeading>

      <div className="grid lg:grid-cols-2 gap-6">
        <Panel variant="error">
          <Panel.Heading>
            <Panel.Title>Source Study</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="text-center mt-2 mb-8">
              <FontAwesomeIcon icon={faTrash} className="text-error text-5xl" />

              <p className="mt-2">
                The source study will be{' '}
                <strong className="text-error">PERMANENTLY DELETED</strong>:
              </p>

              <Heading level={4} className="my-2">
                {study.name}
              </Heading>
            </div>

            <div className="text-base space-y-2">
              <p>
                The study's description text and other basic properties such as
                quality parameters and data history fields will be deleted.
              </p>
              <p>
                All referenced objects will be moved to the target study,
                including:
              </p>

              <ul className="list-disc list-inside pl-2">
                <li>Links to outcrops</li>
                <li>Key parameters</li>
                <li>Georeferences</li>
                <li>Supporting objects (pictures, cross sections, etc.)</li>
                <li>Architectural elements &amp; (and their measurements)</li>
                <li>Lithostrat links</li>
                <li>Audit log history</li>
              </ul>
            </div>
          </Panel.Body>
        </Panel>

        <Panel variant="primary">
          <Panel.Heading>
            <Panel.Title>Target Study</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="text-center my-2">
              <FontAwesomeIcon
                icon={faCodeMerge}
                flip="horizontal"
                className="text-primary text-5xl"
              />

              <p className="mt-2">
                Select a target study that the source will be merged into.
              </p>
            </div>

            <ReactSelect
              isLoading={loadingList}
              isDisabled={loadingList}
              onChange={handleChange}
              options={options}
              // @ts-expect-error their types are garbage
              formatGroupLabel={group => group.outcrop}
              // @ts-expect-error their types are garbage
              isOptionDisabled={opt => opt.disabled}
              isSearchable
              isClearable
            />

            {target && (
              <div className="my-4 space-y-4">
                <p className="text-muted">
                  The source study will be merged into:
                </p>

                <Heading level={4}>{target.name}</Heading>

                <p>
                  This study <strong>will be unapproved</strong>, please review
                  it for correctness after merging.
                </p>

                <div className="text-center">
                  <Button
                    type="button"
                    color="primary"
                    size="lg"
                    onClick={handleMerge}
                    loading={loading}
                    startIcon={<FontAwesomeIcon icon={faCodeMerge} />}
                  >
                    Merge Studies
                  </Button>
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>
    </>
  );
}
