import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import type {
  CreateOutcropSubregionMutation,
  CreateOutcropSubregionMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { OutcropSubregionFormFields } from '~/components/upload/outcropSubregion/OutcropSubregionFormFields';
import { uploadOutcropSubregionUpdateRoute } from '~/paths';
import type { OutcropSubregionFormValues } from '~/utils/modules/outcropSubregion';
import {
  outcropSubregionFormValuesToInput,
  outcropSubregionInitialFormValues,
  outcropSubregionValidationSchema,
} from '~/utils/modules/outcropSubregion';

const CREATE_OUTCROP_SUBREGION = gql`
  mutation CreateOutcropSubregion($outcropSubregion: OutcropSubregionInput!) {
    createOutcropSubregion(outcropSubregion: $outcropSubregion) {
      id
    }
  }
`;

export function UploadOutcropSubregionCreatePage() {
  useBreadcrumb(
    'routes/upload/outcrop-subregions/create',
    'Create Outcrop Subregion',
  );

  const navigate = useNavigate();

  const [createSubregion, { loading, error }] = useMutation<
    CreateOutcropSubregionMutation,
    CreateOutcropSubregionMutationVariables
  >(CREATE_OUTCROP_SUBREGION);

  async function handleSubmit(values: OutcropSubregionFormValues) {
    try {
      const result = await createSubregion({
        variables: {
          outcropSubregion: outcropSubregionFormValuesToInput(values),
        },
      });
      const createdId = result.data?.createOutcropSubregion.id;
      if (createdId) {
        toast.success('Outcrop Subregion created successfully.');
        navigate(uploadOutcropSubregionUpdateRoute(createdId));
      }
    } catch (err) {
      console.log('Error creating Outcrop Subregion', err);
      toast.error('There was a problem creating the Outcrop Subregion.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={outcropSubregionInitialFormValues()}
      validationSchema={outcropSubregionValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Create Outcrop Subregion</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <OutcropSubregionFormFields />
              <FormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
