import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import {
  faFileExport,
  faFileImport,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import type {
  DeleteArchitecturalElementMutation,
  DeleteArchitecturalElementMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { CreateAEModal } from '~/components/upload/architecturalElement/CreateAEModal';
import { DeleteTrashedAEs } from '~/components/upload/architecturalElement/DeleteTrashedAEs';
import { ExportToCSVModal } from '~/components/upload/architecturalElement/ExportToCSVModal';
import { useSortFilter } from '~/hooks/data';
import {
  uploadStudyBulkAEImportRoute,
  uploadStudyBulkMeasurementImportRoute,
  uploadStudyUpdateAEDetailRoute,
} from '~/paths';
import { useStudyAEsOutletContext } from '~/routes/upload/model/study/$studyId/architectural-elements';

export const DELETE_AE_MUTATION = gql`
  mutation DeleteArchitecturalElement($id: Int!) {
    deleteArchitecturalElement(id: $id)
  }
`;

export default function UploadStudyAEsIndexRoute() {
  const ctx = useStudyAEsOutletContext();

  const { items, sortIndicatorProps } = useSortFilter(
    ctx.architecturalElements,
    'name',
    'name',
    'uploadAEList',
  );

  const [deleteArchitecturalElement, { loading }] = useMutation<
    DeleteArchitecturalElementMutation,
    DeleteArchitecturalElementMutationVariables
  >(DELETE_AE_MUTATION, {
    refetchQueries: ctx.refetchQueries,
  });

  const handleDelete = (aeId: number) => async () => {
    try {
      await deleteArchitecturalElement({ variables: { id: aeId } });
      toast.success('Architectural element deleted.');
    } catch (err) {
      console.log('Error deleting AE.');
      toast.error('There was a problem deleting the selected AE.');
    }
  };

  const numTrashed = items.filter(ae => ae.isTrashed === true).length;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>Architectural Elements</Panel.Title>

        <div className="space-x-1">
          <ExportToCSVModal
            studyId={ctx.study.id}
            aes={ctx.architecturalElements}
          >
            {showExportModal => (
              <Button
                type="button"
                color="primary"
                size="xs"
                onClick={showExportModal}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faFileExport} /> Export to CSV
              </Button>
            )}
          </ExportToCSVModal>

          <Link
            to={uploadStudyBulkAEImportRoute(ctx.study.id)}
            className="btn btn-xs btn-primary gap-1"
          >
            <FontAwesomeIcon icon={faFileImport} /> Import AEs
          </Link>
          <Link
            to={uploadStudyBulkMeasurementImportRoute(ctx.study.id)}
            className="btn btn-xs btn-primary gap-1"
          >
            <FontAwesomeIcon icon={faFileImport} /> Import Measurements
          </Link>

          <CreateAEModal
            studyId={ctx.study.id}
            outcrops={ctx.outcrops}
            refetchQueries={ctx.refetchQueries}
          >
            {showModal => (
              <Button
                type="button"
                color="primary"
                size="xs"
                onClick={showModal}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faPlus} /> Create new AE
              </Button>
            )}
          </CreateAEModal>
        </div>
      </Panel.Heading>

      <Panel.Body className="space-y-4">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger
                  colName="name"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="outcrop.name"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Outcrop
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="geologyType"
                  sortIndicatorProps={sortIndicatorProps}
                  filterable
                >
                  Geology Type
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="grossDepositionalEnvironment"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  <Tooltip message="Gross Depositional Environment">
                    <span>GDE</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="depositionalEnvironment"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  <Tooltip message="Depositional Environment">
                    <span>DE</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="depositionalSubEnvironment"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  <Tooltip message="Subenvironment">
                    <span>DSE</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="architecturalElement"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  <Tooltip message="Architectural Element">
                    <span>AE</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="measurements"
                  sortIndicatorProps={sortIndicatorProps}
                >
                  Measurements
                </SortTrigger>
              </th>
              <th />
            </tr>
          </thead>

          <tbody>
            {items.map(ae => (
              <tr key={ae.id}>
                <td>
                  <Link
                    to={uploadStudyUpdateAEDetailRoute(ctx.study.id, ae.id)}
                    className={cn('', {
                      link: !ae.isTrashed,
                      'text-error hover:underline': ae.isTrashed,
                    })}
                  >
                    {ae.name}
                  </Link>
                </td>
                <td>{ae.outcrop?.name}</td>
                <td>{ae.geologyType}</td>
                <td>{ae.grossDepositionalEnvironment}</td>
                <td>{ae.depositionalEnvironment}</td>
                <td>{ae.depositionalSubEnvironment}</td>
                <td>{ae.architecturalElement}</td>
                <td className="text-center">
                  <Link
                    to={{
                      pathname: uploadStudyUpdateAEDetailRoute(
                        ctx.study.id,
                        ae.id,
                      ),
                      hash: '#measurements',
                    }}
                    className="link"
                  >
                    {ae.measurementCount}
                  </Link>
                </td>
                <td>
                  <Confirm
                    onConfirm={handleDelete(ae.id)}
                    text="Deleting this AE will also delete all of its measurements. Are you sure you want to proceed?"
                  >
                    {showConfirm => (
                      <Tooltip message="Delete AE and its measurements">
                        <Button
                          type="button"
                          color="ghost"
                          size="xs"
                          onClick={showConfirm}
                          disabled={loading}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Tooltip>
                    )}
                  </Confirm>
                </td>
              </tr>
            ))}

            <NoItemsRow colSpan={9} show={!items.length}>
              No architectural elements have been created yet.
            </NoItemsRow>
          </tbody>
        </table>

        <div className="text-center">
          <DeleteTrashedAEs
            studyId={ctx.study.id}
            numTrashed={numTrashed}
            refetchQueries={ctx.refetchQueries}
          >
            {confirmDelete => (
              <button
                type="button"
                className="btn btn-error"
                onClick={confirmDelete}
              >
                <FontAwesomeIcon icon={faTrash} /> Empty Trash
              </button>
            )}
          </DeleteTrashedAEs>
        </div>
      </Panel.Body>
    </Panel>
  );
}
