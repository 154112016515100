import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import {
  faCancel,
  faInfoCircle,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { Alert, Button } from 'react-daisyui';
import { fieldPictureParts, fileParts } from '~/apollo/fragments';
import {
  type UploadOutcropFieldPicsPageQuery,
  type UploadOutcropFieldPicsPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { BulkFieldPictureUploader } from '~/components/upload/fieldPicture/BulkFieldPictureUploader';
import { FieldPictureCard } from '~/components/upload/supportingObject/fieldPicture/FieldPictureCard';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

const UPLOAD_OUTCROP_UPDATE_FIELD_PICS_PAGE = gql`
  query UploadOutcropFieldPicsPage($outcropId: Int!) {
    outcropList(id: $outcropId) {
      id
      fieldPictures {
        ...fieldPictureParts
        file {
          ...fileParts
        }
      }
    }
  }

  ${fieldPictureParts}
  ${fileParts}
`;

export default function UploadOutcropFieldPicsRoute() {
  const { outcrop } = useUploadOutcropUpdateRouteOutletContext();
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);

  const { data, loading, refetch } = useQuery<
    UploadOutcropFieldPicsPageQuery,
    UploadOutcropFieldPicsPageQueryVariables
  >(UPLOAD_OUTCROP_UPDATE_FIELD_PICS_PAGE, {
    variables: { outcropId: outcrop.id },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropFieldPicsPageQueryVariables>,
  ] = [
    {
      query: UPLOAD_OUTCROP_UPDATE_FIELD_PICS_PAGE,
      variables: { outcropId: outcrop.id },
    },
  ];

  const fps =
    data?.outcropList.find(oc => oc.id === outcrop.id)?.fieldPictures ?? [];
  const fieldPics = R.sortBy(R.pathOr(Infinity, ['file', 'name']), fps);

  if (loading) return <SpinnerPlaceholder />;

  const uploadLinkSearchParams = new URLSearchParams();
  uploadLinkSearchParams.append('outcropId', String(outcrop.id));

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading className="flex justify-between items-center">
          <Panel.Title>Upload Field Pictures</Panel.Title>

          <Button
            size="xs"
            color={isUploaderVisible ? 'ghost' : 'primary'}
            onClick={() => setIsUploaderVisible(!isUploaderVisible)}
            startIcon={
              isUploaderVisible ? (
                <FontAwesomeIcon icon={faCancel} />
              ) : (
                <FontAwesomeIcon icon={faUpload} />
              )
            }
          >
            {isUploaderVisible ? 'Hide Uploader' : 'Upload Field Pictures'}
          </Button>
        </Panel.Heading>

        {isUploaderVisible && (
          <Panel.Body>
            <BulkFieldPictureUploader
              outcropId={outcrop.id}
              onUploadSuccess={refetch}
            />
          </Panel.Body>
        )}
      </Panel>

      {!fieldPics.length && (
        <Alert>
          <FontAwesomeIcon icon={faInfoCircle} /> No field pictures have been
          added to this outcrop.
        </Alert>
      )}

      {fieldPics.map(fp => (
        <FieldPictureCard
          key={fp.id}
          fieldPicture={fp}
          refetchQueries={refetchQueries}
        />
      ))}
    </div>
  );
}
