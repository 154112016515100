import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-daisyui';
import { Link, Outlet, useOutletContext, useParams } from 'react-router';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  LithostratFormationPageQuery,
  LithostratFormationPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
  ReviewCommentParentType,
  Role,
} from '~/apollo/generated/v3/graphql';
import { RoleSecured } from '~/components/auth/RoleSecured';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { TargetReviewCommentsModal } from '~/components/reviewComment/TargetReviewCommentsModal';
import { LithostratTypeBacklink } from '~/components/wiki/lithostratType/LithostratTypeBacklink';
import {
  lithostratFormationAnaloguesRoute,
  lithostratFormationExamplesRoute,
  lithostratFormationRoute,
  uploadLithostratFormationUpdateWikiRoute,
} from '~/paths';

const LITHOSTRAT_FORMATION_PAGE = gql`
  query LithostratFormationPage($id: Int!) {
    lithostratFormationList(id: $id, hasWiki: true) {
      ...lithostratFormationParts
      lithostratType {
        ...lithostratTypeParts
      }
      wikiPage {
        ...lithostratWikiPageParts
        keyParameters {
          ...keyParametersParts
        }
      }

      formationAbove {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      formationBelow {
        ...lithostratFormationParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      group {
        ...lithostratGroupParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      members {
        ...lithostratMemberParts
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      outcropLinkAnalogues {
        ...outcropLinkAnalogueParts
      }
    }
  }

  ${fragments.lithostratFormationParts}
  ${fragments.lithostratTypeParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratMemberParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.outcropLinkAnalogueParts}
  ${fragments.keyParametersParts}
`;

type OutletContext = {
  formation: LithostratFormationPageQuery['lithostratFormationList'][number];
};

export default function LithostratFormationRoute() {
  const params = useParams();
  invariant(params.formationId, 'formationId param required');
  const formationId = parseInt(params.formationId);

  const { data, loading } = useQuery<
    LithostratFormationPageQuery,
    LithostratFormationPageQueryVariables
  >(LITHOSTRAT_FORMATION_PAGE, {
    variables: { id: formationId },
  });

  const formation = data?.lithostratFormationList.find(
    fm => fm.id === formationId,
  );

  useBreadcrumb(
    'routes/wiki/regional/lithostrat/formation.$formationId',
    formation?.name ?? 'Formation',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!formation) return <NotFound />;

  const outletContext: OutletContext = {
    formation,
  };

  return (
    <>
      {!formation.wikiPage?.published && (
        <Alert status="info" className="block p-4">
          <Heading level={3}>Notice</Heading>
          <p>
            Until this formation is approved, it is only visible to you and
            Safari administrators.
          </p>
        </Alert>
      )}

      <div className="float-right space-x-1">
        <TargetBookmarksManagerModal
          parentId={formation.id}
          parentType={BookmarkParentType.Formation}
          targetType={BookmarkTargetType.Formation}
          targetId={formation.id}
          path={lithostratFormationRoute(formation.id)}
        />

        <TargetReviewCommentsModal
          parentType={ReviewCommentParentType.LithostratFormation}
          parentId={formation.id}
          name={formation.name}
        />

        <RoleSecured roles={[Role.RoleAdmin]}>
          <Link
            to={uploadLithostratFormationUpdateWikiRoute(formation.id)}
            className="btn btn-ghost btn-sm gap-1"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </RoleSecured>
      </div>

      <LithostratTypeBacklink />

      <PageHeading hasSubtitle>{formation.name}</PageHeading>
      <p className="my-4">{formation.description}</p>

      <NavTabs className="mb-2">
        <NavTabs.Tab to={lithostratFormationRoute(formation.id)} end>
          Formation
        </NavTabs.Tab>

        {formation.outcropLinkAnalogues.length > 0 && (
          <NavTabs.Tab to={lithostratFormationExamplesRoute(formation.id)}>
            Examples
          </NavTabs.Tab>
        )}

        <NavTabs.Tab to={lithostratFormationAnaloguesRoute(formation.id)}>
          Linked Analogues
        </NavTabs.Tab>
      </NavTabs>

      <Outlet context={outletContext} />
    </>
  );
}

export function useFormationDetailOutletContext() {
  return useOutletContext<OutletContext>();
}
