import type { PureQueryOptions } from '@apollo/client';
import {
  faArrowDown,
  faArrowUp,
  faBookmark,
  faFileText,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import {
  ReportItemType,
  type EditReportPageQuery,
} from '~/apollo/generated/v3/graphql';
import { BookmarkIcon } from '~/components/bookmark/BookmarkIcon';
import { Confirm } from '~/components/common/Confirm';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { ReportEditorEditItemModal } from '~/components/report/ReportEditor/EditItemModal';
import { RemoveItem } from '~/components/report/ReportEditor/RemoveItem';
import { prettyTargetType } from '~/utils/modules/bookmark';
import { reportItemTitle } from '~/utils/modules/report';
import { ucwords } from '~/utils/text';

export type ReportItem =
  EditReportPageQuery['reportList'][number]['items'][number];

type Props = {
  reportId: number;
  item: ReportItem;
  onMoveUp: (itemId: number) => void;
  onMoveDown: (itemId: number) => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
  isLastTouched: boolean;
  isDirty: boolean;
  refetchQueries: PureQueryOptions[];
  disabled: boolean;
};

export function ReportEditorItem({
  reportId,
  item,
  onMoveUp,
  onMoveDown,
  canMoveUp,
  canMoveDown,
  isLastTouched,
  isDirty,
  refetchQueries,
  disabled,
}: Props) {
  const handleMoveUp = () => onMoveUp(item.id);
  const handleMoveDown = () => onMoveDown(item.id);

  return (
    <div
      className={cn('grid grid-cols-12', {
        'p-0.5 border-2 border-dashed border-blue-200 bg-slate-50':
          isLastTouched,
      })}
    >
      {!disabled && (
        <div className="p-2 flex items-center justify-center">
          <div className="space-y-1">
            <div>
              <Button
                type="button"
                color="success"
                size="sm"
                onClick={handleMoveUp}
                disabled={!canMoveUp || disabled}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
            </div>
            <div>
              <Button
                type="button"
                color="warning"
                size="sm"
                onClick={handleMoveDown}
                disabled={!canMoveDown || disabled}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className={disabled ? 'col-span-12' : 'col-span-11'}>
        <Panel>
          <Panel.Body>
            <ReportEditorItemContent item={item} />
          </Panel.Body>

          {!disabled && (
            <Panel.Footer>
              <div className="text-right space-x-1">
                <ReportEditorEditItemModal item={item}>
                  {showEditModal => (
                    <Button
                      type="button"
                      onClick={showEditModal}
                      color="ghost"
                      size="xs"
                      startIcon={<FontAwesomeIcon icon={faPencil} />}
                      disabled={isDirty}
                    >
                      Edit
                    </Button>
                  )}
                </ReportEditorEditItemModal>

                <RemoveItem
                  reportId={reportId}
                  reportItemId={item.id}
                  refetchQueries={refetchQueries}
                >
                  {(removeItem, loading) => (
                    <Confirm
                      onConfirm={removeItem}
                      text="This item will be removed from the report."
                    >
                      {confirmRemove => (
                        <Button
                          type="button"
                          onClick={confirmRemove}
                          color="ghost"
                          size="xs"
                          startIcon={<FontAwesomeIcon icon={faTrash} />}
                          loading={loading}
                          disabled={loading || isDirty}
                        >
                          Remove
                        </Button>
                      )}
                    </Confirm>
                  )}
                </RemoveItem>
              </div>
            </Panel.Footer>
          )}
        </Panel>
      </div>
    </div>
  );
}

function ReportItemIcon({ item }: { item: ReportItem }) {
  switch (item.itemType) {
    case ReportItemType.Bookmark:
      if (item.bookmark) return <BookmarkIcon bookmark={item.bookmark} />;
      else return <FontAwesomeIcon icon={faBookmark} />;
    case ReportItemType.Text:
      return <FontAwesomeIcon icon={faFileText} />;
  }
}

function ReportEditorItemContent({ item }: { item: ReportItem }) {
  function reportItemLink() {
    const itemTitle = reportItemTitle(item);

    if (ReportItemType.Bookmark && item.bookmark) {
      return (
        <Link
          to={item.bookmark.path}
          target="_blank"
          className="hover:underline"
        >
          {itemTitle}
        </Link>
      );
    } else {
      return itemTitle;
    }
  }

  return (
    <>
      <div className="float-right flex justify-end items-center gap-1">
        <div className="text-right">
          {item.itemType === ReportItemType.Bookmark && item.bookmark && (
            <div className="text-slate-500 text-base pl-2 pb-2">
              <div>
                {prettyTargetType(
                  item.bookmark.targetType,
                  item.bookmark.parentType,
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Heading level={4}>
        <span className="mr-2">
          <Tooltip message={ucwords(item.itemType)}>
            <ReportItemIcon item={item} />
          </Tooltip>
        </span>
        {reportItemLink()}
      </Heading>

      <p className="whitespace-pre-line">{item.text}</p>
    </>
  );
}
