import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  PictureParent,
  UpdatePictureMutation,
  UpdatePictureMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { UPDATE_PICTURE } from '~/apollo/operations/supportingObject';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { Tooltip } from '~/components/common/Tooltip';
import type { ShowModalFn } from '~/hooks/modal';
import { useModalState } from '~/hooks/modal';
import type { PictureFormValues } from '~/utils/modules/supportObject';
import {
  formValuesToPictureInput,
  initialPicture,
} from '~/utils/modules/supportObject';
import { PictureFormFields } from './PictureFormFields';
import type { Picture } from './SupportingObjectPictureList';

type Props = {
  parentType: PictureParent;
  parentId: number;
  picture: Picture;
  refetchQueries: PureQueryOptions[];
  children?: (showModal: ShowModalFn) => React.ReactNode;
};

export function SupportingObjectPictureModal({
  parentType,
  parentId,
  picture,
  refetchQueries,
  children,
}: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [updatePicture, { loading, error }] = useMutation<
    UpdatePictureMutation,
    UpdatePictureMutationVariables
  >(UPDATE_PICTURE, {
    refetchQueries,
  });

  async function handleSubmit(values: PictureFormValues) {
    const input = formValuesToPictureInput(values);

    try {
      await updatePicture({
        variables: {
          id: picture.id,
          picture: input,
        },
      });
      toast.success('Picture updated successfully.');
      hideModal();
    } catch (err) {
      console.log('Error updating picture', err);
      toast.error('There was a problem updating the picture.');
    }
  }

  return (
    <>
      {children ? (
        children(showModal)
      ) : (
        <Tooltip message="Edit details">
          <Button type="button" color="ghost" size="xs" onClick={showModal}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </Tooltip>
      )}

      <span className="text-left">
        <Modal open={show} onHide={hideModal} size="lg">
          <Formik
            initialValues={initialPicture(picture)}
            onSubmit={handleSubmit}
          >
            <Form>
              <Modal.Body heading="Edit Picture">
                <div className="space-y-2">
                  <PictureFormFields
                    parentType={parentType}
                    parentId={parentId}
                  />
                  <FormErrors graphQLError={error} />

                  <div className="text-center space-x-2">
                    <Button type="button" color="ghost" onClick={hideModal}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" loading={loading}>
                      Save
                    </Button>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="thumbnail">
                  <img src={picture.file.signedUrl} alt="Picture thumbnail" />
                </div>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
      </span>
    </>
  );
}
