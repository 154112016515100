import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import {
  type SearchOutcropsOptionsQuery,
  type SearchOutcropsOptionsQueryVariables,
  type SearchOutcropsOutcropResultsQuery,
  type SearchOutcropsOutcropResultsQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { SEARCH_OUTCROPS_OPTIONS } from '~/apollo/operations/analogueSearch';
import { Tooltip } from '~/components/common/Tooltip';
import { OutcropResultMap } from '~/components/outcrop/AnalogueSearchForm/OutcropResultMap';
import { OutcropResults } from '~/components/outcrop/AnalogueSearchForm/OutcropResults';
import { ResultTypeField } from '~/components/outcrop/AnalogueSearchForm/ResultTypeField';
import { SearchFilters } from '~/components/outcrop/AnalogueSearchForm/SearchFilters';
import { useImperativeQuery } from '~/hooks/apollo';
import { useSortFilter } from '~/hooks/data';
import { useDebounce } from '~/hooks/debounce';
import type {
  AnalogueSearchFormValues,
  AnalogueSearchOptions,
} from '~/utils/modules/analogueSearch';
import {
  defaultAnalogueSearch,
  defaultAnalogueSearchOptions,
  updateCounts,
} from '~/utils/modules/analogueSearch';
import { isGlobalRegion } from '~/utils/modules/region';

const SEARCH_OUTCROPS_OUTCROP_RESULTS = gql`
  query SearchOutcropsOutcropResults(
    $grossDepositionalEnvironment: [String!]
    $depositionalEnvironment: [String!]
    $depositionalSubEnvironment: [String!]
    $architecturalElement: [String!]
    $climate: [String!]
    $basinType: [String!]
    $outcropCategory: [String!]
    $supportingObjectTypes: [String!]
    $geologyAge: [String!]
    $geologyType: [String!]
    $country: [String!]
    $textSearch: String
    $netToGross: [String!]
    $distanceToSourceArea: [String!]
  ) {
    searchOutcrops(
      grossDepositionalEnvironment: $grossDepositionalEnvironment
      depositionalEnvironment: $depositionalEnvironment
      depositionalSubEnvironment: $depositionalSubEnvironment
      architecturalElement: $architecturalElement
      climate: $climate
      basinType: $basinType
      outcropCategory: $outcropCategory
      supportingObjectTypes: $supportingObjectTypes
      geologyAge: $geologyAge
      geologyType: $geologyType
      country: $country
      textSearch: $textSearch
      netToGross: $netToGross
      distanceToSourceArea: $distanceToSourceArea
    ) {
      outcrops {
        id
        name
        shortDescription
        geologyType
        outcropCategory
        thumbnail {
          id
          signedUrl
        }
        region {
          id
          name
          location {
            id
            country
          }
        }
        center {
          lat
          lng
        }
      }
    }
  }
`;

type Props = {
  onSearchChange?: (query: AnalogueSearchFormValues) => void;
};

export function SearchOutcrops({ onSearchChange }: Props) {
  const { values, resetForm, dirty } =
    useFormikContext<AnalogueSearchFormValues>();
  const [options, setOptions] = useState<AnalogueSearchOptions>(
    defaultAnalogueSearchOptions(),
  );
  const [outcropIdsInView, setOutcropIdsInView] = useState<number[]>([]);

  const debouncedValues = useDebounce(values, 50);

  useEffect(() => {
    if (onSearchChange && dirty) {
      onSearchChange(debouncedValues);
    }
  }, [debouncedValues, onSearchChange, dirty]);

  const [loadOutcrops, { data: dataOutcrops, loading: loadingOutcrops }] =
    useImperativeQuery<
      SearchOutcropsOutcropResultsQuery,
      SearchOutcropsOutcropResultsQueryVariables
    >(SEARCH_OUTCROPS_OUTCROP_RESULTS, { variables: debouncedValues });

  const { loading: loadingOptions } = useQuery<
    SearchOutcropsOptionsQuery,
    SearchOutcropsOptionsQueryVariables
  >(SEARCH_OUTCROPS_OPTIONS, {
    variables: values,
    onCompleted: result => {
      setOptions(updateCounts(options, result.searchOutcrops.queryOptions));
      loadOutcrops();
    },
  });

  const ocResults = dataOutcrops?.searchOutcrops.outcrops ?? [];
  const {
    items: outcrops,
    sortIndicatorProps,
    onSortChange,
  } = useSortFilter(ocResults, 'name', 'name', 'searchOutcrops');

  // Global outcrops should be hidden from the map, but shown in result list
  const globalOutcrops = outcrops.filter(oc => isGlobalRegion(oc.region));
  const globalOutcropIds = globalOutcrops.map(oc => oc.id);
  const regionalOutcrops = outcrops.filter(
    oc => !globalOutcropIds.includes(oc.id),
  );

  const visibleOutcrops = outcrops.filter(oc =>
    outcropIdsInView.includes(oc.id),
  );

  const outcropResultList = visibleOutcrops.concat(globalOutcrops);

  const handleReset = () => resetForm({ values: defaultAnalogueSearch() });

  return (
    <>
      <div className="text-right mt-4 mb-2">
        <Tooltip message="Reset all search filters to default">
          <Button
            type="button"
            color="ghost"
            size="sm"
            onClick={handleReset}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faFilterCircleXmark} /> Reset Filters
          </Button>
        </Tooltip>
      </div>

      <div className="grid sm:grid-cols-2 gap-6">
        <div>
          <SearchFilters options={options} loading={loadingOptions} />
        </div>

        <div className="space-y-4">
          <OutcropResultMap
            outcrops={regionalOutcrops}
            loading={loadingOutcrops}
            setOutcropIdsInView={setOutcropIdsInView}
          />

          <ResultTypeField loading={loadingOptions} />

          <OutcropResults
            outcrops={outcropResultList}
            loading={loadingOutcrops}
            sortIndicatorProps={sortIndicatorProps}
            onSortChange={onSortChange}
          />
        </div>
      </div>
    </>
  );
}
