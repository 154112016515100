import {
  faExternalLink,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';

import type { PureQueryOptions } from '@apollo/client';
import { Button } from 'react-daisyui';
import type { BookmarkCollectionPartsFragment } from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { Tooltip } from '~/components/common/Tooltip';
import { useAuth } from '~/contexts/auth';
import { bookmarkCollectionRoute } from '~/paths';
import type { Bookmark } from '~/utils/modules/bookmark';
import { mayAddBookmarkToCollection } from '~/utils/modules/bookmark';
import { AddToCollection } from './AddToCollection';
import { CreateCollectionAndAdd } from './CreateCollectionAndAdd';
import { RemoveBookmarkFromCollection } from './RemoveBookmarkFromCollection';
import { ListGroup } from '~/components/common/ListGroup';

export type Props = {
  bookmark: Bookmark;
  /**
   * Collections that are valid for the bookmark to be added to based on whether
   * the bookmark belongs to a user or a company. Includes collections in which
   * this bookmark is already a member.
   */
  collections: BookmarkCollectionPartsFragment[];
  refetchQueries: PureQueryOptions[];
};

export function BookmarkCollectionManager({
  bookmark,
  collections,
  refetchQueries,
}: Props) {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be authenticated.');

  const currentCollections = bookmark.collections;
  const availableCollections = collections.filter(
    c => !currentCollections.find(cur => cur.id === c.id),
  );

  return (
    <>
      <div className="p-4 space-y-2">
        {currentCollections.length > 0 && (
          <div className="space-y-2">
            <Heading level={4}>Current Collections</Heading>
            <ListGroup>
              {currentCollections.map(collection => (
                <ListGroup.Item
                  key={collection.id}
                  className="flex gap-1 justify-between items-center hover:bg-white p-1"
                >
                  <div className="flex-grow font-semibold text-sm cursor-default">
                    {collection.name}
                  </div>

                  <div>
                    <Link
                      to={bookmarkCollectionRoute(collection.id)}
                      target="_blank"
                      className="btn btn-ghost btn-xs gap-1"
                    >
                      <FontAwesomeIcon icon={faExternalLink} /> View
                    </Link>

                    {mayAddBookmarkToCollection(
                      authority,
                      collection,
                      bookmark,
                    ) && (
                      <RemoveBookmarkFromCollection
                        bookmarkId={bookmark.id}
                        collectionId={collection.id}
                        refetchQueries={refetchQueries}
                      >
                        {(handleRemove, isRemoving) => (
                          <Button
                            type="button"
                            color="ghost"
                            size="xs"
                            onClick={handleRemove}
                            disabled={isRemoving}
                            className="gap-1"
                          >
                            <FontAwesomeIcon icon={faTrash} /> Remove
                          </Button>
                        )}
                      </RemoveBookmarkFromCollection>
                    )}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        )}

        {currentCollections.length > 0 && availableCollections.length > 0 && (
          <div className="divider divider-horizontal" />
        )}

        <div className="space-y-2">
          <Heading level={4}>
            {availableCollections.length > 0
              ? 'Available Collections'
              : 'Add to New Collection'}
          </Heading>

          <ListGroup>
            {availableCollections.map(collection => (
              <ListGroup.Item
                key={collection.id}
                className="flex gap-1 justify-between items-center hover:bg-white p-1"
              >
                <div className="flex-grow font-semibold text-sm">
                  {collection.name}
                </div>
                <div>
                  <AddToCollection
                    bookmarkId={bookmark.id}
                    collectionId={collection.id}
                    refetchQueries={refetchQueries}
                  >
                    {({ addToCollection, loading }) => (
                      <Tooltip
                        message={`Add this bookmark to ${collection.name}`}
                      >
                        <Button
                          type="button"
                          color="ghost"
                          size="xs"
                          onClick={addToCollection}
                          disabled={
                            loading ||
                            !mayAddBookmarkToCollection(
                              authority,
                              collection,
                              bookmark,
                            )
                          }
                          className="whitespace-normal gap-1 text-left"
                        >
                          <FontAwesomeIcon icon={faPlusCircle} /> Add
                        </Button>
                      </Tooltip>
                    )}
                  </AddToCollection>
                </div>
              </ListGroup.Item>
            ))}

            <ListGroup.Item>
              <CreateCollectionAndAdd
                bookmarkId={bookmark.id}
                isCompany={bookmark.companyId !== null}
                refetchQueries={refetchQueries}
              />
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
}
