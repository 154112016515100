import { type PureQueryOptions } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { ItemFormFieldsText } from '~/components/report/ReportEditor/ItemFormFieldsText';
import { useCreateReportItemMutation } from '~/components/report/ReportEditor/operations';
import { editReportRoute } from '~/paths';
import type { ReportItemFormValuesText } from '~/utils/modules/report';
import {
  reportItemTextInitialValues,
  reportItemTextValidationSchema,
} from '~/utils/modules/report';

export function AddText({
  reportId,
  refetchQueries,
}: {
  reportId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const navigate = useNavigate();

  const [createReportItem, { loading, error }] = useCreateReportItemMutation({
    refetchQueries,
  });

  async function handleSubmit(values: ReportItemFormValuesText) {
    try {
      await createReportItem({
        variables: {
          reportId,
          reportItem: {
            text: {
              title: values.title.trim(),
              text: values.text.trim() || null,
            },
          },
        },
      });
      toast.success('Report item added successfully.');
      navigate(editReportRoute(reportId));
    } catch (err) {
      console.log('Error saving text item', err);
      toast.error('There was a problem saving the item, please try again.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={reportItemTextInitialValues()}
      validationSchema={reportItemTextValidationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Add Text</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <ItemFormFieldsText />
              <FormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right">
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </div>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
