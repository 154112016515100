import type { PagePartsFragment } from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type PageFormValues = {
  pageName: string;
  title: string;
  content: string;
  secured: boolean;
};

export function defaultPage(page?: PagePartsFragment): PageFormValues {
  return {
    pageName: page?.pageName || '',
    title: page?.title || '',
    content: page?.content || '',
    secured: page?.secured ?? false,
  };
}

export const pageValidationSchema = () =>
  yup.object({
    pageName: yup
      .string()
      .required()
      .matches(/^[\w\d-_]+$/) // Alphanumeric and dashes and underscores
      .label('internal page name'),
    title: yup.string().required(),
    content: yup.string().required(),
    secured: yup.bool().required(),
  });
