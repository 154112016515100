import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from '~/components/layout/Footer';
import { Navbar } from '~/components/layout/Navbar';
import { cn } from '~/utils/common';
import {
  Breadcrumb,
  BreadcrumbContext,
  BreadcrumbProvider,
} from './Breadcrumb';
import { FullWidthContext, FullWidthProvider } from './fullWidthContext';

type Props = {
  children: React.ReactNode;
};

export function AppShell({ children }: Props) {
  return (
    <>
      <HelmetProvider>
        <BreadcrumbProvider>
          <BreadcrumbContext.Consumer>
            {({ title }) => (
              <Helmet>
                <title>{title}</title>
              </Helmet>
            )}
          </BreadcrumbContext.Consumer>

          <Navbar />

          <div className="container mx-auto max-w-7xl">
            <Breadcrumb />
          </div>

          <FullWidthProvider>
            <FullWidthContext.Consumer>
              {({ isFullWidth }) => (
                <main
                  className={cn('container', 'mx-auto', 'mt-3', {
                    'max-w-7xl': !isFullWidth,
                    'max-w-full': isFullWidth,
                  })}
                >
                  {children}
                </main>
              )}
            </FullWidthContext.Consumer>
          </FullWidthProvider>

          <div className="h-36" />
          <Footer />
        </BreadcrumbProvider>
      </HelmetProvider>
    </>
  );
}
