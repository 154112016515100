import { useMutation, type PureQueryOptions } from '@apollo/client';
import {
  faEyeSlash,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import type {
  DeletePictureMutation,
  DeletePictureMutationVariables,
  FilePartsFragment,
  OutcropPartsFragment,
  PictureParent,
  PicturePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { DELETE_PICTURE } from '~/apollo/operations/supportingObject';
import { publishedButtonTrigger } from '~/components/upload/supportingObject/SupportObjectManager/SupportObjectListItem';
import { SupportingObjectPictureModal } from '~/components/upload/supportingObject/SupportingObjectPictureModal';
import { TogglePublished } from '~/components/upload/supportingObject/TogglePublished';
import { uploadOutcropUpdateRoute } from '~/paths';
import { truncate } from '~/utils/text';

export type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
  outcropTag?: OutcropPartsFragment | null;
};

type Props = {
  picture: Picture;
  parentType: PictureParent;
  parentId: number;
  refetchQueries: PureQueryOptions[];
  showThumb?: boolean;
  onDeleteSuccess?: () => Promise<void> | void;
  condensed?: boolean;
};

export function PictureListCard({
  picture,
  parentId,
  parentType,
  refetchQueries,
  onDeleteSuccess,
  condensed,
}: Props) {
  const [deletePicture, { loading }] = useMutation<
    DeletePictureMutation,
    DeletePictureMutationVariables
  >(DELETE_PICTURE, {
    variables: { parentId, parentType, id: picture.id },
    refetchQueries,
  });

  async function handleDelete() {
    const confirmText = 'This will permanently delete the selected picture.';
    if (!window.confirm(confirmText)) return;

    try {
      await deletePicture();
      if (onDeleteSuccess) {
        await onDeleteSuccess();
      }
      toast.success('Picture deleted.');
    } catch (err) {
      console.log('Error deleting picture', err);
      toast.error('There was a problem deleting the picture.');
    }
  }

  return (
    <div
      className={cn('', {
        'p-2': condensed,
        'p-4': !condensed,
        'border border-solid': picture.published,
        'border-2 border-dotted border-info': !picture.published && condensed,
        'border-4 p-0.5 border-dotted border-info':
          !picture.published && !condensed,
      })}
    >
      <div className="w-full flex gap-6">
        <div
          className="shrink-0 w-32 h-32 bg-cover bg-center border"
          style={{ backgroundImage: `url(${picture.file.signedUrl}` }}
        />

        <div className="grow">
          <div
            className={cn(
              'w-full flex justify-between items-start gap-2 text-left break-all ',
            )}
          >
            <div className={cn('font-bold', { 'text-sm': condensed })}>
              {!picture.published && (
                <FontAwesomeIcon icon={faEyeSlash} className="mr-1 text-info" />
              )}
              {picture.name}
            </div>

            <div className="space-x-1 shrink-0">
              <TogglePublished
                soType="picture"
                picture={picture}
                published={picture.published}
                children={publishedButtonTrigger(picture.published)}
              />

              <SupportingObjectPictureModal
                parentType={parentType}
                parentId={parentId}
                picture={picture}
                refetchQueries={refetchQueries}
              >
                {showEditModal => (
                  <Button
                    type="button"
                    onClick={showEditModal}
                    color="ghost"
                    size="xs"
                    startIcon={<FontAwesomeIcon icon={faPencil} />}
                  >
                    Edit
                  </Button>
                )}
              </SupportingObjectPictureModal>

              <Button
                type="button"
                color="ghost"
                size="xs"
                onClick={handleDelete}
                disabled={loading}
                startIcon={<FontAwesomeIcon icon={faTrash} />}
              >
                Delete
              </Button>
            </div>
          </div>

          <div
            className={cn({
              'space-y-0': condensed,
              'space-y-0.5': !condensed,
            })}
          >
            <PictureDetailText
              label="Description"
              value={truncate(140)(picture.description)}
            />
            <PictureDetailText label="Type" value={picture.type} />
            <PictureDetailText label="Author" value={picture.author} />
            <PictureDetailText label="Scale" value={picture.scale} />
            <PictureDetailText label="Comments" value={picture.comments} />
            <PictureDetailText
              label="Priority"
              value={String(picture.priority ?? '')}
            />
            {picture.outcropTag && (
              <PictureDetailText
                label="Outcrop Tag"
                value={
                  <Link
                    to={uploadOutcropUpdateRoute(picture.outcropTag.id)}
                    className="link"
                  >
                    {picture.outcropTag.name}
                  </Link>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PictureDetailText({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  if (!value) {
    return null;
  }

  return (
    <div className="p-0 space-x-2">
      <span className="text-xs text-muted cursor-default">{label}</span>
      <span className="text-sm">{value}</span>
    </div>
  );
}
