import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import {
  Link,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  FormationUpdateRouteQuery,
  FormationUpdateRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import { DeleteFormation } from '~/components/upload/lithostrat/formation/DeleteFormation';
import * as routes from '~/paths';

const FORMATION_UPDATE_PAGE = gql`
  query FormationUpdateRoute($formationId: Int!) {
    lithostratFormationList(id: $formationId) {
      ...lithostratFormationParts
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
      group {
        ...lithostratGroupParts
      }
      members {
        ...lithostratMemberParts
        startAge {
          ...lithostratAgeParts
        }
        wikiPage {
          ...lithostratWikiPageParts
        }
      }
      wikiPage {
        ...lithostratWikiPageParts
        keyParameters {
          ...keyParametersParts
        }
        regions {
          id
          name
        }
      }
    }
  }

  ${fragments.lithostratFormationParts}
  ${fragments.lithostratAgeParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratMemberParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.keyParametersParts}
`;

type Formation = FormationUpdateRouteQuery['lithostratFormationList'][number];

type OutletContext = {
  formation: Formation;
  refetchQueries: PureQueryOptions[];
};

export default function FormationUpdateRoute() {
  const params = useParams<{ formationId: string }>();
  invariant(params.formationId, 'formationId param required');
  const formationId = parseInt(params.formationId);

  const navigate = useNavigate();

  const { data, loading } = useQuery<
    FormationUpdateRouteQuery,
    FormationUpdateRouteQueryVariables
  >(FORMATION_UPDATE_PAGE, {
    variables: { formationId },
  });

  const refetchQueries: [PureQueryOptions<FormationUpdateRouteQueryVariables>] =
    [
      {
        query: FORMATION_UPDATE_PAGE,
        variables: { formationId },
      },
    ];

  const formation = data?.lithostratFormationList.find(
    f => f.id === formationId,
  );

  function handleDeleteSuccess() {
    navigate(routes.uploadLithostratFormationListRoute());
  }

  useBreadcrumb(
    'routes/upload/lithostrat/formation/$formationId',
    formation?.name ?? 'Update Formation',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!formation) return <NotFound />;

  const hasMembers = formation.members.length > 0;

  const outletContext: OutletContext = {
    formation,
    refetchQueries,
  };

  return (
    <>
      <Link
        to={routes.lithostratFormationRoute(formationId)}
        target="_blank"
        className="btn btn-primary btn-sm float-right gap-1"
      >
        Wiki Page <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading>{formation.name}</PageHeading>

      <NavTabs>
        <NavTabs.Tab
          to={routes.uploadLithostratFormationUpdateRoute(formation.id)}
          end
        >
          Formation
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratFormationUpdateGeologicalAgeRoute(
            formation.id,
          )}
        >
          Geological Age
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratFormationUpdateExamplesRoute(formation.id)}
        >
          Examples
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratFormationUpdateMembersRoute(formation.id)}
        >
          Members
        </NavTabs.Tab>
        <NavTabs.Tab
          to={routes.uploadLithostratFormationUpdateWikiRoute(formation.id)}
        >
          Wiki
        </NavTabs.Tab>
      </NavTabs>

      <div className="mt-2" />
      <Outlet context={outletContext} />

      <div className="text-center mt-6">
        <DeleteFormation
          id={formation.id}
          onDeleteSuccess={handleDeleteSuccess}
        >
          {(onDelete, loading) => (
            <Confirm
              onConfirm={onDelete}
              text="This formation will be permanently deleted."
            >
              {confirmDelete => (
                <Tooltip
                  disabled={!hasMembers}
                  message="Formation cannot be deleted because it has members associated with it."
                >
                  <Button
                    type="button"
                    variant="link"
                    onClick={confirmDelete}
                    disabled={hasMembers}
                    loading={loading}
                    className="text-error hover:text-error gap-1"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    Delete Formation
                  </Button>
                </Tooltip>
              )}
            </Confirm>
          )}
        </DeleteFormation>
      </div>
    </>
  );
}

export function useUpdateFormationOutletContext() {
  return useOutletContext<OutletContext>();
}
