import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { depositionalWikiParts } from '~/apollo/fragments';
import type {
  CreateDepositionalWikiMutation,
  CreateDepositionalWikiMutationVariables,
  DepositionalWiki,
  DepositionalWikiType,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { useQueryString } from '~/hooks/routing';
import { uploadGeologyUpdateRoute } from '~/paths';
import type { DepositionalWikiFormValues } from '~/utils/modules/depositionalWiki';
import {
  defaultWikiPage,
  toDepositionalWikiInput,
  validationSchema,
} from '~/utils/modules/depositionalWiki';
import { DepositionalWikiFormFields } from './DepositionalWikiFormFields';
import invariant from 'tiny-invariant';

export const CREATE_DEPOSITIONAL_WIKI = gql`
  mutation CreateDepositionalWiki($depositional: DepositionalWikiInput!) {
    createDepositionalWiki(depositional: $depositional) {
      ...depositionalWikiParts
    }
  }

  ${depositionalWikiParts}
`;

type QueryString = {
  geologyType?: string;
  type?: string;
  value?: string;
};

export function CreateDepositionalWikiForm() {
  const { query } = useQueryString<QueryString>();
  const navigate = useNavigate();
  const [createWikiPage, { loading, error }] = useMutation<
    CreateDepositionalWikiMutation,
    CreateDepositionalWikiMutationVariables
  >(CREATE_DEPOSITIONAL_WIKI, {});

  async function handleSubmit(values: DepositionalWikiFormValues) {
    const depositionalInput = toDepositionalWikiInput(values);

    try {
      const result = await createWikiPage({
        variables: { depositional: depositionalInput },
      });
      const wikiPage = result.data?.createDepositionalWiki;
      invariant(wikiPage, 'Error parsing wikiPage from response!');
      toast.success('Wiki page saved successfully.');
      navigate(uploadGeologyUpdateRoute(wikiPage.id));
    } catch (err) {
      console.log('Error saving wiki page', err);
      toast.error('There was a problem saving the wiki page.');
    }
  }

  const initialValues = (): DepositionalWikiFormValues => {
    const partialDW: Partial<DepositionalWiki> = {};
    if (query.geologyType) partialDW.geologyType = query.geologyType;
    if (query.type) partialDW.type = query.type as DepositionalWikiType;
    if (query.value) partialDW.value = [query.value];
    return defaultWikiPage(partialDW);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues()}
      validationSchema={validationSchema}
    >
      <Form>
        <DepositionalWikiFormFields />
        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
