import { gql } from '~/apollo/client-v3';
import {} from '@apollo/client';

export const DEPOSITIONAL_HIERARCHY = gql`
  query DepositionalHierarchy {
    depositionalHierarchyFull {
      clastic {
        grossDepositionalEnvironment {
          name
          depositionalEnvironment {
            name
            depositionalSubEnvironment {
              name
              architecturalElement {
                name
              }
            }
          }
        }
      }

      carbonate {
        grossDepositionalEnvironment {
          name
          depositionalEnvironment {
            name
            depositionalSubEnvironment {
              name
              architecturalElement {
                name
              }
            }
          }
        }
      }

      structural {
        grossDepositionalEnvironment {
          name
          depositionalEnvironment {
            name
            depositionalSubEnvironment {
              name
              architecturalElement {
                name
              }
            }
          }
        }
      }
    }
  }
`;
