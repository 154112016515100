import React from 'react';
import type { OutcropPalaeogeographyRouteQuery } from '~/apollo/generated/v3/graphql';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/v3/graphql';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { outcropPalaeogeographyTabRoute } from '~/paths';

type Outcrop = OutcropPalaeogeographyRouteQuery['outcropList'][number];
type PaleoMap = Outcrop['paleoMaps'][number];
type Picture = PaleoMap['pictures'][number];

type Props = {
  outcropId: number;
  paleoMapId: number;
  picture: Picture;
};

export function PaleoMapPictureModal({
  outcropId,
  paleoMapId,
  picture,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      <a href="#show-image" onClick={showModal}>
        <img
          src={picture.file.signedUrl}
          className="w-full h-auto"
          alt={picture.description}
        />

        <div className="text-center text-muted text-base">
          {picture.description}
        </div>
      </a>

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading={picture.name}>
          <div className="text-right">
            <TargetBookmarksManagerModal
              parentType={BookmarkParentType.PaleoMap}
              parentId={paleoMapId}
              targetType={BookmarkTargetType.Picture}
              targetId={picture.id}
              path={outcropPalaeogeographyTabRoute(outcropId)}
            />
          </div>

          <div className="space-y-4">
            <div className="text-center">
              <img
                src={picture.file.signedUrl}
                className="w-full h-auto"
                alt={picture.description}
              />
            </div>

            <div className="text-center">{picture.description}</div>

            <div className="lg:w-1/2">
              <table className="table table-compact w-full">
                <tbody>
                  {/* 'undefined' is the default string value for type */}
                  {picture.type && picture.type !== 'undefined' && (
                    <tr>
                      <th>Type:</th>
                      <td>{picture.type}</td>
                    </tr>
                  )}
                  {picture.category && (
                    <tr>
                      <th>Category:</th>
                      <td>{picture.category}</td>
                    </tr>
                  )}
                  {picture.author && (
                    <tr>
                      <th>Author:</th>
                      <td>{picture.author}</td>
                    </tr>
                  )}
                  {picture.scale && (
                    <tr>
                      <th>Scale:</th>
                      <td>{picture.scale}</td>
                    </tr>
                  )}
                  {picture.pictureOrientation && (
                    <tr>
                      <th>Orientation:</th>
                      <td>{picture.pictureOrientation}</td>
                    </tr>
                  )}
                  {picture.comments && (
                    <tr>
                      <th>Comments:</th>
                      <td>{picture.comments}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
