import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { photo360Parts } from '~/apollo/fragments';
import type {
  Photo360PartsFragment,
  UpdatePhoto360Mutation,
  UpdatePhoto360MutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { UrlBasedSOFormFields } from '~/components/supportingObject/urlBasedSO/UrlBasedSOFormFields';
import type { UrlBasedSOFormValues } from '~/utils/modules/urlBasedSO';
import {
  initialUrlBasedSO,
  urlBasedSOToInput,
  urlBasedSOValidationSchema,
} from '~/utils/modules/urlBasedSO';

const UPDATE_PHOTO_360 = gql`
  mutation UpdatePhoto360($id: Int!, $photo360: UpdateUrlBasedSOInput!) {
    updatePhoto360(id: $id, photo360: $photo360) {
      ...photo360Parts
    }
  }

  ${photo360Parts}
`;

export function UpdatePhoto360Form({
  photo360,
  onUpdateSuccess,
}: {
  photo360: Photo360PartsFragment;
  onUpdateSuccess?: () => void;
}) {
  const [updatePhoto360, { loading, error }] = useMutation<
    UpdatePhoto360Mutation,
    UpdatePhoto360MutationVariables
  >(UPDATE_PHOTO_360);

  async function handleSubmit(values: UrlBasedSOFormValues) {
    try {
      await updatePhoto360({
        variables: {
          id: photo360.id,
          photo360: urlBasedSOToInput(values),
        },
      });
      toast.success('360 Photo saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving 360 Photo', err);
      toast.error('There was a problem saving the 360 Photo.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialUrlBasedSO(photo360)}
      validationSchema={urlBasedSOValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <UrlBasedSOFormFields soType="Photo360" />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
