import type { DocumentNode, PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import {
  faCancel,
  faEye,
  faEyeSlash,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { Badge, Button } from 'react-daisyui';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import type {
  FileParent,
  GeoreferenceParent,
  PictureParent,
} from '~/apollo/generated/v3/graphql';
import { GeoreferenceDataType } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SOFileList } from '~/components/upload/supportingObject/SOFileList';
import { SupportingObjectGeoreference } from '~/components/upload/supportingObject/SupportingObjectGeoreference';
import { SupportingObjectPictureList } from '~/components/upload/supportingObject/SupportingObjectPictureList';
import { TogglePublished } from '~/components/upload/supportingObject/TogglePublished';
import { uploadOutcropUpdateRoute } from '~/paths';
import type {
  SupportObject,
  SupportObjectParent,
  SupportObjectType,
} from '~/utils/modules/supportObject';
import {
  hasFiles,
  hasGeoreferences,
  supportObjectFields,
} from '~/utils/modules/supportObject';
import { UpdateSupportObject } from './UpdateSupportObject';

type Props = {
  outcropId?: number;
  studyId?: number;
  supportObjectType: SupportObjectType;
  supportObject: SupportObject;
  parentType: SupportObjectParent;
  updateMutation: DocumentNode;
  deleteMutation: DocumentNode;
  refetchQueries: PureQueryOptions[];
  showUnpublished: boolean;
};

export function publishedButtonTrigger(published: boolean) {
  return function PublishedButtonTrigger(
    onClick: () => Promise<void>,
    loading: boolean,
  ) {
    return (
      <Button
        type="button"
        onClick={onClick}
        color={published ? 'ghost' : 'info'}
        size="xs"
        disabled={loading}
        startIcon={
          published ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
            <FontAwesomeIcon icon={faEye} />
          )
        }
      >
        {published ? 'Unpublish' : 'Publish'}
      </Button>
    );
  };
}

function publishedButton(soType: SupportObjectType, so: SupportObject) {
  if (soType === 'crossSection' && so.__typename === 'CrossSection') {
    return (
      <TogglePublished
        soType="crossSection"
        crossSection={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (
    soType === 'sedimentaryLog' &&
    so.__typename === 'SedimentaryLog'
  ) {
    return (
      <TogglePublished
        soType="sedimentaryLog"
        sedimentaryLog={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (soType === 'facies' && so.__typename === 'Facies') {
    return (
      <TogglePublished
        soType="facies"
        facies={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (soType === 'wellLog' && so.__typename === 'WellLog') {
    return (
      <TogglePublished
        soType="wellLog"
        wellLog={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (soType === 'production' && so.__typename === 'Production') {
    return (
      <TogglePublished
        soType="production"
        production={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (
    soType === 'reservoirModel' &&
    so.__typename === 'ReservoirModel'
  ) {
    return (
      <TogglePublished
        soType="reservoirModel"
        reservoirModel={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (soType === 'trainingImage' && so.__typename === 'TrainingImage') {
    return (
      <TogglePublished
        soType="trainingImage"
        trainingImage={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (soType === 'variogram' && so.__typename === 'Variogram') {
    return (
      <TogglePublished
        soType="variogram"
        variogram={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  } else if (soType === 'gigaPan' && so.__typename === 'GigaPan') {
    return (
      <TogglePublished
        soType="gigaPan"
        gigaPan={so}
        published={so.published}
        children={publishedButtonTrigger(so.published)}
      />
    );
  }

  return null;
}

export function SupportObjectListItem({
  outcropId,
  studyId,
  supportObject,
  supportObjectType,
  parentType,
  updateMutation,
  deleteMutation,
  refetchQueries,
  showUnpublished,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const [deleteSupportingObject, { loading }] = useMutation(deleteMutation, {
    variables: { id: supportObject.id },
    refetchQueries,
  });

  const toggleEditing = () => setIsEditing(!isEditing);

  async function handleDelete() {
    const confirmText = 'The selected object will be permanently deleted.';
    if (!window.confirm(confirmText)) {
      return;
    }

    try {
      await deleteSupportingObject();
    } catch (err) {
      console.log('Error deleting supporting object:', err);
      toast.error('There was a problem deleting the supporting object.');
    }
  }

  const editButton = (
    <Button
      type="button"
      color="primary"
      size="xs"
      onClick={toggleEditing}
      startIcon={<FontAwesomeIcon icon={faPencil} />}
    >
      Edit
    </Button>
  );

  const cancelDeleteButton = (
    <div className="space-x-1">
      <Button
        type="button"
        color="error"
        size="xs"
        onClick={handleDelete}
        disabled={loading}
        startIcon={<FontAwesomeIcon icon={faTrash} />}
      >
        Delete
      </Button>
      <Button
        type="button"
        size="xs"
        onClick={toggleEditing}
        startIcon={<FontAwesomeIcon icon={faCancel} />}
      >
        Cancel
      </Button>
    </div>
  );

  const supportObjectDetails = (
    <div className="space-y-2">
      <div>
        {supportObjectFields(supportObjectType).map(({ name, label }) => (
          <div key={name} className="space-x-2">
            <span className="text-muted text-sm">{label}</span>
            <span>{R.propOr('--', name, supportObject)}</span>
          </div>
        ))}

        {supportObject.outcropTagId && supportObject.outcropTag && (
          <div className="space-x-2">
            <span className="text-muted text-sm">Outcrop</span>
            <span>
              <Link
                to={uploadOutcropUpdateRoute(supportObject.outcropTagId)}
                target="_blank"
                className="link"
              >
                {supportObject.outcropTag.name}
              </Link>
            </span>
          </div>
        )}
      </div>

      <div className="grid grid-cols-3 gap-6 border-t pt-1">
        {hasFiles(supportObjectType) && 'files' in supportObject && (
          <div>
            <SOFileList
              outcropId={outcropId}
              studyId={studyId}
              parentType={parentType as FileParent}
              parentId={supportObject.id}
              refetchQueries={refetchQueries}
              files={supportObject.files ?? []}
            />
          </div>
        )}

        <div
          className={
            hasFiles(supportObjectType) ? 'lg:col-span-2' : 'lg:col-span-3'
          }
        >
          <SupportingObjectPictureList
            parentType={parentType as PictureParent}
            parentId={supportObject.id}
            pictures={supportObject.pictures ?? []}
            refetchQueries={refetchQueries}
            condensed={supportObjectType !== 'facies'}
            showUnpublished={showUnpublished}
          />
        </div>
      </div>

      <hr />

      {hasGeoreferences(supportObjectType) &&
        'georeference' in supportObject && (
          <SupportingObjectGeoreference
            georeference={supportObject.georeference}
            dataTypes={[
              GeoreferenceDataType.Centre,
              GeoreferenceDataType.Polyline,
              GeoreferenceDataType.Polygon,
              GeoreferenceDataType.Point,
            ]}
            limits={{ [GeoreferenceDataType.Centre]: 1 }}
            parentType={parentType as GeoreferenceParent}
            parentId={supportObject.id}
            refetchQueries={refetchQueries}
            outcropId={outcropId}
            studyId={studyId}
          />
        )}
    </div>
  );

  return (
    <Panel
      className={
        !supportObject.published
          ? 'border-4 border-dotted border-info'
          : undefined
      }
    >
      <Panel.Heading className="flex justify-between gap-2">
        <Panel.Title className="shrink space-x-1">
          {!supportObject.published && (
            <FontAwesomeIcon icon={faEyeSlash} fontSize="10" />
          )}
          <span>{supportObject.name}</span>
          <Badge color="ghost" className="font-mono text-sm">
            {supportObject.id}
          </Badge>
        </Panel.Title>
        <div className="flex items-center justify-end gap-1 grow shrink-0">
          {publishedButton(supportObjectType, supportObject)}
          {isEditing ? cancelDeleteButton : editButton}
        </div>
      </Panel.Heading>

      <Panel.Body>
        {isEditing ? (
          <UpdateSupportObject
            outcropId={outcropId}
            studyId={studyId}
            supportObject={supportObject}
            supportObjectType={supportObjectType}
            updateMutation={updateMutation}
            refetchQueries={refetchQueries}
            onUpdateSuccess={toggleEditing}
          />
        ) : (
          supportObjectDetails
        )}
      </Panel.Body>
    </Panel>
  );
}
