import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Papa from 'papaparse';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import * as fragments from '~/apollo/fragments';
import type { ExportStudiesPageQuery } from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import { uploadStudyUpdateRoute } from '~/paths';
import { saveAsCSV } from '~/utils/export';
import { mergeKeyParameters } from '~/utils/modules/keyParameters';
import { toISODateString } from '~/utils/text';

type Study = ExportStudiesPageQuery['studyList'][number];

const EXPORT_STUDIES_PAGE = gql`
  query ExportStudiesPage {
    studyList {
      id
      name
      type
      abstract
      dominantGeologyType
      approved
      qcCompleted
      redundant
      insertedAt
      updatedAt
      outcrops {
        id
        region {
          location {
            country
          }
        }
      }
      qualityParameters {
        dataAcquisitionMethod
        spatialObservationType
        publicationType
      }
      dataHistory {
        collectedBy
        date
      }
      keyParameters {
        ...keyParametersParts
      }
      project {
        name
      }
      architecturalElements {
        measurementCount
      }
      pictures {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      crossSections {
        id
      }
      facies {
        id
      }
      production {
        id
      }
      literatureReferences {
        id
      }
      literatureReferences {
        id
      }
      reservoirModels {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      trainingImages {
        id
      }
      lithostratStudyLinks {
        id
      }
    }
  }

  ${fragments.keyParametersParts}
`;

const studyKPs = (study: Study) => mergeKeyParameters(study.keyParameters);

const numAEsWithMeasurements = (study: Study) =>
  study.architecturalElements?.reduce((acc, cur) => {
    return acc + (cur.measurementCount > 0 ? 1 : 0);
  }, 0) ?? 0;

const numSO = (study: Study, key: keyof Study) => {
  const item = study[key];
  if (item instanceof Array) return item.length;
  else return 0;
};

function formatDate(date?: Date | string | null | undefined) {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
}

class Row {
  ID: number;
  Name: string;
  'Study Type': string;
  'Geology Type': string;
  'Country': string;
  Project: string;
  Abstract: string; // (yes/no)
  'Collected By'?: string;
  'Date'?: number;
  'Data Acquisition Method'?: string;
  'Spatial Observation Type'?: string;
  'Publication Type'?: string;
  'Key Reference': string; // (yes/no)
  Approved: string; // (yes/no)
  'QC Completed': string; // (yes/no)
  Redundant: string; // (yes/no)
  'Outcrop Links': number; // (count)
  GDE: string;
  DE: string;
  SE: string;
  AE: number; // (count AEs with measurement)
  Lithostrat: string; // (yes/no)
  Pictures: number; // (count)
  Facies: number; // (count)
  'Cross Sections': number; // (count)
  'Sedimentary Logs': number; // (count)
  'Well Logs': number; // (count)
  Production: number; // (count)
  Literature: number; // (count)
  'Reservoir Models': number; // (count)
  'Training Images': number; // (count)
  Variograms: number; // (count)
  Panoramas: number; // (count)
  'Inserted At': string;
  'Updated At': string;

  constructor(s: Study) {
    this['ID'] = s.id;
    this['Name'] = s.name;
    this['Study Type'] = s.type;
    this['Geology Type'] = s.dominantGeologyType ?? '';
    this['Country'] = s.outcrops?.at(0)?.region?.location.country ?? '';
    this['Project'] = s.project.name ?? '';
    this['Abstract'] = s.abstract ? 'yes' : 'no';
    this['Collected By'] = s.dataHistory?.collectedBy;
    this['Date'] = s.dataHistory?.date;
    this['Data Acquisition Method'] =
      s.qualityParameters?.dataAcquisitionMethod?.join(', ');
    this['Spatial Observation Type'] =
      s.qualityParameters?.spatialObservationType ?? '';
    this['Publication Type'] = s.qualityParameters?.publicationType?.join(', ');
    this['Key Reference'] = s.literatureReferences ? 'yes' : 'no';
    this['Approved'] = s.approved ? 'yes' : 'no';
    this['QC Completed'] = s.qcCompleted ? 'yes' : 'no';
    this['Redundant'] = s.redundant ? 'yes' : 'no';
    this['Outcrop Links'] = s.outcrops?.length || 0;
    this['GDE'] = studyKPs(s).grossDepositionalEnvironment.join(', ');
    this['DE'] = studyKPs(s).depositionalEnvironment.join(', ');
    this['SE'] = studyKPs(s).depositionalSubEnvironment.join(', ');
    this['AE'] = numAEsWithMeasurements(s);
    this['Lithostrat'] =
      !!s.lithostratStudyLinks && s.lithostratStudyLinks.length > 0
        ? 'yes'
        : 'no';
    this['Pictures'] = numSO(s, 'pictures');
    this['Facies'] = numSO(s, 'facies');
    this['Cross Sections'] = numSO(s, 'crossSections');
    this['Sedimentary Logs'] = numSO(s, 'sedimentaryLogs');
    this['Well Logs'] = numSO(s, 'wellLogs');
    this['Production'] = numSO(s, 'production');
    this['Literature'] = numSO(s, 'literatureReferences');
    this['Reservoir Models'] = numSO(s, 'reservoirModels');
    this['Training Images'] = numSO(s, 'trainingImages');
    this['Variograms'] = numSO(s, 'variograms');
    this['Panoramas'] = numSO(s, 'gigaPans');
    this['Inserted At'] = formatDate(s.insertedAt) ?? '';
    this['Updated At'] = formatDate(s.updatedAt) ?? '';
  }
}

export default function ExportStudiesRoute() {
  useBreadcrumb('routes/upload/export/studies', 'Study Master Sheet');

  const { data, loading } =
    useQuery<ExportStudiesPageQuery>(EXPORT_STUDIES_PAGE);

  const studies = data?.studyList ?? [];

  const rows = studies.map(s => new Row(s));

  const fields = rows.length ? (Object.keys(rows[0]) as Array<keyof Row>) : [];

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    rows,
    'ID',
    'Name',
    'exportStudiesList',
  );

  function handleSave() {
    const data = Papa.unparse(rows);
    const date = toISODateString(new Date());
    saveAsCSV(data, `Studies Export ${date}.csv`);
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <div className="float-right">
        <Button
          type="button"
          variant="link"
          onClick={handleSave}
          className="gap-1"
        >
          <FontAwesomeIcon icon={faFileCsv} /> Save as CSV
        </Button>
      </div>

      <PageHeading>Study Master Sheet</PageHeading>
      <p>Master list of all studies</p>

      <table className="table table-compact text-sm">
        <thead className="sticky top-0 bg-sky-100">
          <tr>
            {fields.map(f => (
              <th key={f}>
                <SortTrigger colName={f} sortIndicatorProps={siProps}>
                  <span style={{ fontSize: '9pt' }}>{f}</span>
                </SortTrigger>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {items.map(item => (
            <tr key={item.ID}>
              {fields.slice(0, 1).map(f => (
                <td key={f} className="sticky left-0 bg-sky-100">
                  <Link
                    to={uploadStudyUpdateRoute(item.ID)}
                    target="blank"
                    className="link"
                  >
                    {item[f]}
                  </Link>
                </td>
              ))}
              {fields.slice(1).map(f => (
                <td key={f} className="border border-slate-100">
                  {item[f]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
