import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';

import * as fragments from '~/apollo/fragments';
import {
  CREATE_WELL_LOG,
  DELETE_WELL_LOG,
  UPDATE_WELL_LOG,
} from '~/apollo/operations/supportingObject';
import type {
  UploadOutcropUpdateWellLogsTabQuery,
  UploadOutcropUpdateWellLogsTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SupportObjectManager } from '~/components/upload/supportingObject/SupportObjectManager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

export const WELL_LOGS_TAB = gql`
  query UploadOutcropUpdateWellLogsTab($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
      wellLogs {
        ...wellLogParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
          }
        }
        files {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.wellLogParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateOutcropWellLogsRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UploadOutcropUpdateWellLogsTabQuery,
    UploadOutcropUpdateWellLogsTabQueryVariables
  >(WELL_LOGS_TAB, {
    variables: { id: outcropId },
  });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropUpdateWellLogsTabQueryVariables>,
  ] = [
    {
      query: WELL_LOGS_TAB,
      variables: { id: outcropId },
    },
  ];

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const wellLogs = outcrop?.wellLogs ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SupportObjectManager
      outcropId={outcropId}
      supportObject={wellLogs}
      supportObjectType="wellLog"
      parentType="WELL_LOG"
      createMutation={CREATE_WELL_LOG}
      updateMutation={UPDATE_WELL_LOG}
      deleteMutation={DELETE_WELL_LOG}
      refetchQueries={refetchQueries}
    />
  );
}
