import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  CreateLithostratGroupMutation,
  CreateLithostratGroupMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { uploadLithostratGroupUpdateRoute } from '~/paths';
import type { GroupFormValues } from '~/utils/modules/lithostrat';
import {
  defaultGroup,
  formValuesToGroupInput,
  groupValidationSchema,
} from '~/utils/modules/lithostrat';
import { GroupFormFields } from './GroupFormFields';

export const CREATE_LITHOSTRAT_GROUP = gql`
  mutation CreateLithostratGroup($group: LithostratGroupInput!) {
    createLithostratGroup(group: $group) {
      id
    }
  }
`;

type Props = {
  refetchQueries: PureQueryOptions[];
  children: (showModal: () => void) => React.ReactNode;
};

export function CreateGroupModal({ refetchQueries, children }: Props) {
  const navigate = useNavigate();
  const { show, showModal, hideModal } = useModalState();
  const [createGroup, { loading, error }] = useMutation<
    CreateLithostratGroupMutation,
    CreateLithostratGroupMutationVariables
  >(CREATE_LITHOSTRAT_GROUP, {
    refetchQueries,
  });

  async function handleSubmit(values: GroupFormValues) {
    try {
      const res = await createGroup({
        variables: {
          group: formValuesToGroupInput(values),
        },
      });
      const groupId = res.data?.createLithostratGroup.id;
      invariant(groupId);
      toast.success('Group created successfully.');
      navigate(uploadLithostratGroupUpdateRoute(groupId));
      hideModal();
    } catch (err) {
      console.log('Error creating group', err);
      toast.error('There was a problem creating the group.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultGroup()}
          validationSchema={groupValidationSchema}
        >
          <Form>
            <Modal.Body heading="Create Group">
              <div className="space-y-4">
                <GroupFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
