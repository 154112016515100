import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import type { NearestOutcropsQuery } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { Modal } from '~/components/common/Modal';
import { CoordinatesText } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/CoordinatesText';
import type { BulkFieldPictureUploaderDefaultValues } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/DefaultValuesForm';
import type { FieldPictureFormValues } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/MetadataEditor';
import { OutcropSuggestions } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/OutcropSuggestions';
import type { ShowModalFn } from '~/hooks/modal';
import { useModalState } from '~/hooks/modal';
import { markerIcon } from '~/utils/georeference';

type Props = {
  exifLocation: google.maps.LatLngLiteral | null;
  children: (showModal: ShowModalFn) => JSX.Element;
  nearestOutcrops: NearestOutcropsQuery['nearestOutcrops'];
  outcropId?: number;
  defaultValues?: BulkFieldPictureUploaderDefaultValues;
};

export function LocationEditor({
  exifLocation,
  children,
  nearestOutcrops,
  outcropId,
  defaultValues,
}: Props) {
  const [map, setMap] = useState<google.maps.Map>();
  const { show, showModal, hideModal } = useModalState();
  const { values, setFieldValue } = useFormikContext<FieldPictureFormValues>();

  function handleMapLoaded(map: google.maps.Map) {
    map.setCenter({ lat: 0, lng: 0 });
    map.setZoom(2);
    zoomToLocation(map);

    setMap(map);
  }

  function zoomToLocation(map: google.maps.Map) {
    if (map && values.location) {
      map.panTo(values.location);
      map.setZoom(10);
    }
  }

  function handleShow() {
    showModal();

    if (map) {
      zoomToLocation(map);
    }
  }

  function handleMouseEvent(event: google.maps.MapMouseEvent) {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();

    if (lat && lng) {
      setFieldValue('location', { lat, lng });
      setFieldValue('locationApproximate', false);
    } else {
      setFieldValue('location', null);
    }
  }

  function clearLocation() {
    if (window.confirm('Are you sure you want to remove the location?')) {
      setFieldValue('location', null);
      setFieldValue('locationApproximate', true);
    }
  }

  function resetToExifLocation() {
    if (exifLocation) {
      setFieldValue('location', exifLocation);
      setFieldValue('locationApproximate', false);
    }
  }

  useEffect(() => {
    if (!exifLocation && defaultValues?.location) {
      setFieldValue('location', defaultValues.location);
    }
  }, [exifLocation, defaultValues?.location, setFieldValue]);

  return (
    <>
      {children(handleShow)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body
          heading={values.location ? 'Adjust Location' : 'Set Location'}
        >
          <div className="space-y-4">
            <GoogleMap
              mapContainerClassName="w-full h-[500px]"
              onLoad={handleMapLoaded}
              onClick={handleMouseEvent}
              mapTypeId="terrain"
            >
              {values.location && (
                <MarkerF
                  position={values.location}
                  draggable
                  onDragEnd={handleMouseEvent}
                />
              )}

              {nearestOutcrops.map(
                result =>
                  result.outcrop.center && (
                    <MarkerF
                      key={result.outcrop.id}
                      position={result.outcrop.center}
                      draggable={false}
                      clickable={false}
                      options={{
                        icon: markerIcon(
                          String(result.outcrop.id) === values.outcropId
                            ? {
                                fill: 'yellow',
                                stroke: 'yellow',
                                fillOpacity: 0.5,
                              }
                            : {
                                fill: 'blue',
                                stroke: 'blue',
                                fillOpacity: 0.5,
                              },
                        ),
                      }}
                    />
                  ),
              )}
            </GoogleMap>

            <div className="text-center space-y-2">
              {values.location && (
                <CoordinatesText
                  exifLocation={exifLocation}
                  location={values.location}
                />
              )}

              <Field
                name="locationApproximate"
                component={FormikField}
                type="checkbox"
                label="Approximate location"
                className="inline-block"
              />

              <div className="space-x-2">
                {values.location && (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={clearLocation}
                  >
                    Remove Location
                  </Button>
                )}

                {exifLocation && (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={resetToExifLocation}
                  >
                    Reset to Image Location
                  </Button>
                )}
              </div>
            </div>
          </div>

          {nearestOutcrops.length > 0 && (
            <div className="w-full flex justify-center">
              <div className="inline w-auto">
                <OutcropSuggestions
                  disabled={!!outcropId}
                  outcropId={values.outcropId}
                  nearestOutcrops={nearestOutcrops}
                  onOutcropClick={outcropId =>
                    setFieldValue('outcropId', String(outcropId))
                  }
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" color="ghost" onClick={hideModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
