import { useState } from 'react';
import { Button } from 'react-daisyui';
import type { UserPartsFragment } from '~/apollo/generated/v3/graphql';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import CompanyAdminUserListItem from './CompanyAdminUserListItem';

type Props = {
  userList: UserPartsFragment[];
  colSpan: number;
};

function CompanyAdminUserListDeletedUsers({ userList, colSpan }: Props) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(val => !val);

  return (
    <tbody>
      <tr>
        <td colSpan={colSpan}>
          <Button type="button" color="ghost" onClick={toggleExpanded}>
            {userList.length} Deleted Users{' '}
            <ExpandedIcon expanded={expanded} className="ml-2" />
          </Button>
        </td>
      </tr>
      {expanded &&
        userList.map(user => (
          <CompanyAdminUserListItem key={user.id} user={user} />
        ))}
    </tbody>
  );
}

export default CompanyAdminUserListDeletedUsers;
