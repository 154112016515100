import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type {
  CreateLithostratMemberMutation,
  CreateLithostratMemberMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import { uploadLithostratMemberUpdateRoute } from '~/paths';
import type { MemberFormValues } from '~/utils/modules/lithostrat';
import {
  formValuesToLithostratMemberInput,
  initialMember,
  memberValidationSchema,
} from '~/utils/modules/lithostrat';
import { MemberFormFields } from './MemberFormFields';

export const CREATE_LITHOSTRAT_MEMBER = gql`
  mutation createLithostratMember($member: LithostratMemberInput!) {
    createLithostratMember(member: $member) {
      id
    }
  }
`;

type Props = {
  formationId?: number;
  children: (showModal: () => void) => React.ReactNode;
};

export function CreateMemberModal({ formationId, children }: Props) {
  const navigate = useNavigate();
  const { show, showModal, hideModal } = useModalState();
  const [createMember, { loading, error }] = useMutation<
    CreateLithostratMemberMutation,
    CreateLithostratMemberMutationVariables
  >(CREATE_LITHOSTRAT_MEMBER, {});

  async function handleSubmit(values: MemberFormValues) {
    const member = formValuesToLithostratMemberInput(values);

    try {
      const { data } = await createMember({
        variables: {
          member,
        },
      });
      invariant(data);
      const memberId = data.createLithostratMember.id;
      toast.success('Member created successfully.');
      hideModal();
      navigate(uploadLithostratMemberUpdateRoute(memberId));
    } catch (err) {
      console.log('Error creating member', err);
      toast.error('There was a problem creating the member.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            ...initialMember(),
            formationId: formationId ?? null,
          }}
          validationSchema={memberValidationSchema()}
        >
          <Form>
            <Modal.Body heading="Create Member">
              <div className="space-y-4">
                <MemberFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                disabled={loading}
                onClick={hideModal}
              >
                Cancel
              </Button>
              <Button color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
