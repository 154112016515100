import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import type {
  CompanyPartsFragment,
  CreateStudyAccessMutation,
  CreateStudyAccessMutationVariables,
  StudyAccessInput,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import type { StudyAccessParent } from '~/components/upload/study/studyAccessManager/StudyAccessManager';
import { yup } from '~/utils/validation';

export const CREATE_STUDY_ACCESS = gql`
  mutation CreateStudyAccess($studyAccess: StudyAccessInput!) {
    createStudyAccess(studyAccess: $studyAccess) {
      id
    }
  }
`;

type Props = {
  parentType: StudyAccessParent;
  parentId: number;
  availableCompanies: Pick<CompanyPartsFragment, 'id' | 'name'>[];
  refetchQueries: PureQueryOptions[];
};

export function AddStudyAccess({
  parentType,
  parentId,
  availableCompanies,
  refetchQueries,
}: Props) {
  const [createStudyAccess, { loading, error }] = useMutation<
    CreateStudyAccessMutation,
    CreateStudyAccessMutationVariables
  >(CREATE_STUDY_ACCESS, {
    refetchQueries,
  });

  type FormValues = { companyId: string };
  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    // Input should have either projectId OR studyId set but not both.
    const saInput: StudyAccessInput = {
      companyId: parseInt(values.companyId),
      projectId: parentType === 'project' ? parentId : null,
      studyId: parentType === 'study' ? parentId : null,
    };

    try {
      await createStudyAccess({
        variables: {
          studyAccess: saInput,
        },
      });
      toast.success('Study access granted successfully.');
      helpers.resetForm();
    } catch (err) {
      toast.error('There was a problem creating the study access.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ companyId: '' }}
      validationSchema={yup.object({ companyId: yup.string().required() })}
    >
      <Form className="space-y-2">
        <Field
          name="companyId"
          label="Grant access to:"
          component={FormikField}
          type="select"
          options={availableCompanies.map(c => ({
            value: c.id,
            label: c.name,
          }))}
          required
        />

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Grant Access
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
