import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { Progress } from 'react-daisyui';
import { pollTilingMutation } from '~/components/upload/vom/modelData/states/UploadingState';
const INTERVAL_DELAY = 1000 * 30;

export function ArchivingState({
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [pollTiling, { loading }] = useMutation(pollTilingMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  useEffect(() => {
    const interval = window.setInterval(pollTiling, INTERVAL_DELAY);
    pollTiling();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [cesiumAssetId, pollTiling]);

  return (
    <div className="text-center">
      <Progress color={loading ? 'neutral' : 'success'} className="w-56 h-4" />
      <div className="italic text-muted">Archiving...</div>
    </div>
  );
}
