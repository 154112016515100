import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { Menu } from 'react-daisyui';
import { Outlet } from 'react-router';
import invariant from 'tiny-invariant';
import type {
  CompanyAdminPageQuery,
  CompanyAdminPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NavLink } from '~/components/common/NavLink';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';
import {
  companyAdminMachineTokensRoute,
  companyAdminRoute,
  companyAdminUsersRoute,
} from '~/paths';

const COMPANY_ADMIN_PAGE = gql`
  query CompanyAdminPage($id: Int!) {
    companyList(id: $id) {
      id
      machineTokenProvisioning
    }
  }
`;

export default function CompanyAdminPage() {
  const { authority } = useAuth();
  invariant(authority, 'Must be authenticated');
  const companyId = authority.user.companyId;

  const { data, loading } = useQuery<
    CompanyAdminPageQuery,
    CompanyAdminPageQueryVariables
  >(COMPANY_ADMIN_PAGE, { variables: { id: companyId } });

  const company = data?.companyList.find(c => c.id === companyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!company) return <NotFound />;

  return (
    <div className="grid lg:grid-cols-4 gap-6">
      <div>
        <Menu>
          <Menu.Item>
            <NavLink to={companyAdminRoute()} end>
              Company Options
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to={companyAdminUsersRoute()}>Users</NavLink>
          </Menu.Item>

          {company.machineTokenProvisioning && (
            <Menu.Item>
              <NavLink to={companyAdminMachineTokensRoute()}>
                Machine Tokens
              </NavLink>
            </Menu.Item>
          )}
        </Menu>
      </div>

      <div className="lg:col-span-3">
        <Outlet />
      </div>
    </div>
  );
}
