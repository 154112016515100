import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateLithostratTypeMutation,
  CreateLithostratTypeMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { LithostratTypeFormValues } from '~/utils/modules/lithostratType';
import {
  formValuesToLithostratTypeInput,
  initialLithostratType,
  lithostratTypeValidationSchema,
} from '~/utils/modules/lithostratType';
import { LithostratTypeFormFields } from './LithostratTypeFormFields';

const CREATE_LITHOSTRAT_TYPE = gql`
  mutation CreateLithostratType($lithostratType: LithostratTypeInput!) {
    createLithostratType(lithostratType: $lithostratType) {
      id
    }
  }
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  refetchQueries: PureQueryOptions[];
};

export function CreateLithostratTypeModal({ children, refetchQueries }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createLT, { loading, error }] = useMutation<
    CreateLithostratTypeMutation,
    CreateLithostratTypeMutationVariables
  >(CREATE_LITHOSTRAT_TYPE, {
    refetchQueries,
  });

  async function handleSubmit(values: LithostratTypeFormValues) {
    const lithostratType = formValuesToLithostratTypeInput(values);

    try {
      await createLT({ variables: { lithostratType } });
      toast.success('Lithostrat type created successfully.');
      hideModal();
    } catch (err) {
      console.log('Error creating lithostrat type', err);
      toast.error('There was a problem creating the lithostrat type.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialLithostratType()}
          validationSchema={lithostratTypeValidationSchema}
        >
          <Form>
            <Modal.Body heading="Create Lithostrat Type">
              <div className="space-y-4">
                <LithostratTypeFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>

              <Button type="submit" color="primary" loading={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
