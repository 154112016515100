import {
  faExclamationTriangle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import type { GeoreferencePartsFragment } from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { capitalize } from '~/utils/text';

type Props = {
  georeferences: GeoreferencePartsFragment[];
  onDelete: (georeferenceId: number) => Promise<void>;
  isDeleting: boolean;
};

export function CurrentGeoreferences({ georeferences, onDelete }: Props) {
  const handleDelete = (georeferenceId: number) => () =>
    onDelete(georeferenceId);

  return (
    <div>
      {georeferences.map(g => (
        <div key={g.id} className="p-2 border-t border-slate-200">
          <b>
            {capitalize(g.dataType)}
            {!g.data && (
              <span className="text-danger">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  title="No coordinates set"
                />
              </span>
            )}
          </b>

          <div className="float-right">
            <Confirm
              onConfirm={handleDelete(g.id)}
              text="This georeference will be permanently deleted."
            >
              {onConfirm => (
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={onConfirm}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </Confirm>
          </div>

          <div>
            <small className="text-xs">{g.name}</small>
          </div>
          <div>
            <small className="text-muted text-xs">{g.description}</small>
          </div>
        </div>
      ))}

      {!georeferences.length && (
        <p className="text-muted">
          No georeferences have been added. Press the Add button to get started.
        </p>
      )}
    </div>
  );
}
