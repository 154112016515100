import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import type { GeoreferencePartsFragment } from '~/apollo/generated/v3/graphql';
import { saveAs } from '~/utils/export';
import { createKmlDocument, createKmlFolder } from '~/utils/kml';

type Props = {
  name?: string;
  georeferences: GeoreferencePartsFragment[];
};

export function ExportToKML({ name = 'Georeferences', georeferences }: Props) {
  const documentName = `${name} Export`;
  const filename = documentName + '.kml';

  async function handleClick() {
    try {
      const description = 'Exported SafariDB Georeferences';
      const folder = createKmlFolder(name, description, georeferences);
      const kml = await createKmlDocument([folder]);
      saveAs(kml, filename, 'application/vnd;charset=utf-8');
    } catch (err) {
      console.log('Error writing KML', err);
    }
    console.groupEnd();
  }

  return (
    <Button
      type="button"
      color="ghost"
      size="sm"
      onClick={handleClick}
      className="gap-1"
    >
      <FontAwesomeIcon icon={faCloudDownload} /> Download as KML
    </Button>
  );
}
