import { gql } from '~/apollo/client-v3';
import {} from '@apollo/client';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik, useFormikContext } from 'formik';
import * as R from 'ramda';
import React from 'react';
import { savedDataSearchParts } from '~/apollo/fragments';
import type {
  SavedDataSearchDataPartsFragment,
  SavedDataSearchWithOutcropPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { GraphFormFields } from '~/components/dataSearch/GraphFormFields';
import { OutcropFilters } from '~/components/dataSearch/OutcropFilters';
import { MeasurementGraphCrossPlot } from '~/components/statistics/MeasurementGraph/MeasurementGraphCrossPlot';
import { MeasurementGraphHistogram } from '~/components/statistics/MeasurementGraph/MeasurementGraphHistogram';
import { MeasurementStatistics } from '~/components/statistics/MeasurementGraph/MeasurementStatistics';
import { RegressionFormFields } from '~/components/statistics/MeasurementGraph/RegressionFormFields';
import { useDebounce } from '~/hooks/debounce';
import type { CrossPlotDataPoint, HistogramDataPoint } from '~/utils/chart';
import {
  initialDataSearchOptions,
  initialSavedDataSearch,
  type DataSearchFormValues,
  type DataSearchOption,
  type DataSearchOptions,
} from '~/utils/modules/dataSearch';
import { ucwords } from '~/utils/text';
import { FilterValues } from './FilterValues';

export const savedDataSearchWithOutcropParts = gql`
  fragment savedDataSearchWithOutcropParts on SavedDataSearch {
    ...savedDataSearchParts
    outcrops {
      id
      name
      region {
        id
        name
        location {
          id
          country
        }
      }
    }
    studies {
      id
      name
      outcrops {
        id
      }
    }
  }

  ${savedDataSearchParts}
`;

type SdsType = SavedDataSearchWithOutcropPartsFragment;
type SdsDataType = SavedDataSearchDataPartsFragment;

export const sdsFilterFields: Array<keyof SdsType> = [
  'geologyType',
  'outcropCategory',
  'grossDepositionalEnvironment',
  'depositionalEnvironment',
  'depositionalSubEnvironment',
  'architecturalElement',
  'basinType',
  'climate',
  'country',
  'startAge',
  'netToGross',
  'distanceToSourceAreaDesc',
];

type Props = {
  savedDataSearch: SdsType;
  data: SdsDataType;
  options: DataSearchOptions;
};

function SavedDataSearchDisplay({
  savedDataSearch: sds,
  data,
  options,
}: Props) {
  const { values } = useFormikContext<DataSearchFormValues>();

  const title = `Measurements${
    data.revision > 0 ? ` (rev. ${data.revision})` : ''
  }`;

  const groupDataBy = useDebounce(values.groupDataBy, 1000);
  const dbMinX = useDebounce(values.histogram.minX, 1000);
  const dbMaxX = useDebounce(values.histogram.maxX, 1000);

  const crossPlotData: CrossPlotDataPoint[] = React.useMemo(() => {
    // Make sure all the data has both x and y values
    type FilteredData = SdsDataType['measurements'][number] & { y: number };
    const filtered = data.measurements.filter(
      (m): m is FilteredData => !R.isNil(m.x) && !R.isNil(m.y),
    );
    return filtered.map(f => ({
      ...f,
      label: f[groupDataBy] ?? 'undefined',
    }));
  }, [data, groupDataBy]);

  const histogramData: HistogramDataPoint[] = React.useMemo(() => {
    return data.measurements
      .map(m => ({
        ...m,
        value: m.x,
        label: m[groupDataBy] ?? 'undefined',
      }))
      .filter(d => {
        if (dbMinX && d.x < dbMinX) return false;
        if (dbMaxX && d.x > dbMaxX) return false;
        return true;
      });
  }, [data, dbMinX, dbMaxX, groupDataBy]);

  return (
    <>
      <div className="grid lg:grid-cols-4 gap-6">
        <div className="space-y-2">
          <Heading level={4}>
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            Applied Filters
          </Heading>

          {sdsFilterFields.map(field => (
            <FilterValues
              key={field}
              name={ucwords(field)}
              values={sds[field]}
            />
          ))}
        </div>

        <div className="lg:col-span-3 space-y-4">
          <Panel>
            <Panel.Heading className="flex justify-between items-center">
              <Panel.Title>{title}</Panel.Title>
              <small className="text-muted">
                Last updated{' '}
                <LocalDate
                  format={{ dateStyle: 'long' }}
                  date={data.insertedAt}
                />
              </small>
            </Panel.Heading>

            <Panel.Body className="space-y-4">
              <GraphFormFields options={options} locked />

              {sds.graphType === 'cross_plot' && (
                <>
                  <MeasurementGraphCrossPlot
                    // title={title}
                    data={crossPlotData}
                    xAxisDataType={sds.dataTypeX}
                    yAxisDataType={sds.dataTypeY ?? 'Y'}
                    showRegressionLine={values.crossPlot.showRegressionLine}
                    showZIRegressionLine={values.crossPlot.showZIRegressionLine}
                    logScaleX={values.crossPlot.logScaleX}
                    logScaleY={values.crossPlot.logScaleY}
                    expanded
                  />
                  <MeasurementStatistics
                    dataX={crossPlotData.map(d => d.x)}
                    dataY={crossPlotData.map(d => d.y)}
                  >
                    <RegressionFormFields data={crossPlotData} />
                  </MeasurementStatistics>
                </>
              )}
              {sds.graphType === 'histogram' && (
                <>
                  <MeasurementGraphHistogram
                    // title={title}
                    data={histogramData}
                    xAxisDataType={sds.dataTypeX}
                    numBins={values.histogram.numBins}
                    binWidth={values.histogram.binWidth}
                    nice={values.histogram.nice}
                    expanded={false}
                  />
                  <MeasurementStatistics
                    dataX={data.measurements.map(m => m.x)}
                  />
                </>
              )}
            </Panel.Body>
          </Panel>

          {sds.outcrops.length > 0 && (
            <OutcropFilters
              outcrops={sds.outcrops}
              studies={sds.studies}
              disabled
            />
          )}
        </div>
      </div>
    </>
  );
}

export function SavedDataSearch({
  savedDataSearch: sds,
  data,
}: {
  savedDataSearch: SavedDataSearchWithOutcropPartsFragment;
  data: SavedDataSearchDataPartsFragment;
}) {
  const count = data.measurements.length;
  const toOption = (name: string): DataSearchOption => ({ name, count });

  function toOptions(value?: string | null): DataSearchOption[];
  function toOptions(value?: string[] | null): DataSearchOption[];
  function toOptions(value?: string[] | string | null) {
    if (value instanceof Array) return value.map(toOption);
    return value ? [toOption(value)] : [];
  }

  const options: DataSearchOptions = {
    ...initialDataSearchOptions(),
    dataTypeX: toOptions(sds.dataTypeX),
    dataTypeY: toOptions(sds.dataTypeY),
    measurementQualityX: toOptions(sds.measurementQualityX),
    measurementQualityY: toOptions(sds.measurementQualityY),
    measurementCompletenessX: toOptions(sds.measurementCompletenessX),
    measurementCompletenessY: toOptions(sds.measurementCompletenessY),
  };

  return (
    <Formik onSubmit={() => {}} initialValues={initialSavedDataSearch(sds)}>
      <Form>
        <SavedDataSearchDisplay
          savedDataSearch={sds}
          data={data}
          options={options}
        />
      </Form>
    </Formik>
  );
}
