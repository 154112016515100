import type { NavLinkProps as RRNavLinkProps } from 'react-router';
import { NavLink as RRNavLink } from 'react-router';

export type NavLinkProps = RRNavLinkProps & {
  disabled?: boolean;
};

export function NavLink({ disabled, ...navLinkProps }: NavLinkProps) {
  const handleClick: RRNavLinkProps['onClick'] = event => {
    if (disabled) event.preventDefault();
  };

  return <RRNavLink {...navLinkProps} onClick={handleClick} />;
}
