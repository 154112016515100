import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Button } from 'react-daisyui';

import * as fragments from '~/apollo/fragments';
import type {
  ReviewCommentParentType,
  TargetReviewCommentsQuery,
  TargetReviewCommentsQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { Tooltip } from '~/components/common/Tooltip';
import { useAuth } from '~/contexts/auth';
import { useModalState } from '~/hooks/modal';
import { CreateReviewComment } from './CreateReviewComment';
import { ReviewComment } from './ReviewComment';

export const TARGET_REVIEW_COMMENTS = gql`
  query TargetReviewComments(
    $parentId: Int
    $parentType: ReviewCommentParentType
  ) {
    reviewCommentList(parentId: $parentId, parentType: $parentType) {
      ...reviewCommentParts
      author {
        ...publicUserParts
      }
    }
  }
  ${fragments.reviewCommentParts}
  ${fragments.publicUserParts}
`;

type Props = {
  parentType: ReviewCommentParentType;
  parentId: number;
  name: string;
};

export function TargetReviewCommentsModal({
  parentType,
  parentId,
  name,
}: Props) {
  const { isAuthenticated } = useAuth();
  const { show, showModal, hideModal } = useModalState();

  const { data } = useQuery<
    TargetReviewCommentsQuery,
    TargetReviewCommentsQueryVariables
  >(TARGET_REVIEW_COMMENTS, {
    variables: { parentType, parentId },
  });
  const refetchQueries: [PureQueryOptions<TargetReviewCommentsQueryVariables>] =
    [
      {
        query: TARGET_REVIEW_COMMENTS,
        variables: { parentType, parentId },
      },
    ];

  if (!isAuthenticated) return null;

  const reviewCommentList = R.sortBy(
    rc => rc.insertedAt,
    data?.reviewCommentList ?? [],
  );

  const hasComments = reviewCommentList.length > 0;

  return (
    <>
      <Tooltip
        message={`${reviewCommentList.length} review comment${
          reviewCommentList.length !== 1 ? 's' : ''
        }`}
      >
        <Button
          type="button"
          color="ghost"
          size="sm"
          onClick={showModal}
          className={hasComments ? 'text-error' : 'text-muted'}
        >
          <FontAwesomeIcon icon={faComment} />
        </Button>
      </Tooltip>

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading={`Review Comments for ${name}`}>
          <div className="space-y-4">
            {reviewCommentList.map(comment => (
              <ReviewComment
                key={comment.id}
                reviewComment={comment}
                refetchQueries={refetchQueries}
              />
            ))}

            {!hasComments && (
              <div className="text-center text-muted">
                <em>No review comments have been added to {name} yet.</em>
              </div>
            )}

            <CreateReviewComment
              parentId={parentId}
              parentType={parentType}
              name={name}
              refetchQueries={refetchQueries}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
