import { gql } from '~/apollo/client-v3';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  UpdateVomSetupFileMutation,
  UpdateVomSetupFileMutationVariables,
  VoSpecsTabQuery,
  VoSpecsTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';

const VO_SPECS_TAB = gql`
  query VOSpecsTab($id: Int!) {
    virtualOutcropModelSetupFile(id: $id)
  }
`;

const UPDATE_VOM_SETUP_FILE = gql`
  mutation UpdateVomSetupFile($id: Int!, $json: String!) {
    updateVirtualOutcropModelSetupFile(id: $id, json: $json)
  }
`;

export default function UpdateVomVOSpecsRoute() {
  const ctx = useUpdateVomOutletContext();
  const vomId = ctx.vom.id;

  const [value, setValue] = useState('');

  const handleDataLoaded = (json: string) => setValue(json);

  const handleEditorChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = e => {
    setValue(e.target.value);
  };

  const { data, loading } = useQuery<VoSpecsTabQuery, VoSpecsTabQueryVariables>(
    VO_SPECS_TAB,
    {
      variables: { id: vomId },
      onCompleted: data => {
        if (data.virtualOutcropModelSetupFile) {
          handleDataLoaded(data.virtualOutcropModelSetupFile);
        }
      },
    },
  );

  const [updateSetupFile, { loading: loadingUpdate, error }] = useMutation<
    UpdateVomSetupFileMutation,
    UpdateVomSetupFileMutationVariables
  >(UPDATE_VOM_SETUP_FILE, {});

  const handleSave = async () => {
    try {
      const res = await updateSetupFile({
        variables: { id: vomId, json: value },
      });
      const updatedJson = res.data?.updateVirtualOutcropModelSetupFile;
      if (!updatedJson) throw new Error('Unepected error getting response.');
      setValue(updatedJson);
      toast.success('Setup file updated successfully.');
    } catch (err) {
      console.log('Error updating setup file', err);
      if (err instanceof Error && err.message === 'json_invalid') {
        toast.error(
          'The setup file could not be updated because the JSON could not be parsed. Check to ensure the syntax is valid and try again.',
        );
      } else {
        toast.error(
          'There was a problem updating the setup file. Please see console for more details.',
        );
      }
    }
  };

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Looking up setup.json file on S3, this may take a moment...
      </SpinnerPlaceholder>
    );
  }

  return (
    <div className="space-y-4">
      {data?.virtualOutcropModelSetupFile === null && (
        <p>
          It looks like this VO doesn't have a setup file associated with it.
          You can upload a file to S3, or paste its contents below to create it.
        </p>
      )}

      <Panel>
        <Panel.Body className="space-y-4">
          <div className="form-control">
            <textarea
              className="textarea textarea-bordered h-80 font-mono"
              value={value}
              onChange={handleEditorChange}
            />
          </div>

          {/* Dummy formik wrapper for the error box */}
          <Formik initialValues={{}} onSubmit={() => {}}>
            <FormErrors graphQLError={error} />
          </Formik>
        </Panel.Body>
        <Panel.Footer className="text-right">
          <Button
            type="button"
            color="primary"
            className="btn btn-primary"
            onClick={handleSave}
            loading={loadingUpdate}
          >
            Save to S3
          </Button>
        </Panel.Footer>
      </Panel>
    </div>
  );
}
