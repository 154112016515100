import { Link } from 'react-router';
import type { LithostratWikiOverviewPageQuery } from '~/apollo/generated/v3/graphql';
import { lithostratTypeRoute } from '~/paths';

type Props = {
  lithostratType: LithostratWikiOverviewPageQuery['lithostratTypeList'][number];
};

export function LithostratTypeLink({ lithostratType }: Props): JSX.Element {
  const placeholderImg = '/assets/images/common/brand.jpg';
  const bgImg = lithostratType.diagram?.file?.signedUrl ?? placeholderImg;

  return (
    <div>
      <Link
        to={lithostratTypeRoute(lithostratType.id)}
        className="block w-full h-full p-4 border hover:border-slate-300 hover:bg-base-200"
      >
        <div
          className="w-full h-32 bg-center bg-no-repeat bg-contain"
          style={{ backgroundImage: `url(${bgImg})` }}
        />

        <div className="text-center text-base text-black">
          {lithostratType.name}
        </div>
      </Link>
    </div>
  );
}
