import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { cn } from '~/utils/common';
import React from 'react';
import { Button } from 'react-daisyui';
import type { RegionPageQuery } from '~/apollo/generated/v3/graphql';
import { GeologyTypeIcon } from '~/components/common/icons/GeologyTypeIcon';
import { ListGroup } from '~/components/common/ListGroup';
import { Tooltip } from '~/components/common/Tooltip';
import { outcropRoute } from '~/paths';

type Props = {
  outcrops: RegionPageQuery['regionList'][number]['outcrops'];
  selectedOutcropId: number | null;
  onMapButtonClick: (outcropId: number | null) => void;
};

export function OutcropList({
  outcrops,
  selectedOutcropId,
  onMapButtonClick,
}: Props) {
  const handleMarkerButtonClick = (ocId: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    // Allow deselecting with a second click
    const val = ocId === selectedOutcropId ? null : ocId;
    onMapButtonClick(val);
  };

  return (
    <ListGroup>
      {outcrops.map(oc => (
        <ListGroup.Item
          as={Link}
          key={oc.id}
          to={outcropRoute(oc.id)}
          className="flex space-between items-center gap-2 "
        >
          <div className="w-10 h-10 flex items-center justify-center">
            {oc.center && (
              <Tooltip message="Show on map">
                <Button
                  type="button"
                  color="ghost"
                  size="sm"
                  onClick={handleMarkerButtonClick(oc.id)}
                >
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className={cn(
                      'text-xl',
                      oc.id === selectedOutcropId ? 'text-error' : 'text-muted',
                    )}
                  />
                </Button>
              </Tooltip>
            )}
          </div>

          <div>{oc.name}</div>

          {oc.geologyType.map(geologyType => (
            <GeologyTypeIcon
              key={geologyType}
              geologyType={geologyType}
              className="h-5 w-auto"
            />
          ))}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
