import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import type {
  UploadVomListRouteQuery,
  UploadVomListRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { VomList } from '~/components/upload/vom/VomList';
import type { VomInterpretationState } from '~/utils/modules/vom';

export const UPLOAD_VOM_LIST_ROUTE = gql`
  query UploadVomListRoute {
    virtualOutcropModelList {
      id
      name
      acquisitionType
      acquisitionYear
      copyright
      v3GeoId
      approved
      outcropId
      outcrop {
        id
        name
        region {
          id
          location {
            country
          }
        }
      }
      project {
        id
        name
      }
      interpretations {
        id
        published
      }
      cesiumAsset {
        id
        state
        approved
        isClipping
      }
      pictures {
        id
      }
    }
  }
`;

type QueryVom = UploadVomListRouteQuery['virtualOutcropModelList'][number];
export type SortableVom = QueryVom & {
  interpretationState: VomInterpretationState;
  authors: string;
  hasV3GeoModelLinked: boolean;
  isClipping: 'Yes' | 'No' | 'undefined';
};

export function toSortableVom(vom: QueryVom): SortableVom {
  let interpretationState: VomInterpretationState = 'none';

  const interpretations = vom.interpretations ?? [];
  const hasPublishedInterp = interpretations.find(i => i.published === true);
  const hasUnpublishedInterp = interpretations.find(i => i.published === false);

  if (hasPublishedInterp) interpretationState = 'published';
  if (hasUnpublishedInterp) interpretationState = 'unpublished';

  return {
    ...vom,
    interpretationState,
    authors: vom.copyright?.join(', ') ?? '--',
    hasV3GeoModelLinked: vom.v3GeoId !== null,
    isClipping:
      vom.cesiumAsset?.isClipping === true
        ? 'Yes'
        : vom.cesiumAsset?.isClipping === false
          ? 'No'
          : 'undefined',
  };
}

export default function UploadVomListRoute() {
  const { data, loading } = useQuery<
    UploadVomListRouteQuery,
    UploadVomListRouteQueryVariables
  >(UPLOAD_VOM_LIST_ROUTE);

  const vomList = data?.virtualOutcropModelList ?? [];
  const voms = vomList.map(toSortableVom);

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <PageHeading>Virtual Outcrop Models</PageHeading>
      <VomList voms={voms} />
    </>
  );
}
