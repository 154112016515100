import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { faInfoCircle, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-daisyui';
import { Link } from 'react-router';

import * as fragments from '~/apollo/fragments';
import type {
  UpdateOutcropPalaeogeographyTabQuery,
  UpdateOutcropPalaeogeographyTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { uploadPaleoMapUpdateRoute } from '~/paths';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';

const PALAEOGEOGRAPHY_TAB = gql`
  query UpdateOutcropPalaeogeographyTab($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      paleoMaps {
        ...paleoMapParts
        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.paleoMapParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
`;

export default function UpdateOutcropPalaeogeographyRoute() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery<
    UpdateOutcropPalaeogeographyTabQuery,
    UpdateOutcropPalaeogeographyTabQueryVariables
  >(PALAEOGEOGRAPHY_TAB, {
    variables: { outcropId },
  });

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);
  const paleoMaps = outcrop?.paleoMaps ?? [];

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  if (!paleoMaps.length) {
    return (
      <Alert icon={<FontAwesomeIcon icon={faInfoCircle} className="text-xl" />}>
        No paleomaps linked to this outcrop
      </Alert>
    );
  }

  return (
    <>
      {paleoMaps.map(pm => (
        <Panel key={pm.id}>
          <Panel.Heading className="flex justify-between">
            <Panel.Title>Palaeomap #{pm.id}</Panel.Title>
            <Link
              to={uploadPaleoMapUpdateRoute(pm.id)}
              className="btn btn-xs btn-primary gap-1"
            >
              <FontAwesomeIcon icon={faPencil} /> Edit
            </Link>
          </Panel.Heading>
          <Panel.Body>
            {pm.description && (
              <div dangerouslySetInnerHTML={{ __html: pm.description }} />
            )}

            <div className="grid lg:grid-cols-12 gap-6 mt-4">
              {pm.pictures.map(picture => (
                <div key={picture.id} className="lg:col-span-4">
                  <img
                    src={picture.file.signedUrl}
                    alt={`Paleomap picture ${picture.id}`}
                    className="w-full h-auto"
                  />
                </div>
              ))}
            </div>
          </Panel.Body>
        </Panel>
      ))}
    </>
  );
}
