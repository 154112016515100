import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  UpdateLithostratFormationMutation,
  UpdateLithostratFormationMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { UPDATE_LITHOSTRAT_FORMATION } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { FormationFormFields } from '~/components/upload/lithostrat/formation/FormationFormFields';
import { useUpdateFormationOutletContext } from '~/routes/upload/lithostrat/formation/$formationId';
import type { FormationFormValues } from '~/utils/modules/lithostrat';
import {
  formValuesToFormationInput,
  initialLithostratFormation,
} from '~/utils/modules/lithostrat';

export default function UpdateFormationDetailPage() {
  const { formation } = useUpdateFormationOutletContext();

  const [updateFormation, { loading, error }] = useMutation<
    UpdateLithostratFormationMutation,
    UpdateLithostratFormationMutationVariables
  >(UPDATE_LITHOSTRAT_FORMATION);

  async function handleSubmit(values: FormationFormValues) {
    try {
      await updateFormation({
        variables: {
          id: formation.id,
          formation: formValuesToFormationInput(values),
        },
      });
      toast.success('Formation updated successfully.');
    } catch (err) {
      console.log('Error updating formation', err);
      toast.error('There was a problem updating the formation.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialLithostratFormation(formation)}
    >
      <Form className="space-y-4">
        <FormationFormFields />
        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
