import { faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import React from 'react';
import { Button } from 'react-daisyui';
import type { OutcropPartsFragment } from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { Tooltip } from '~/components/common/Tooltip';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import { KeyParametersFormFields } from '~/components/upload/keyParameter/KeyParametersFormFields';
import { OutcropAdditionalFields } from '~/components/upload/outcrop/OutcropAdditionalFields';
import type { AEFormValues } from '~/utils/modules/architecturalElement';

const connectivityTypes = ['high', 'low', 'isolated', 'undefined'];

const depositionalConfinementTypes = [
  'tectonic confinement',
  'erosional confinement',
  'unspecified confinement',
  'unconfined',
  'undefined',
];

const outcropOrderTypes = ['order 3rd', 'order 4th', 'order 5th', 'undefined'];

const shapeKinds = [
  'belt',
  'cone or fan',
  'dendroid',
  'jet',
  'lens',
  'ribbon',
  'sheet',
  'wedge',
  'other',
  'undefined',
];

type Props = {
  action: 'create' | 'update';
  outcrops: OutcropPartsFragment[];
};

const mapStrOpts = (option: string) => ({ value: option, label: option });

export function AEFormFields({ action, outcrops }: Props) {
  const [isEditingKPs, setIsEditingKPs] = React.useState(false);
  const { values, setValues, resetForm } = useFormikContext<AEFormValues>();

  function toggleEditing() {
    if (!isEditingKPs) {
      setValues({
        ...values,
        geologyType: '',
        grossDepositionalEnvironment: '',
        depositionalEnvironment: '',
        depositionalSubEnvironment: '',
        architecturalElement: '',
      });
    } else {
      resetForm();
    }

    setIsEditingKPs(!isEditingKPs);
  }

  const showKPToggle = action === 'update';
  const showKPFields = action === 'create' || isEditingKPs;

  const geologyTypes = R.pipe(
    () => outcrops,
    R.pluck('geologyType'),
    R.filter(R.complement(R.isNil)), // Not sure if there are nulls for gt in the db still
    R.flatten,
    R.uniq,
  )();

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Field name="name" label="Name" component={FormikField} required />

          <Field
            name="outcropId"
            label="Outcrop"
            component={FormikField}
            type="select"
            options={outcrops.map(outcrop => ({
              value: outcrop.id,
              label: outcrop.name,
            }))}
            required
          />

          <Field
            name="description"
            label="Description"
            component={FormikField}
          />

          <Field name="comments" label="Comments" component={FormikField} />

          <Field
            name="connectivity"
            label="Connectivity"
            component={FormikField}
            type="select"
            options={connectivityTypes.map(mapStrOpts)}
          />

          <Field
            name="depositionalConfinement"
            label="Depositional Confinement"
            component={FormikField}
            type="select"
            options={depositionalConfinementTypes.map(mapStrOpts)}
          />

          <Field
            name="shapeKind"
            label="Shape kind"
            component={FormikField}
            type="select"
            options={shapeKinds.map(mapStrOpts)}
          />

          <Field
            name="outcropOrder"
            label="Outcrop Order"
            component={FormikField}
            type="select"
            options={outcropOrderTypes.map(mapStrOpts)}
          />

          <Field
            name="background"
            label="Background"
            component={FormikField}
            type="radio"
            options={[
              { value: true, text: 'Yes' },
              { value: false, text: 'No' },
            ]}
            inline
            required
          />
        </div>

        <div>
          {showKPFields && (
            <KeyParametersFormFields geologyTypes={geologyTypes} />
          )}

          {showKPToggle && (
            <>
              {!isEditingKPs && (
                <>
                  <KeyParametersPanel
                    keyParameters={[
                      {
                        id: -1,
                        grossDepositionalEnvironment:
                          values.grossDepositionalEnvironment,
                        depositionalEnvironment: values.depositionalEnvironment,
                        depositionalSubEnvironment:
                          values.depositionalSubEnvironment,
                        architecturalElement: values.architecturalElement,
                        dominant: true,
                      },
                    ]}
                  />

                  <Tooltip message="Discard the current key parameters and set new values">
                    <div className="text-center">
                      <Button
                        type="button"
                        color="ghost"
                        size="xs"
                        onClick={toggleEditing}
                        className="gap-1"
                      >
                        <FontAwesomeIcon icon={faPencil} /> Edit
                      </Button>
                    </div>
                  </Tooltip>
                </>
              )}

              {isEditingKPs && (
                <div className="text-center mt-2">
                  <Tooltip message="Discard ALL pending changes for this AE and reset to previous values">
                    <Button
                      type="button"
                      color="ghost"
                      size="sm"
                      className="gap-1 text-error"
                      onClick={toggleEditing}
                    >
                      <FontAwesomeIcon icon={faRefresh} /> Discard all changes
                    </Button>
                  </Tooltip>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {values.geologyType && (
        <div className="mt-4">
          <OutcropAdditionalFields
            entity="ae"
            geologyTypes={[values.geologyType]}
            keyParameters={[values]}
          />
        </div>
      )}
    </>
  );
}
