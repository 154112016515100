import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  PicturePartsFragment,
  UpdatePictureMutation,
  UpdatePictureMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { PictureParent } from '~/apollo/generated/v3/graphql';
import { UPDATE_PICTURE } from '~/apollo/operations/supportingObject';
import { FormErrors } from '~/components/common/FormErrors';
import { PictureFormFields } from '~/components/upload/supportingObject/PictureFormFields';
import type { PictureFormValues } from '~/utils/modules/supportObject';
import {
  initialPicture,
  formValuesToPictureInput,
  pictureValidationSchema,
} from '~/utils/modules/supportObject';

type Props = {
  picture: PicturePartsFragment;
  onUpdateSuccess: () => void;
};

export function UpdateVomPicture({ picture, onUpdateSuccess }: Props) {
  const [updatePicture, { loading, error }] = useMutation<
    UpdatePictureMutation,
    UpdatePictureMutationVariables
  >(UPDATE_PICTURE, {});

  async function handleSubmit(values: PictureFormValues) {
    const input = formValuesToPictureInput(values);

    try {
      await updatePicture({
        variables: {
          id: picture.id,
          picture: input,
        },
      });
      toast.success('Picture updated successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error updating picture', err);
      toast.error('There was a problem updating the picture');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialPicture(picture)}
      validationSchema={pictureValidationSchema()}
    >
      <Form className="space-y-4">
        <PictureFormFields
          parentType={PictureParent.Vom}
          parentId={picture.id}
        />
        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
