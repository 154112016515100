import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Button, Progress } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  DeleteGeoreferenceMutation,
  DeleteGeoreferenceMutationVariables,
  GeoreferenceParent,
  GeoreferencePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { GeoreferenceDataType } from '~/apollo/generated/v3/graphql';
import {
  CREATE_GEOREFERENCE,
  DELETE_GEOREFERENCE,
} from '~/apollo/operations/georeference';
import { Heading } from '~/components/common/Heading';
import { Modal } from '~/components/common/Modal';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import type { DataTypeLimits } from '~/components/upload/georeference/GeoreferenceManager';
import { useModalState } from '~/hooks/modal';
import type { GeoreferenceImportInput } from '~/utils/geojson';
import { kmlToGeoreferences } from '~/utils/geojson';
import { importToGeoreferenceInput } from '~/utils/modules/georeference';
import { ClearExistingOption } from './ClearExistingOption';
import { ConfirmData } from './ConfirmData';
import { FileUpload } from './FileUpload';

type Props = {
  children: (showModal: () => void) => JSX.Element;
  currentGeoreferences: GeoreferencePartsFragment[];
  parentType: GeoreferenceParent;
  parentId: number;
  limits?: DataTypeLimits;
  refetchQueries?: PureQueryOptions[];
};

export function ImportKML({
  children,
  currentGeoreferences,
  parentType,
  parentId,
  limits,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [shouldClearExisting, setShouldClearExisting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [importInput, setImportInput] = useState<GeoreferenceImportInput[]>();
  const [createGeoreference, { loading: isCreating }] =
    useMutation(CREATE_GEOREFERENCE);
  const [deleteGeoreference, { loading: isDeleting }] = useMutation<
    DeleteGeoreferenceMutation,
    DeleteGeoreferenceMutationVariables
  >(DELETE_GEOREFERENCE);

  const loading = isCreating || isDeleting;

  // If existing georeferences will be cleared on import, ignore them when calculating the limits
  const importedGeorefs: GeoreferenceImportInput[] =
    importInput?.filter(g => g.uploadStatus === null) ?? [];

  const getGeorefsForLimit = () => {
    if (!importedGeorefs.length) return [];
    if (shouldClearExisting) return importedGeorefs;
    return [...currentGeoreferences, ...importedGeorefs];
  };

  const georefsForLimit = getGeorefsForLimit();

  const getLimitsExceeded = () => {
    if (!limits) return {};

    return Object.keys(limits).reduce<Record<string, boolean>>((acc, cur) => {
      const keyLimits = limits[cur as keyof DataTypeLimits];
      if (!keyLimits) return acc;
      const numMatches = georefsForLimit.filter(g => g.dataType === cur);
      return { ...acc, [cur]: numMatches.length > keyLimits };
    }, {});
  };

  const isLimitsExceeded = R.pipe(
    () => getLimitsExceeded(),
    R.values,
    R.any(R.equals(true)),
  )();

  const isEditingAny = (importInput || []).reduce((acc, cur) => {
    return acc || cur.isEditing;
  }, false);

  /** Sets the first matching point/polygon to a centre/outline if none is set already */
  function setFirstPrimaryTypes(
    curGeorefs: GeoreferencePartsFragment[],
    pendingGeorefs: GeoreferenceImportInput[],
  ): GeoreferenceImportInput[] {
    const curOutline = curGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Outline,
    );
    const curCentre = curGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Centre,
    );

    const curPendingOutline = pendingGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Outline,
    );
    const curPendingCentre = pendingGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Centre,
    );

    let nextGeorefs = pendingGeorefs;

    if (!curOutline && limits?.OUTLINE !== 0 && !curPendingOutline) {
      const firstPolygonIdx = nextGeorefs.findIndex(
        g => g.dataType === GeoreferenceDataType.Polygon,
      );
      if (firstPolygonIdx > -1) {
        const updatedPolygon = R.assoc(
          'dataType',
          GeoreferenceDataType.Outline,
          nextGeorefs[firstPolygonIdx],
        );
        nextGeorefs = R.update(firstPolygonIdx, updatedPolygon, nextGeorefs);
      }
    }

    if (!curCentre && limits?.CENTRE !== 0 && !curPendingCentre) {
      const firstPointIdx = nextGeorefs.findIndex(g => g.dataType === 'POINT');
      if (firstPointIdx > -1) {
        const updatedPoint = R.assoc(
          'dataType',
          'CENTRE',
          nextGeorefs[firstPointIdx],
        );
        // @ts-expect-error this is correct, we need to fix georeferences as a whole
        // because this is such a mess to deal with
        nextGeorefs = R.update(firstPointIdx, updatedPoint, nextGeorefs);
      }
    }

    return nextGeorefs;
  }

  useEffect(() => {
    if (shouldClearExisting) {
      // When the clear existing box is checked, try to set a new centre/outline
      const primaries = setFirstPrimaryTypes([], importInput ?? []);
      setImportInput(primaries);
    }
  }, [shouldClearExisting]);

  function handleParseFileSuccess(kml: string) {
    try {
      console.log('Converting to GeoJSON...');
      const parsedGeorefs = kmlToGeoreferences(kml);
      const parsedWithPrimaries = setFirstPrimaryTypes(
        currentGeoreferences,
        parsedGeorefs,
      );
      setImportInput(parsedWithPrimaries);
      console.log('Parsed georefs from file:', parsedGeorefs);
    } catch (err) {
      console.log('Error parsing to geojson', err);
    }

    setIsUploading(false);
  }

  async function handleUpload(queue: GeoreferenceImportInput[]) {
    if (isEditingAny) {
      window.alert(
        `There is a georeference currently being edited. Save or discard your changes before continuing.`,
      );
      return;
    }
    if (!importInput) {
      throw new Error(
        'No georeferences pending. This state should not be possible.',
      );
    }

    // Set all georeferences to pending
    const nextGeorefs = queue.map(
      g => ({ ...g, uploadStatus: 'pending' }) as GeoreferenceImportInput,
    );
    setIsUploading(true);
    setImportInput(nextGeorefs);

    // Upload georeferences
    // let numUploadSuccess = 0;
    let numUploadFailed = 0;
    let i;
    for (i = 0; i < nextGeorefs.length; i++) {
      const g = nextGeorefs[i];
      const refetchParam =
        i === nextGeorefs.length - 1 ? { refetchQueries } : {};

      console.log('Creating georef with parent:', parentType, parentId);

      try {
        await createGeoreference({
          variables: {
            parentId,
            parentType,
            georeference: importToGeoreferenceInput(g),
          },
          ...refetchParam,
        });
        console.log('upload successful', i);
        g.uploadStatus = 'success';
        // numUploadSuccess++;
      } catch (err) {
        console.log('upload failed', i);
        g.uploadStatus = 'failed';
        numUploadFailed++;
        console.log('Error uploading georef');
      }

      setImportInput([...nextGeorefs]);
    }

    if (numUploadFailed === 0) {
      toast.success('All imported georeferences saved successfully.');
    } else {
      // Something went wrong with uploading one or more new georefs,
      // show an error toast and bail out of deleting if they checked the box.
      let errorMessage = `There was a problem saving ${numUploadFailed} georeferences.`;
      if (shouldClearExisting) {
        errorMessage +=
          ' Cancelling request - existing georeferences will NOT be deleted.';
      }
      toast.error(errorMessage);
      return;
    }

    // Delete existing georeferences if option was checked AND none failed the upload
    if (shouldClearExisting && numUploadFailed === 0) {
      let j: number;
      // let numDeleteSuccess = 0;
      let numDeleteFailed = 0;
      for (j = 0; j < currentGeoreferences.length; j++) {
        const georef = currentGeoreferences[j];
        const refetchParam =
          j === currentGeoreferences.length - 1 ? { refetchQueries } : {};

        try {
          await deleteGeoreference({
            variables: {
              parent: parentType,
              parentId,
              id: georef.id,
            },
            ...refetchParam,
          });
          // numDeleteSuccess++;
        } catch (err) {
          console.log(`Error deleting georeference '${georef.name}'`, err);
          numDeleteFailed++;
        }
      }

      if (numDeleteFailed === 0) {
        toast.success('All current georeferences cleared successfully.');
      } else {
        toast.error(
          `There was a problem deleting ${numDeleteFailed} existing georeferences.`,
        );
      }
    }
  }

  function percentComplete() {
    if (!importInput) return 0;
    const completedGeorefs = importInput.filter(
      g => g.uploadStatus === 'success' || g.uploadStatus === 'failed',
    );
    const percent = Math.ceil(
      (completedGeorefs.length / importInput.length) * 100,
    );
    return percent;
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading="Import Georeferences">
          <div className="space-y-2">
            <Heading level={3}>Step 1</Heading>
            <FileUpload onUploadSuccess={handleParseFileSuccess} />
          </div>

          {importInput && importInput.length > 0 && (
            <div className="space-y-4 mt-4">
              <Heading level={3}>Step 2</Heading>
              <p>
                Confirm imported data. In this step, you can remove elements
                from being saved, and change the data type if an element
                represents a <b>centre</b> or an <b>outline</b>.
              </p>

              <div>
                <ClearExistingOption
                  shouldClear={shouldClearExisting}
                  setShouldClear={setShouldClearExisting}
                />
              </div>

              <div>
                <ConfirmData
                  georeferences={importInput}
                  setGeoreferences={setImportInput}
                />
              </div>
            </div>
          )}

          {/* Limit warning */}
          {isLimitsExceeded && (
            <div className="grid grid-cols-12 gap-6 my-4">
              <div className="col-span-8 col-start-2">
                <div className="alert alert-error py-2 px-4">
                  <div className="block">
                    <Heading level={3}>Limits Exceeded</Heading>
                    <p>
                      There are too many georeferences of the same type. The
                      limits are:
                    </p>
                    <ul className="list-disc list-inside">
                      {R.keys(getLimitsExceeded()).map((k: string) => (
                        <li key={k}>
                          <strong>{k}</strong>:{' '}
                          {limits?.[k as keyof DataTypeLimits]}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Save button */}
          {importInput && importInput.length > 0 && (
            <div className="text-center mt-4">
              <Button
                type="button"
                color="primary"
                onClick={() => handleUpload([...importInput])}
                disabled={isUploading || isLimitsExceeded}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faCloudUpload} /> Commit Changes
              </Button>
            </div>
          )}

          {/* Progress bar for upload queue */}
          {isUploading && (
            <Progress
              className="mt-4 w-full h-6"
              value={percentComplete()}
              max={100}
              color="info"
            />
          )}

          <div className="text-center">
            {isCreating && (
              <SpinnerPlaceholder>
                Uploading geoferences, please wait...
              </SpinnerPlaceholder>
            )}
            {isDeleting && (
              <SpinnerPlaceholder>
                Deleting current georeferences, please wait...
              </SpinnerPlaceholder>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            color="ghost"
            onClick={hideModal}
            disabled={loading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
