import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  DeleteIssueCommentMutation,
  DeleteIssueCommentMutationVariables,
  IssueDetailPageQuery,
} from '~/apollo/generated/v3/graphql';
import { Role } from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { useAuth } from '~/contexts/auth';

export const DELETE_ISSUE_COMMENT = gql`
  mutation DeleteIssueComment($id: Int!) {
    deleteIssueComment(id: $id)
  }
`;

const dateFormat: Intl.DateTimeFormatOptions = {
  dateStyle: 'long',
  timeStyle: 'short',
};

type CommentType =
  IssueDetailPageQuery['issueList'][number]['comments'][number];

type Props = {
  comment: Omit<CommentType, '__typename'>;
  isMainComment?: boolean;
  refetchQueries: PureQueryOptions[];
};

export function IssueComment({
  comment,
  isMainComment = false,
  refetchQueries,
}: Props) {
  const { hasAnyRole } = useAuth();
  const [deleteComment, { loading }] = useMutation<
    DeleteIssueCommentMutation,
    DeleteIssueCommentMutationVariables
  >(DELETE_ISSUE_COMMENT, {
    variables: { id: comment.id },
    refetchQueries,
  });

  const canDelete = !isMainComment && hasAnyRole([Role.RoleAdmin]);

  async function handleDelete() {
    try {
      await deleteComment();
      toast.success('Comment deleted successfully.');
    } catch (err) {
      console.log('Error deleting comment', err);
      toast.error(
        'There was a problem deleting the comment. Please try again.',
      );
    }
  }

  return (
    <Panel variant={isMainComment ? 'primary' : 'default'}>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          <b>{comment.author.name}</b>{' '}
          <small>{comment.author.company.name}</small>
        </Panel.Title>

        <div className="flex items-center gap-2">
          <LocalDate date={comment.dateCreated} format={dateFormat} />

          {canDelete && (
            <Confirm
              onConfirm={handleDelete}
              text="This comment will be permanently deleted."
            >
              {showConfirm => (
                <Tooltip message="Delete this comment">
                  <Button
                    type="button"
                    color="error"
                    size="xs"
                    onClick={showConfirm}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Tooltip>
              )}
            </Confirm>
          )}
        </div>
      </Panel.Heading>
      <Panel.Body>
        <div className="whitespace-pre-line">{comment.content}</div>
      </Panel.Body>
    </Panel>
  );
}
