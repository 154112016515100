import type { PureQueryOptions } from '@apollo/client';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { Modal } from '~/components/common/Modal';
import { PictureUpload } from '~/components/upload/file/PictureUpload';
import { useModalState } from '~/hooks/modal';
import type { PictureParent } from '~/apollo/generated/v3/graphql';

type Props = {
  parentType: PictureParent;
  parentId: number;
  disabled: boolean;
  refetchQueries: PureQueryOptions[];
  onUploadSuccess?: () => Promise<void> | void;
};

export function SupportingObjectUploadPictureModal({
  parentType,
  parentId,
  disabled,
  refetchQueries,
  onUploadSuccess,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      <Button
        type="button"
        color="ghost"
        size="xs"
        onClick={showModal}
        disabled={disabled}
        className="inline-block space-x-1"
      >
        <FontAwesomeIcon icon={faUpload} /> Upload
      </Button>

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Upload Pictures">
          <PictureUpload
            parentType={parentType}
            parentId={parentId}
            refetchQueries={refetchQueries}
            onUploadSuccess={onUploadSuccess}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
