import type { PureQueryOptions } from '@apollo/client';
import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import type { ButtonProps } from 'react-daisyui';
import { Button } from 'react-daisyui';
import type { EditReportPageQuery } from '~/apollo/generated/v3/graphql';
import { ReportItemType } from '~/apollo/generated/v3/graphql';
import { AddBookmark } from '~/components/report/ReportEditor/AddItem/AddBookmark';
import { AddText } from '~/components/report/ReportEditor/AddItem/AddText';
import { rejectNil } from '~/utils/common';

export function ReportEditorAddItem({
  report,
  isCompany,
  refetchQueries,
}: {
  report: EditReportPageQuery['reportList'][number];
  isCompany: boolean;
  refetchQueries: PureQueryOptions[];
}) {
  const [itemType, setItemType] = useState<ReportItemType>(
    ReportItemType.Bookmark,
  );

  const currentBookmarkIds = report.items
    .map(item => item.bookmarkId)
    .filter(rejectNil);

  return (
    <div className="space-y-4">
      <ItemTypeSelector itemType={itemType} onSelect={t => setItemType(t)} />

      {itemType === ReportItemType.Bookmark && (
        <AddBookmark
          reportId={report.id}
          currentBookmarkIds={currentBookmarkIds}
          isCompany={isCompany}
          refetchQueries={refetchQueries}
        />
      )}
      {itemType === ReportItemType.Text && (
        <AddText reportId={report.id} refetchQueries={refetchQueries} />
      )}
    </div>
  );
}

function ItemTypeSelector({
  itemType,
  onSelect,
}: {
  itemType: ReportItemType;
  onSelect: (itemType: ReportItemType) => void;
}) {
  const handleClick = (itemType: ReportItemType) => () => onSelect(itemType);

  return (
    <div className="flex justify-center items-center gap-6">
      <ItemTypeButton
        icon={faBookmark}
        label="Bookmark"
        selected={itemType === ReportItemType.Bookmark}
        onClick={handleClick(ReportItemType.Bookmark)}
      />
      <ItemTypeButton
        icon={faComment}
        label="Text Section"
        selected={itemType === ReportItemType.Text}
        onClick={handleClick(ReportItemType.Text)}
      />
    </div>
  );
}

function ItemTypeButton({
  icon,
  label,
  selected = false,
  ...buttonProps
}: {
  icon: FontAwesomeIconProps['icon'];
  label: React.ReactNode;
  selected?: boolean;
} & ButtonProps) {
  return (
    <div className="text-center">
      <Button
        type="button"
        color={selected ? 'primary' : 'ghost'}
        size="lg"
        className="h-auto"
        {...buttonProps}
      >
        <div className="space-y-2 p-6 block">
          <FontAwesomeIcon icon={icon} className="text-3xl" />
          <div>{label}</div>
        </div>
      </Button>
    </div>
  );
}
