import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { miniModelParts } from '~/apollo/fragments';
import type {
  MiniModelPartsFragment,
  UpdateMiniModelMutation,
  UpdateMiniModelMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { MiniModelFormFields } from '~/components/supportingObject/urlBasedSO/MiniModelFormFields';
import type { MiniModelFormValues } from '~/utils/modules/urlBasedSO';
import {
  initialMiniModel,
  miniModelValidationSchema,
  toMiniModelInput,
} from '~/utils/modules/urlBasedSO';

const UPDATE_MINI_MODEL = gql`
  mutation UpdateMiniModel($id: Int!, $miniModel: UpdateMiniModelInput!) {
    updateMiniModel(id: $id, miniModel: $miniModel) {
      ...miniModelParts
    }
  }

  ${miniModelParts}
`;

export function UpdateMiniModelForm({
  miniModel,
  onUpdateSuccess,
}: {
  miniModel: MiniModelPartsFragment;
  onUpdateSuccess?: () => void;
}) {
  const [updateMiniModel, { loading, error }] = useMutation<
    UpdateMiniModelMutation,
    UpdateMiniModelMutationVariables
  >(UPDATE_MINI_MODEL);

  async function handleSubmit(values: MiniModelFormValues) {
    try {
      await updateMiniModel({
        variables: {
          id: miniModel.id,
          miniModel: toMiniModelInput(values),
        },
      });
      toast.success('Mini-model saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving mini-model', err);
      toast.error('There was a problem saving the mini-model.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialMiniModel(miniModel)}
      validationSchema={miniModelValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <MiniModelFormFields />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
