import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation, useQuery } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import type * as schema from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { FormikReactSelectField } from '~/components/common/FormikField/FormikReactSelectField';
import { uploadRegionUpdateRoute } from '~/paths';
import { yup } from '~/utils/validation';

const CREATE_LITHOSTRAT_WIKI_REGION_LINK = gql`
  mutation CreateLithostratWikiRegionLink(
    $lithostratWikiId: Int!
    $regionId: Int!
  ) {
    lithostratWikiRegionLinkCreate(
      lithostratWikiId: $lithostratWikiId
      regionId: $regionId
    ) {
      id
      regions {
        id
        name
      }
    }
  }
`;

const DELETE_LITHOSTRAT_WIKI_REGION_LINK = gql`
  mutation DeleteLithostratWikiRegionLink(
    $lithostratWikiId: Int!
    $regionId: Int!
  ) {
    lithostratWikiRegionLinkDelete(
      lithostratWikiId: $lithostratWikiId
      regionId: $regionId
    )
  }
`;

type FormValues = {
  regionId: string;
};

type Props = {
  lithostratWikiId: number;
  currentRegions: Array<Pick<schema.RegionPartsFragment, 'id' | 'name'>>;
  refetchQueries: PureQueryOptions[];
};

export function LithostratWikiRegionManager({
  lithostratWikiId,
  currentRegions,
  refetchQueries,
}: Props) {
  const [createLink, { loading: loadingCreate }] = useMutation<
    schema.CreateLithostratWikiRegionLinkMutation,
    schema.CreateLithostratWikiRegionLinkMutationVariables
  >(CREATE_LITHOSTRAT_WIKI_REGION_LINK, { refetchQueries });

  const [deleteLink, { loading: loadingDelete }] = useMutation<
    schema.DeleteLithostratWikiRegionLinkMutation,
    schema.DeleteLithostratWikiRegionLinkMutationVariables
  >(DELETE_LITHOSTRAT_WIKI_REGION_LINK, { refetchQueries });

  async function handleCreate(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const regionId = parseInt(values.regionId);
    try {
      await createLink({
        variables: {
          lithostratWikiId,
          regionId,
        },
      });
      toast.success('Region linked to this wiki page successfully.');
      helpers.resetForm({ values: { regionId: '' } });
    } catch (e) {
      console.log('Error creating link:', e);
      toast.error(
        'There was a problem creating the link; please reload the page and try again.',
      );
    }
  }

  async function handleDelete(regionId: number) {
    const confirmText = 'Are you sure you want to remove this region link?';
    if (!window.confirm(confirmText)) return;

    try {
      await deleteLink({ variables: { lithostratWikiId, regionId } });
      toast.success('Region unlinked from this wiki page.');
    } catch (e) {
      console.log('Error deleting link:', e);
      toast.error(
        'There was a problem deleting the link; please reload the page and try again.',
      );
    }
  }

  const regions = R.sortBy(region => region.name, currentRegions);

  return (
    <div className="space-y-4">
      <div>
        {!regions.length && (
          <div className="text-center text-muted text-sm italic">
            This wiki page isn't linked to any regions yet.
          </div>
        )}

        <div className="space-y-1">
          {regions.map(r => (
            <div
              key={r.id}
              className="flex justify-between gap-6 items-center p-2 hover:bg-slate-100 border border-slate-300 rounded"
            >
              <div className="font-normal space-x-2">
                <Link
                  to={uploadRegionUpdateRoute(r.id)}
                  target="_blank"
                  className="link"
                >
                  {r.name}
                </Link>
                <span className="text-sm text-muted cursor-default">
                  {r.id}
                </span>
              </div>

              <Button
                type="button"
                color="ghost"
                size="xs"
                onClick={() => handleDelete(r.id)}
                loading={loadingDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          ))}
        </div>
      </div>

      <Formik
        onSubmit={handleCreate}
        initialValues={{ regionId: '' }}
        validationSchema={yup.object({
          regionId: yup.number().required(),
        })}
      >
        <Form>
          <div className="space-y-2">
            <CreateLithostratWikiRegionLinkFormFields
              currentRegions={currentRegions}
            />

            <div className="text-center">
              <Button
                type="submit"
                color="primary"
                size="sm"
                loading={loadingCreate}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

const CREATE_LITHOSTRAT_WIKI_REGION_LINK_FORM_OPTIONS = gql`
  query CreateLithostratWikiRegionLinkFormOptions {
    regionList {
      id
      name
      location {
        country
      }
    }
  }
`;

type FormFieldsProps = {
  currentRegions: Pick<schema.RegionPartsFragment, 'id'>[];
};

function CreateLithostratWikiRegionLinkFormFields({
  currentRegions,
}: FormFieldsProps) {
  const { data, loading } =
    useQuery<schema.CreateLithostratWikiRegionLinkFormOptionsQuery>(
      CREATE_LITHOSTRAT_WIKI_REGION_LINK_FORM_OPTIONS,
    );

  const currentRegionIds = currentRegions.map(r => r.id);

  const options = R.sortBy(region => region.name, data?.regionList ?? []).map(
    r => ({
      value: r.id,
      label: `${r.name} (${r.id})`,
      disabled: currentRegionIds.includes(r.id),
    }),
  );

  return (
    <Field
      name="regionId"
      label="Link a region"
      component={FormikField}
      type={FormikReactSelectField}
      options={options}
      disabled={loading}
    />
  );
}
