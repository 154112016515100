import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { type EditReportPageQuery } from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { ItemFormFieldsText } from '~/components/report/ReportEditor/ItemFormFieldsText';
import { useUpdateReportItemMutation } from '~/components/report/ReportEditor/operations';
import type { ReportItemFormValuesText } from '~/utils/modules/report';
import {
  reportItemTextInitialValues,
  reportItemTextValidationSchema,
} from '~/utils/modules/report';

type Report = EditReportPageQuery['reportList'][number];
type Item = Report['items'][number];

export function EditItemText({
  item,
  onUpdateSuccess,
}: {
  item: Item;
  onUpdateSuccess: () => void;
}) {
  const [updateReportItem, { loading, error }] = useUpdateReportItemMutation();

  async function handleSubmit(values: ReportItemFormValuesText) {
    try {
      await updateReportItem({
        variables: {
          reportItemId: item.id,
          reportItem: {
            text: {
              title: values.title.trim(),
              text: values.text.trim() || null,
            },
          },
        },
      });
      toast.success('Report item updated successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error updating item', err);
      toast.error(
        'There was a problem updating the report item; please try again.',
      );
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={reportItemTextInitialValues(item)}
      validationSchema={reportItemTextValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <ItemFormFieldsText />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
