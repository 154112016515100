import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Toggle } from 'react-daisyui';
import { toast } from 'react-toastify';
import { dmvParts } from '~/apollo/fragments';
import type {
  DefaultMeasurementsViewInput,
  DmvPartsFragment,
  SaveDmvMutation,
  SaveDmvMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { SavedDataSearchGraphType } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { DmvFormFields } from '~/components/upload/defaultMeasurementsView/DmvFormFields';
import { yup } from '~/utils/validation';

type Props = (
  | { type: 'outcrop'; outcropId: number }
  | { type: 'study'; studyId: number }
) & {
  dmv: DmvPartsFragment | null;
  refetchQueries?: PureQueryOptions[];
};

export type DmvFormValues = {
  graphType: SavedDataSearchGraphType;
  crossPlotDataTypeX: string;
  crossPlotDataTypeY: string;
  crossPlotLogScaleX: boolean;
  crossPlotLogScaleY: boolean;
  histogramDataType: string;
};

const validationSchema = yup.object({
  graphType: yup
    .string()
    .oneOf(Object.values(SavedDataSearchGraphType))
    .required(),
  crossPlotDataTypeX: yup.string().when('graphType', {
    is: SavedDataSearchGraphType.CrossPlot,
    then: schema => schema.min(1).required(),
  }),
  crossPlotDataTypeY: yup.string().when('graphType', {
    is: SavedDataSearchGraphType.CrossPlot,
    then: schema => schema.min(1).required(),
  }),
  histogramDataType: yup.string().when('graphType', {
    is: SavedDataSearchGraphType.Histogram,
    then: schema => schema.min(1).required(),
  }),
});

function initialValues(dmv: DmvPartsFragment | null): DmvFormValues {
  return {
    graphType: dmv?.graphType ?? SavedDataSearchGraphType.CrossPlot,
    crossPlotDataTypeX: dmv?.crossPlotDataTypeX ?? '',
    crossPlotDataTypeY: dmv?.crossPlotDataTypeY ?? '',
    crossPlotLogScaleX: dmv?.crossPlotLogScaleX ?? false,
    crossPlotLogScaleY: dmv?.crossPlotLogScaleY ?? false,
    histogramDataType: dmv?.histogramDataType ?? '',
  };
}

function toDmvInput(formValues: DmvFormValues): DefaultMeasurementsViewInput {
  return {
    graphType: formValues.graphType,
    crossPlotDataTypeX: formValues.crossPlotDataTypeX.trim(),
    crossPlotDataTypeY: formValues.crossPlotDataTypeY.trim(),
    crossPlotLogScaleX: formValues.crossPlotLogScaleX ?? false,
    crossPlotLogScaleY: formValues.crossPlotLogScaleY ?? false,
    histogramDataType: formValues.histogramDataType.trim(),
  };
}

const SAVE_DMV = gql`
  mutation SaveDmv(
    $outcropId: Int
    $studyId: Int
    $input: DefaultMeasurementsViewInput
  ) {
    saveDefaultMeasurementsView(
      outcropId: $outcropId
      studyId: $studyId
      input: $input
    ) {
      ...dmvParts
    }
  }

  ${dmvParts}
`;

export function UpdateDmvForm(props: Props) {
  const outcropId = props.type === 'outcrop' ? props.outcropId : null;
  const studyId = props.type === 'study' ? props.studyId : null;

  const [isAuto, setIsAuto] = useState(props.dmv === null);

  const [saveDmv, { loading: loadingSaveDmv }] = useMutation<
    SaveDmvMutation,
    SaveDmvMutationVariables
  >(SAVE_DMV, { refetchQueries: props.refetchQueries });

  async function handleSubmit(values: DmvFormValues) {
    if (isAuto) {
      // When "auto" is selected, set input to null
      try {
        const result = await saveDmv({
          variables: { outcropId, studyId, input: null },
        });
        if (result.data?.saveDefaultMeasurementsView === null) {
          toast.success('Successfully cleared default measurements view.');
        } else {
          console.log(result);
          toast.error('There was a problem clearing the view.');
        }
      } catch (err) {
        console.log('Error clearing view', err);
        toast.error('There was a problem clearing the view.');
      }
    } else {
      try {
        const input = toDmvInput(values);
        await saveDmv({
          variables: { outcropId, studyId, input },
        });
        toast.success('Default measurements options saved.');
      } catch (err) {
        console.log('Error saving DMV', err);
        toast.error(
          'There was a problem saving the options, please reload the page and try again.',
        );
      }
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues(props.dmv)}
      validationSchema={isAuto ? undefined : validationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Default Measurements View</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="form-control max-w-xl mx-auto">
              <label className="label cursor-pointer justify-start items-start gap-2">
                <Toggle
                  name="isAuto"
                  onChange={() => setIsAuto(!isAuto)}
                  checked={isAuto}
                  color={isAuto ? 'primary' : 'ghost'}
                />

                <div className="text-base">
                  <div className={isAuto ? 'font-bold' : 'text-muted'}>
                    Automatic
                  </div>
                  {isAuto ? (
                    <div className="text-muted">
                      Automatically try to derive the best set of options based
                      on the default preferred data types. Falls back to the
                      options with the most available measurements.
                    </div>
                  ) : (
                    <div className="text-muted">
                      Manually select options below to set a default view for
                      the graph(s).
                    </div>
                  )}
                </div>
              </label>
            </div>

            {!isAuto && (
              <DmvFormFields outcropId={outcropId} studyId={studyId} />
            )}
          </Panel.Body>

          <Panel.Footer className="p-2 text-right">
            <Button
              type="submit"
              color="primary"
              disabled={loadingSaveDmv}
              loading={loadingSaveDmv}
            >
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
