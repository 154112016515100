import { gql } from '~/apollo/client-v3';
import { useMutation, type PureQueryOptions } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  DeleteFieldPictureMutation,
  DeleteFieldPictureMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';

const DELETE_FIELD_PICTURE = gql`
  mutation DeleteFieldPicture($id: Int!) {
    deleteFieldPicture(id: $id)
  }
`;

type Props = {
  fieldPictureId: number;
  refetchQueries: PureQueryOptions[];
};

export function DeleteFieldPicture({ fieldPictureId, refetchQueries }: Props) {
  const [deleteFieldPicture, { loading }] = useMutation<
    DeleteFieldPictureMutation,
    DeleteFieldPictureMutationVariables
  >(DELETE_FIELD_PICTURE, { refetchQueries });

  async function handleDelete() {
    try {
      await deleteFieldPicture({ variables: { id: fieldPictureId } });
      toast.success('Field picture deleted successfully.');
    } catch (e) {
      console.log('Error deleting field picture:', e);
      toast.error(
        'There was a problem deleting the field picture; it may have already been deleted. Please reload the page and try again.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="Are you sure you want to delete this field picture?"
    >
      {confirmDelete => (
        <Tooltip message="Delete field picture">
          <button
            type="button"
            className="btn btn-ghost btn-xs"
            onClick={confirmDelete}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </Tooltip>
      )}
    </Confirm>
  );
}
