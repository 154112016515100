import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateLiteratureReferenceMutation,
  CreateLiteratureReferenceMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import type { LiteratureFormValues } from '~/utils/modules/literatureReference';
import {
  defaultLiterature,
  toLiteratureReferenceInput,
  validationSchema,
} from '~/utils/modules/literatureReference';
import { LiteratureFormFields } from './LiteratureFormFields';

export const CREATE_LITERATURE_REFERENCE = gql`
  mutation CreateLiteratureReference(
    $studyId: Int!
    $literature: LiteratureReferenceInput!
  ) {
    createLiteratureReference(studyId: $studyId, literature: $literature) {
      id
    }
  }
`;

type Props = {
  studyId: number;
  refetchQueries: PureQueryOptions[];
};

export function CreateLiteratureForm({ studyId, refetchQueries }: Props) {
  const [createLiteratureReference, { loading, error }] = useMutation<
    CreateLiteratureReferenceMutation,
    CreateLiteratureReferenceMutationVariables
  >(CREATE_LITERATURE_REFERENCE, {
    refetchQueries,
  });

  async function handleSubmit(
    values: LiteratureFormValues,
    helpers: FormikHelpers<LiteratureFormValues>,
  ) {
    try {
      await createLiteratureReference({
        variables: {
          studyId,
          literature: toLiteratureReferenceInput(values),
        },
      });

      toast.success('Literature reference saved successfully.');
      helpers.resetForm();
    } catch (err) {
      console.log('Error creating literature reference', err);
      toast.error('There was a problem saving the literature reference.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultLiterature()}
      validationSchema={validationSchema}
    >
      <Form className="space-y-4">
        <LiteratureFormFields studyId={studyId} />
        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" size="sm" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
