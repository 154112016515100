import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faCloudUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type * as schema from '~/apollo/generated/v3/graphql';
import {
  CREATE_GEOREFERENCE,
  DELETE_GEOREFERENCE,
} from '~/apollo/operations/georeference';
import { Heading } from '~/components/common/Heading';
import { CreateGeoreference } from '~/components/upload/georeference/CreateGeoreference';
import { CurrentGeoreferences } from '~/components/upload/georeference/CurrentGeoreferences';
import { MapEditorContainer } from '~/components/upload/georeference/MapEditorContainer';
import type { GeoreferenceFormValues } from '~/utils/georeference';
import {
  defaultGeoreference,
  georeferenceValidationSchema,
  toGeoreferenceInput,
} from '~/utils/georeference';
import { ExportToKML } from './ExportToKML';
import { ImportKML } from './ImportKML';

export type DataTypeLimits = Partial<
  Record<schema.GeoreferenceDataType, number>
>;

type Props = {
  name?: string;
  parentType: schema.GeoreferenceParent;
  parentId: number;
  georeferences: schema.GeoreferencePartsFragment[];
  dataTypes: schema.GeoreferenceDataType[];
  limits?: DataTypeLimits;
  maxAllowed?: number;
  refetchQueries: PureQueryOptions[];
};

export function GeoreferenceManager({
  name,
  parentType,
  parentId,
  georeferences,
  dataTypes,
  limits = {},
  maxAllowed,
  refetchQueries,
}: Props) {
  const [showCreateForm, setShowCreateForm] = useState(false);

  const toggleShowCreateForm = () => setShowCreateForm(prevValue => !prevValue);

  const [createGeoreference, { loading: isCreating, error: createErrors }] =
    useMutation<
      schema.CreateGeoreferenceMutation,
      schema.CreateGeoreferenceMutationVariables
    >(CREATE_GEOREFERENCE, { refetchQueries });

  const [deleteGeoreference, { loading: isDeleting }] = useMutation(
    DELETE_GEOREFERENCE,
    { refetchQueries },
  );

  const parent = parentType.toUpperCase();

  async function handleSubmit(
    values: GeoreferenceFormValues,
    helpers: FormikHelpers<GeoreferenceFormValues>,
  ) {
    let variables: schema.CreateGeoreferenceMutationVariables = {
      parentType,
      parentId,
      georeference: toGeoreferenceInput(values),
    };

    try {
      await createGeoreference({ variables });

      toast.success('Georeference created');
      helpers.resetForm({ values: defaultGeoreference() });
    } catch (err) {
      console.log('Error creating georeference:', err);
      toast.error(
        'There was a problem creating the georeference. Please try again.',
      );
    }
  }

  async function handleDelete(georeferenceId: number) {
    try {
      await deleteGeoreference({
        variables: {
          parent,
          parentId,
          id: georeferenceId,
        },
      });

      toast.success('Georeference deleted.');
    } catch (err) {
      console.log('Error deleting georeference:', err);
      toast.error('There was a problem deleting the selected georeference.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultGeoreference()}
      validationSchema={georeferenceValidationSchema}
    >
      <Form>
        <div className="grid grid-cols-3 gap-6">
          <div className="space-y-2">
            <Heading level={3}>Georeferences</Heading>

            <div className="text-center space-x-1">
              <ImportKML
                parentType={parentType}
                parentId={parentId}
                currentGeoreferences={georeferences}
                limits={limits}
                refetchQueries={refetchQueries}
              >
                {showImportModal => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={showImportModal}
                    className="gap-1"
                  >
                    <FontAwesomeIcon icon={faCloudUpload} /> Import
                  </Button>
                )}
              </ImportKML>

              <Button
                type="button"
                color={showCreateForm ? 'ghost' : 'primary'}
                size="sm"
                onClick={toggleShowCreateForm}
                className="gap-1"
              >
                {showCreateForm && <>Cancel</>}
                {!showCreateForm && (
                  <>
                    <FontAwesomeIcon icon={faPlus} /> Create
                  </>
                )}
              </Button>
            </div>

            {showCreateForm && (
              <CreateGeoreference
                georeferences={georeferences}
                dataTypes={dataTypes}
                limits={limits}
                maxAllowed={maxAllowed}
                disabled={isCreating}
                errors={createErrors}
              />
            )}

            <CurrentGeoreferences
              georeferences={georeferences}
              onDelete={handleDelete}
              isDeleting={isDeleting}
            />

            <div className="text-center">
              {georeferences.length > 0 && (
                <ExportToKML name={name} georeferences={georeferences} />
              )}
            </div>
          </div>

          <div className="lg:col-span-2">
            <MapEditorContainer georeferences={georeferences} />
          </div>
        </div>
      </Form>
    </Formik>
  );
}
