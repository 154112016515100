import { gql } from '~/apollo/client-v3';
import { useMutation, type PureQueryOptions } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  DeleteReportMutation,
  DeleteReportMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';

const DELETE_REPORT = gql`
  mutation DeleteReport($reportId: Int!) {
    deleteReport(reportId: $reportId)
  }
`;

export function DeleteReport({
  children,
  reportId,
  refetchQueries,
}: {
  children: (showModal: () => void) => ReactNode;
  reportId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const { show, showModal, hideModal } = useModalState();

  const [deleteReport, { loading }] = useMutation<
    DeleteReportMutation,
    DeleteReportMutationVariables
  >(DELETE_REPORT, {
    variables: { reportId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteReport();
      toast.success('Report deleted successfully.');
      hideModal();
    } catch (err) {
      console.log('Error deleting report', err);
      toast.error(
        'There was a problem deleting the report. Please reload the page and try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Modal.Body heading="Delete Report">
          <div className="space-y-4">
            <p>
              <strong className="font-bold text-error">
                This report will be{' '}
                <span className="underline">
                  permanently deleted. It cannot be recovered.
                </span>
              </strong>
            </p>

            <p>
              Bookmarks and Data Bookmarks associated with the report{' '}
              <strong>will not be deleted</strong>.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            color="ghost"
            disabled={loading}
            onClick={hideModal}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleDelete}
            color="error"
            disabled={loading}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            Permanently Delete Report
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
