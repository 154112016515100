import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  AePartsFragment,
  AesTabQuery,
  DeleteMeasurementMutation,
  DeleteMeasurementMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { useSortFilter } from '~/hooks/data';
import { UpdateMeasurementModal } from './UpdateMeasurementModal';
import { snakeToHuman } from '~/utils/text';

export const DELETE_MEASUREMENT = gql`
  mutation DeleteMeasurement($id: Int!) {
    deleteArchitecturalMeasurement(id: $id)
  }
`;

type Props = {
  studyId: AePartsFragment['studyId'];
  measurements: AesTabQuery['studyList'][number]['architecturalElements'][number]['measurements'];
  refetchQueries: PureQueryOptions[];
};

export function MeasurementList({
  studyId,
  measurements,
  refetchQueries,
}: Props) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    measurements,
    'id',
    'id',
    'uploadMeasurementList',
  );

  const [deleteMeasurement, { loading }] = useMutation<
    DeleteMeasurementMutation,
    DeleteMeasurementMutationVariables
  >(DELETE_MEASUREMENT, { refetchQueries });

  const handleDelete = (measurementId: number) => async () => {
    try {
      await deleteMeasurement({ variables: { id: measurementId } });
      toast.success('Measurement deleted.');
    } catch (err) {
      console.log('Error deleting measurement:', err);
      toast.error(
        'There was a problem deleting the selected measurement. Please try again.',
      );
    }
  };

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="id" sortIndicatorProps={siProps}>
              ID
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="completeness" sortIndicatorProps={siProps}>
              Completeness
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="dataType" sortIndicatorProps={siProps}>
              Data Type
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="quality" sortIndicatorProps={siProps}>
              Data source confidence
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="value" sortIndicatorProps={siProps}>
              Value
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {items.map(am => (
          <tr key={am.id}>
            <td>{am.id}</td>
            <td>{snakeToHuman(am.completeness)}</td>
            <td>{snakeToHuman(am.dataType)}</td>
            <td>{snakeToHuman(am.quality)}</td>
            <td>{am.value}</td>
            <td className="text-right space-x-1">
              <UpdateMeasurementModal studyId={studyId} measurement={am}>
                {showModal => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={showModal}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                )}
              </UpdateMeasurementModal>

              <Confirm
                onConfirm={handleDelete(am.id)}
                text="The selected measurement will be permanently deleted."
              >
                {confirm => (
                  <Tooltip message="Delete measurement">
                    <Button
                      type="button"
                      onClick={confirm}
                      color="ghost"
                      size="xs"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Tooltip>
                )}
              </Confirm>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
