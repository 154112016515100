import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Outlet, useOutletContext, useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { outcropParts } from '~/apollo/fragments';
import type {
  UploadOutcropUpdatePageQuery,
  UploadOutcropUpdatePageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { outcropRoute } from '~/paths';
import UploadOutcropNavigation from './$outcropId/__UploadOutcropNavigation';

export const UPLOAD_OUTCROP_UPDATE_PAGE = gql`
  query UploadOutcropUpdatePage($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
    }
  }

  ${outcropParts}
`;

export type UploadOutcropUpdateRouteOutletContext = {
  outcrop: UploadOutcropUpdatePageQuery['outcropList'][number];
};

export default function UploadOutcropUpdatePage() {
  const params = useParams<{ outcropId: string }>();
  invariant(params.outcropId, 'outcropId param required');
  const outcropId = parseInt(params.outcropId);

  const { data, loading } = useQuery<
    UploadOutcropUpdatePageQuery,
    UploadOutcropUpdatePageQueryVariables
  >(UPLOAD_OUTCROP_UPDATE_PAGE, {
    variables: { id: outcropId },
  });

  const outcropList = data?.outcropList ?? [];
  const outcrop = outcropList.find(oc => oc.id === outcropId);

  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId',
    outcrop?.name ?? 'Update Outcrop',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  const outletContext: UploadOutcropUpdateRouteOutletContext = { outcrop };

  return (
    <>
      <Link
        to={outcropRoute(outcropId)}
        className="btn btn-primary btn-sm float-right gap-1"
        target="_blank"
      >
        View Outcrop <FontAwesomeIcon icon={faExternalLink} />
      </Link>

      <PageHeading hasSubtitle>Update Outcrop</PageHeading>
      <PageHeading.Subtitle>{outcrop.name}</PageHeading.Subtitle>

      <div className="grid grid-cols-12 gap-6 mt-4">
        <div className="col-span-12 lg:col-span-2">
          <UploadOutcropNavigation outcropId={outcropId} />
        </div>

        <div className="col-span-12 lg:col-span-10">
          <Outlet context={outletContext} />
        </div>
      </div>
    </>
  );
}

export function useUploadOutcropUpdateRouteOutletContext() {
  return useOutletContext<UploadOutcropUpdateRouteOutletContext>();
}
