import type { PureQueryOptions } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-daisyui';
import type {
  FilePartsFragment,
  PicturePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { DeleteVomPicture } from './DeleteVomPicture';
import { UpdateVomPicture } from './UpdateVomPicture';
import { VomPictureDetails } from './VomPictureDetails';

type PictureProps = PicturePartsFragment & {
  file: FilePartsFragment;
};

type Props = {
  vomId: number;
  picture: PictureProps;
  refetchQueries: PureQueryOptions[];
};

export function VomPictureManager({ vomId, picture, refetchQueries }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => setIsEditing(prevValue => !prevValue);

  const handleUpdateSuccess = () => setIsEditing(false);

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>{picture.name}</Panel.Title>
        <Button
          type="button"
          color={isEditing ? 'ghost' : 'primary'}
          size="xs"
          onClick={toggleEdit}
          className="gap-1"
        >
          {isEditing && 'Cancel'}
          {!isEditing && (
            <>
              <FontAwesomeIcon icon={faPencil} /> Edit
            </>
          )}
        </Button>
      </Panel.Heading>

      <Panel.Body>
        <div className="grid lg:grid-cols-3 gap-6">
          <div>
            <div className="thumbnail">
              <img src={picture.file.signedUrl} alt="VOM Picture" />
            </div>
          </div>

          <div className="lg:col-span-2 space-y-4">
            {!isEditing && <VomPictureDetails picture={picture} />}
            {isEditing && (
              <>
                <UpdateVomPicture
                  picture={picture}
                  onUpdateSuccess={handleUpdateSuccess}
                />
                <div className="text-center">
                  <DeleteVomPicture
                    vomId={vomId}
                    pictureId={picture.id}
                    refetchQueries={refetchQueries}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
}
