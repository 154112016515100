import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { Badge, Checkbox } from 'react-daisyui';
import { Link } from 'react-router';
import type { DataSearchOptionsQuery } from '~/apollo/generated/v3/graphql';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { useSortFilter } from '~/hooks/data';
import { outcropMeasurementsTabRoute, studyMeasurementsRoute } from '~/paths';
import { cn, filterUnique, rejectNil } from '~/utils/common';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';
import { isGlobalRegion } from '~/utils/modules/region';
import { truncate } from '~/utils/text';

type Outcrop = DataSearchOptionsQuery['dataSearchOptions']['outcrops'][number];
type Study = DataSearchOptionsQuery['dataSearchOptions']['studies'][number];

function filterStudiesByOutcrops(outcropIds: number[], studies: Study[]) {
  return studies
    .filter(s => {
      const studyOCIds = new Set(s.outcrops.map(oc => oc.id));
      return new Set(outcropIds).intersection(studyOCIds).size > 0;
    })
    .map(study => study.id);
}

type Props = {
  outcrops: Outcrop[];
  studies: Study[];
  disabled: boolean;
};

export function OutcropFilters(props: Props) {
  const { values, setFieldValue } = useFormikContext<DataSearchFormValues>();

  const { items: outcrops, sortIndicatorProps: siProps } = useSortFilter(
    props.outcrops,
    'name',
    'name',
    'dataSearchOutcropList',
  );

  const availableOutcropIds = useMemo(
    () => props.outcrops.map(oc => oc.id),
    [props.outcrops],
  );
  const selectedOutcropIds = useMemo(
    () => values.outcropIds,
    [values.outcropIds],
  );

  // We only classify a study ID as "available" if it is currently visible
  // (i.e. its parent Outcrop is checked)
  const availableStudyIds = useMemo(
    () => filterStudiesByOutcrops(selectedOutcropIds, props.studies),
    [props.studies, selectedOutcropIds],
  );
  const selectedStudyIds = useMemo(() => values.studyIds, [values.studyIds]);

  const setOutcropIdsValue = useCallback(
    (value: number[]) => setFieldValue('outcropIds', value),
    [setFieldValue],
  );

  const setStudyIdsValue = useCallback(
    (value: number[]) => setFieldValue('studyIds', value),
    [setFieldValue],
  );

  useEffect(() => {
    // When available outcrops are updated,
    // automatically uncheck ones that are no longer in the list
    const nextSelectedOutcrops = selectedOutcropIds.filter(id =>
      availableOutcropIds.includes(id),
    );

    if (selectedOutcropIds.length !== nextSelectedOutcrops.length) {
      setOutcropIdsValue(nextSelectedOutcrops);
    }
  }, [availableOutcropIds, selectedOutcropIds, setOutcropIdsValue]);

  const isAllOutcropsSelected = useMemo(() => {
    if (!availableOutcropIds.length || !selectedOutcropIds.length) return false;
    const available = new Set(availableOutcropIds);
    const selected = new Set(selectedOutcropIds);
    return available.symmetricDifference(selected).size === 0;
  }, [availableOutcropIds, selectedOutcropIds]);

  const isAllStudiesSelected = useMemo(() => {
    if (!availableStudyIds.length || !selectedStudyIds.length) return false;
    const available = new Set(availableStudyIds);
    const selected = new Set(selectedStudyIds);
    return available.symmetricDifference(selected).size === 0;
  }, [availableStudyIds, selectedStudyIds]);

  function toggleSelectAllOutcrops() {
    if (isAllOutcropsSelected) {
      setOutcropIdsValue([]);
      setStudyIdsValue([]);
    } else {
      setOutcropIdsValue(availableOutcropIds);
    }
  }

  function toggleSelectAllStudies() {
    if (isAllStudiesSelected) {
      setStudyIdsValue([]);
    } else {
      setStudyIdsValue(availableStudyIds);
    }
  }

  function isOutcropSelected(outcropId: number) {
    return selectedOutcropIds.includes(outcropId);
  }

  function isStudySelected(studyId: number) {
    return selectedStudyIds.includes(studyId);
  }

  function outcropStudies(outcropId: number) {
    return props.studies
      .filter(s => s.outcrops.map(soc => soc.id).includes(outcropId))
      .sort(R.ascend(s => s.name));
  }

  function handleOutcropSelected(outcropId: number) {
    if (selectedOutcropIds.includes(outcropId)) {
      const nextOCIds = R.without([outcropId], selectedOutcropIds);
      setOutcropIdsValue(nextOCIds);

      // Remove any studies under this OC if it's no longer visible in any OC
      const nextAvailStudyIds = filterStudiesByOutcrops(
        nextOCIds,
        props.studies,
      );
      const nextStudyIds = values.studyIds.filter(studyId =>
        nextAvailStudyIds.includes(studyId),
      );
      setStudyIdsValue(nextStudyIds);
    } else {
      setOutcropIdsValue(R.append(outcropId, selectedOutcropIds));
    }
  }

  function handleStudySelected(studyId: number, outcropId: number) {
    if (selectedStudyIds.includes(studyId)) {
      const nextStudyIds = selectedStudyIds.filter(sid => sid !== studyId);
      setStudyIdsValue(nextStudyIds);
    } else {
      const nextStudyIds = [...selectedStudyIds, studyId];
      setStudyIdsValue(nextStudyIds);

      // Also check the study's outcrop if it isn't already
      const nextOutcropIds = selectedOutcropIds
        .concat([outcropId])
        .filter(filterUnique);
      setOutcropIdsValue(nextOutcropIds);
    }
  }

  const unassignedOutcrops = outcrops.filter(oc => isGlobalRegion(oc.region));
  const restOutcrops = outcrops.filter(oc => !isGlobalRegion(oc.region));

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-end gap-2">
        <div className="shrink-0 -space-y-2">
          <label className="label justify-start items-start gap-2 cursor-pointer">
            <Checkbox
              onChange={toggleSelectAllOutcrops}
              checked={isAllOutcropsSelected}
              size="sm"
              disabled={props.disabled}
            />
            <span className="label-text">
              Select all Analogues&nbsp;
              <Badge className="font-mono text-xs" color="ghost">
                {selectedOutcropIds.length}
                <span className="mx-0.5 text-slate-400">/</span>
                {availableOutcropIds.length}
              </Badge>
            </span>
          </label>
          <div className="ml-3 pl-3 border-slate-200 border-dotted relative">
            <div className="absolute top-1 left-0 border-b-2 border-l-2 border-slate-200 border-dotted w-3 h-4" />
            <label className="label justify-start items-start gap-2 cursor-pointer">
              <Checkbox
                onChange={toggleSelectAllStudies}
                checked={isAllStudiesSelected}
                size="sm"
                disabled={props.disabled}
              />
              <span className="label-text">
                Select all Studies{' '}
                <Badge className="font-mono text-xs" color="ghost">
                  {selectedStudyIds.length}
                  <span className="mx-0.5 text-slate-400">/</span>
                  {availableStudyIds.length}
                </Badge>
              </span>
            </label>
          </div>
        </div>
        <div className="shrink">
          <SortTrigger colName="name" sortIndicatorProps={siProps}>
            Sort
          </SortTrigger>
        </div>
      </div>

      <div className="space-y-1">
        {unassignedOutcrops.map(outcrop => (
          <OutcropItem
            key={outcrop.id}
            outcrop={outcrop}
            studies={outcropStudies(outcrop.id)}
            onOutcropSelect={handleOutcropSelected}
            onStudySelect={handleStudySelected}
            isOutcropSelected={isOutcropSelected(outcrop.id)}
            isStudySelected={isStudySelected}
            disabled={props.disabled}
          />
        ))}
        {restOutcrops.map(outcrop => (
          <OutcropItem
            key={outcrop.id}
            outcrop={outcrop}
            studies={outcropStudies(outcrop.id)}
            onOutcropSelect={handleOutcropSelected}
            onStudySelect={handleStudySelected}
            isOutcropSelected={isOutcropSelected(outcrop.id)}
            isStudySelected={isStudySelected}
            disabled={props.disabled}
          />
        ))}
      </div>
    </div>
  );
}

function OutcropItem({
  outcrop,
  studies,
  isOutcropSelected,
  isStudySelected,
  onOutcropSelect,
  onStudySelect,
  disabled,
}: {
  outcrop: Outcrop;
  studies: Study[];
  isOutcropSelected: boolean;
  isStudySelected: (studyId: number) => boolean;
  onOutcropSelect: (outcropId: number) => void;
  onStudySelect: (studyId: number, outcropId: number) => void;
  disabled: boolean;
}) {
  return (
    <div key={outcrop.id} className="border border-slate-100 p-1">
      <div className="flex justify-between items-start gap-2">
        <label className="label justify-start items-start gap-2">
          <Checkbox
            onChange={() => onOutcropSelect(outcrop.id)}
            checked={isOutcropSelected}
            disabled={disabled}
            size="sm"
          />
          <span className="label-text">
            {outcrop.name}
            <Link
              to={outcropMeasurementsTabRoute(outcrop.id)}
              target="_blank"
              className="ml-2 text-sm text-slate-300 hover:link-primary"
            >
              <FontAwesomeIcon icon={faExternalLink} />
            </Link>
          </span>
        </label>

        <div className="text-sm text-muted mr-2 mt-2">
          {outcrop.region.location.country}
        </div>
      </div>

      {isOutcropSelected && studies.length > 0 && (
        <div className="space-y-0">
          {studies.map((study, studyIndex, studiesAr) => (
            <div
              key={`${outcrop.id}-${study.id}`}
              className={cn(
                'ml-3 pl-3 border-slate-200 border-dotted relative',
                { 'border-l-2': studyIndex < studiesAr.length - 1 },
              )}
            >
              <div
                className={cn(
                  'absolute top-0 left-0 border-b-2 border-slate-200 border-dotted w-3 h-4',
                  { 'border-l-2': studyIndex === studiesAr.length - 1 },
                )}
              />

              <label className="py-1 label justify-start items-start gap-2">
                <Checkbox
                  onChange={() => onStudySelect(study.id, outcrop.id)}
                  checked={isStudySelected(study.id)}
                  disabled={disabled}
                  size="sm"
                />
                <span className="label-text">
                  <div>
                    {[study.dataHistory?.date, study.dataHistory?.collectedBy]
                      .filter(rejectNil)
                      .join(' - ')}
                    <Link
                      to={studyMeasurementsRoute(study.id)}
                      target="_blank"
                      className="ml-2 text-sm text-slate-300 hover:link-primary"
                    >
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </div>
                  <div className="italic text-muted">
                    <Tooltip
                      disabled={study.name.length <= 70}
                      message={study.name}
                      className="text-left"
                    >
                      {truncate(70)(study.name)}
                    </Tooltip>
                  </div>
                </span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
