import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import {
  ReportItemType,
  type ReportPageQuery,
  type ReportPageQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { ListGroup } from '~/components/common/ListGroup';
import { LocalDate } from '~/components/common/LocalDate';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { savedDataSearchWithOutcropParts } from '~/components/dataSearch/savedDataSearch/SavedDataSearch';
import { outcropPaleogeographyParts } from '~/components/outcrop/PaleomapsTab';
import { ReportItem } from '~/components/report/ReportDisplay/ReportItem';
import {
  prettyTargetType,
  targetName,
  toStandardizedBookmark,
} from '~/utils/modules/bookmark';

const reportOutcropParts = gql`
  fragment reportOutcropParts on Outcrop {
    ...outcropParts
    ...outcropPaleogeographyParts
    region {
      id
      name
      location {
        id
        country
      }
    }
    thumbnail {
      id
      signedUrl
    }
    outline {
      ...georeferenceParts
    }
    keyParameters {
      ...keyParametersParts
    }
  }

  ${fragments.outcropParts}
  ${outcropPaleogeographyParts}
  ${fragments.georeferenceParts}
  ${fragments.keyParametersParts}
  ${fragments.outcropParts}
  ${fragments.basinParts}
  ${fragments.sourceAreaParts}
  ${fragments.paleoMapParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.lithostratAgeParts}
`;

const reportStudyParts = gql`
  fragment reportStudyParts on Study {
    id
    name
    abstract
    keyReferences
    dataHistory {
      id
      collectedBy
      date
    }
  }
`;

const reportRegionParts = gql`
  fragment reportRegionParts on Region {
    id
    name
    location {
      id
      country
    }
    description
  }
`;

const reportVomParts = gql`
  fragment reportVomParts on VirtualOutcropModel {
    id
    name
    acquisitionType
    interpreted
    pictures {
      id
      priority
      file {
        id
        signedUrl
      }
    }
  }
`;

const reportGigaPanParts = gql`
  fragment reportGigaPanParts on GigaPan {
    id
    gigaPanHash
    pictures {
      id
      priority
      author
      description
      file {
        id
        signedUrl
      }
    }
  }
`;

const REPORT_PAGE = gql`
  query ReportPage($reportId: Int!) {
    reportList(reportId: $reportId) {
      ...reportParts
      user {
        ...publicUserParts
      }
      company {
        ...publicCompanyParts
      }
      items {
        ...reportItemParts
        bookmark {
          ...bookmarkParts
          company {
            ...companyParts
          }
          parent {
            ...bookmarkParentParts
          }
          target {
            ...bookmarkTargetParts
          }
          targetOutcrop: target {
            ... on Outcrop {
              ...reportOutcropParts
            }
          }
          targetRegion: target {
            ... on Region {
              ...reportRegionParts
            }
          }
          targetStudy: target {
            ... on Study {
              ...reportStudyParts
            }
          }
          targetVirtualOutcropModel: target {
            ... on VirtualOutcropModel {
              ...reportVomParts
            }
          }
          targetPicture: target {
            ... on Picture {
              ...pictureParts
              file {
                ...fileParts
                signedUrl
              }
            }
          }
          targetGigaPan: target {
            ... on GigaPan {
              ...reportGigaPanParts
            }
          }
          targetDepositionalWiki: target {
            ... on DepositionalWiki {
              ...depositionalWikiParts
              vomExample {
                ...vomParts
              }
            }
          }
          targetOtherWiki: target {
            ... on OtherWiki {
              ...otherWikiParts
            }
          }
          targetLithostratFormation: target {
            ... on LithostratFormation {
              ...lithostratFormationParts
              wikiPage {
                ...lithostratWikiPageParts
                keyParameters {
                  ...keyParametersParts
                }
              }
            }
          }
          targetLithostratGroup: target {
            ... on LithostratGroup {
              ...lithostratGroupParts
              wikiPage {
                ...lithostratWikiPageParts
                keyParameters {
                  ...keyParametersParts
                }
              }
            }
          }
          targetLithostratMember: target {
            ... on LithostratMember {
              ...lithostratMemberParts
              wikiPage {
                ...lithostratWikiPageParts
                keyParameters {
                  ...keyParametersParts
                }
              }
            }
          }
          targetSavedDataSearch: target {
            ... on SavedDataSearch {
              ...savedDataSearchWithOutcropParts
              data {
                ...savedDataSearchDataParts
              }
            }
          }
          user {
            ...publicUserParts
          }
          collections {
            ...bookmarkCollectionParts
          }
        }
      }
    }
  }

  ${fragments.reportParts}
  ${fragments.reportItemParts}

  ${fragments.companyParts}
  ${fragments.publicCompanyParts}
  ${fragments.bookmarkParts}
  ${fragments.bookmarkCollectionParts}
  ${fragments.bookmarkParentParts}
  ${fragments.bookmarkTargetParts}
  ${fragments.pictureParts}
  ${fragments.fileParts}
  ${fragments.publicUserParts}
  ${fragments.vomParts}

  ${fragments.depositionalWikiParts}
  ${fragments.otherWikiParts}
  ${fragments.lithostratFormationParts}
  ${fragments.lithostratGroupParts}
  ${fragments.lithostratMemberParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.keyParametersParts}

  ${savedDataSearchWithOutcropParts}
  ${fragments.savedDataSearchDataParts}

  ${reportOutcropParts}
  ${reportStudyParts}
  ${reportRegionParts}
  ${reportVomParts}
  ${reportGigaPanParts}
`;

export default function BookmarkCollectionReportRoute() {
  const params = useParams<{ reportId: string }>();
  invariant(params.reportId);
  const reportId = parseInt(params.reportId);

  const { data, loading } = useQuery<ReportPageQuery, ReportPageQueryVariables>(
    REPORT_PAGE,
    { variables: { reportId } },
  );

  const handlePrint = () => {
    window.print();
  };

  const report = data?.reportList.find(r => r.id === reportId);
  const items = R.sortBy(item => item.order ?? Infinity, report?.items ?? []);

  function itemTitle(item: (typeof items)[number]) {
    if (item.itemType === ReportItemType.Bookmark) {
      return item.bookmark ? targetName(item.bookmark) : 'Bookmark';
    } else {
      return item.title ?? 'Report Item';
    }
  }

  function itemSubtitle(item: (typeof items)[number]) {
    if (!item.bookmark) return null;

    const bookmark = toStandardizedBookmark(item.bookmark);
    if (bookmark.parentName === bookmark.targetName) {
      return null;
    }

    return <div className="text-sm text-slate-700">{bookmark.parentName}</div>;
  }

  function itemLabel(item: (typeof items)[number]) {
    if (item.itemType === ReportItemType.Bookmark) {
      invariant(item.bookmark, "Can't generate a label for unloaded bookmark.");
      const bookmark = toStandardizedBookmark(item.bookmark);
      return prettyTargetType(bookmark.targetType, bookmark.parentType);
    }
    return null;
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!report) return <NotFound />;

  return (
    <div className="p-4 mx-auto">
      <div className="grid lg:grid-cols-4 gap-6">
        <div className="print:hidden">
          <div className="sm:sticky top-0 max-h-screen overflow-auto pt-4">
            <div className="flex justify-between items-baseline">
              <Heading level={3} className="mt-1">
                {report.title}
              </Heading>
              <div>
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={handlePrint}
                >
                  <FontAwesomeIcon icon={faPrint} />
                </Button>
              </div>
            </div>

            <ListGroup>
              {items.map(item => (
                <ListGroup.Item
                  as="a"
                  key={item.id}
                  href={`#${item.itemType}--${item.id}`}
                  className="flex justify-between p-2"
                >
                  <div>
                    <div className="font-bold text-base text-slate-900">
                      {itemTitle(item)}
                    </div>
                    {itemSubtitle(item)}
                  </div>
                  <div className="text-sm text-slate-500">
                    {itemLabel(item)}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>

        <div className="col-span-4 lg:col-span-3">
          {/* Cover sheet */}
          <div className="print:h-screen py-12 w-full flex justify-center items-center">
            <div className="text-center space-y-4">
              <PageHeading>{report.title}</PageHeading>
              {report.description && (
                <p className="max-w-xl">{report.description}</p>
              )}

              <div>
                {report.completedAt ? (
                  <div>
                    Completed on <LocalDate date={report.completedAt} />
                  </div>
                ) : (
                  <div>
                    Generated on <LocalDate date={report.updatedAt} />
                  </div>
                )}
                <div>by {report.user.name}</div>
              </div>

              <Heading level={5}>
                Based on data from the SAFARI database
                <div>
                  <a href="https://safaridb.com" className="link">
                    SafariDB.com
                  </a>
                </div>
              </Heading>
            </div>
          </div>

          <div className="space-y-4">
            {items.map(item => (
              <div
                key={item.id}
                id={`${item.itemType}--${item.id}`}
                className="m-4 print:break-after-page"
              >
                <ReportItem item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
