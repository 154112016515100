import { useMutation, type PureQueryOptions } from '@apollo/client';
import { faCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { graphql } from '~/apollo/generated/v4';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import type { TilesetParams } from '~/components/cesium/cesiumUtils';
import { TerrainProvider } from '~/components/cesium/cesiumUtils';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';

export function QualityAssuranceState({
  vomId,
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const ctx = useUpdateVomOutletContext();
  const cesiumAsset = ctx.vom.cesiumAsset;
  const [ca, setCa] = useState<TilesetParams | null>(null);

  useEffect(() => {
    if (cesiumAsset && cesiumAsset.assetToken && cesiumAsset.localPath) {
      const tilesetAsset: TilesetParams = {
        assetToken: cesiumAsset.assetToken,
        localPath: cesiumAsset.localPath,
        transform: { local: true },
      };
      setCa(tilesetAsset);
    }
  }, [cesiumAsset]);

  return (
    <div className="space-y-4">
      {ca && (
        <CesiumViewer
          initialTilesets={[ca]}
          terrainProvider={TerrainProvider.None}
        />
      )}

      <div className="text-center space-x-2">
        <ResetButton
          cesiumAssetId={cesiumAssetId}
          refetchQueries={refetchQueries}
        />
        <ApproveButton
          cesiumAssetId={cesiumAssetId}
          refetchQueries={refetchQueries}
        />
      </div>
    </div>
  );
}

const approveQualityMutation = graphql(`
  mutation ApproveCesiumAssetQuality($id: ID!) {
    cesiumAssetApproveQuality(id: $id) {
      result {
        id
      }
    }
  }
`);

function ApproveButton({
  cesiumAssetId,
  refetchQueries,
}: {
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [approveQuality, { loading }] = useMutation(approveQualityMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  async function handleApprove() {
    const confirmMsg = 'Are you sure you want to approve the asset?';
    if (!window.confirm(confirmMsg)) return;
    await approveQuality();
  }

  return (
    <Button
      type="button"
      onClick={handleApprove}
      color="success"
      startIcon={<FontAwesomeIcon icon={faCheck} />}
      loading={loading}
      disabled={loading}
    >
      Approve
    </Button>
  );
}

const resetToInitializedMutation = graphql(`
  mutation ResetCesiumAssetToInitialized($id: ID!) {
    cesiumAssetResetToInitialized(id: $id) {
      result {
        id
      }
    }
  }
`);

function ResetButton({
  cesiumAssetId,
  refetchQueries,
}: {
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [resetToInitialized, { loading }] = useMutation(
    resetToInitializedMutation,
    {
      variables: { id: cesiumAssetId },
      refetchQueries,
    },
  );

  async function handleReset() {
    const confirmMsg = 'Are you sure you want to reset the asset?';
    if (!window.confirm(confirmMsg)) return;

    await resetToInitialized();
  }

  return (
    <Button
      type="button"
      onClick={handleReset}
      color="ghost"
      startIcon={<FontAwesomeIcon icon={faRefresh} />}
      loading={loading}
      disabled={loading}
    >
      Reset
    </Button>
  );
}
