import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  DepositionalWikiPartsFragment,
  UpdateDepositionalWikiMutation,
  UpdateDepositionalWikiMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Panel } from '~/components/common/Panel';
import type { DepositionalWikiFormValues } from '~/utils/modules/depositionalWiki';
import {
  defaultWikiPage,
  toDepositionalWikiInput,
  validationSchema,
} from '~/utils/modules/depositionalWiki';
import { DepositionalWikiFormFields } from './DepositionalWikiFormFields';

export const UPDATE_DEPOSITIONAL_WIKI = gql`
  mutation UpdateDepositionalWiki(
    $id: Int!
    $depositional: DepositionalWikiInput!
  ) {
    updateDepositionalWiki(depositional: $depositional, id: $id) {
      ...depositionalWikiParts
    }
  }

  ${fragments.depositionalWikiParts}
`;

type Props = {
  wikiPage: DepositionalWikiPartsFragment;
  refetchQueries?: PureQueryOptions[];
};

export function UpdateDepositionalWikiForm({
  wikiPage,
  refetchQueries,
}: Props) {
  const [updateWikiPage, { loading, error }] = useMutation<
    UpdateDepositionalWikiMutation,
    UpdateDepositionalWikiMutationVariables
  >(UPDATE_DEPOSITIONAL_WIKI, { refetchQueries });

  async function handleSubmit(
    values: DepositionalWikiFormValues,
    helpers: FormikHelpers<DepositionalWikiFormValues>,
  ) {
    try {
      await updateWikiPage({
        variables: {
          id: wikiPage.id,
          depositional: toDepositionalWikiInput(values),
        },
      });
      toast.success('Wiki page saved successfully.');
    } catch (err) {
      console.log('Error saving wiki page', err);
      toast.error('There was a problem saving the wiki page.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultWikiPage(wikiPage)}
      validationSchema={validationSchema}
    >
      <Form>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Wiki Page Details</Panel.Title>
          </Panel.Heading>
          <Panel.Body className="space-y-4">
            <DepositionalWikiFormFields />
            <FormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer className="text-right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </Form>
    </Formik>
  );
}
