import type { PureQueryOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import * as R from 'ramda';

import * as fragments from '~/apollo/fragments';
import type {
  FormationUpdateExamplesRouteQuery,
  FormationUpdateExamplesRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { LithostratLinkTargetType } from '~/apollo/generated/v3/graphql';
import { useUpdateFormationOutletContext } from '~/routes/upload/lithostrat/formation/$formationId';

import { Alert } from 'react-daisyui';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ExampleManager } from '~/components/upload/lithostrat/examples/ExampleManager';

const EXAMPLES_TAB = gql`
  query FormationUpdateExamplesRoute($id: Int!) {
    lithostratFormationList(id: $id) {
      ...lithostratFormationParts
      wikiPage {
        ...lithostratWikiPageParts
      }
      outcropLinkAnalogues {
        ...outcropParts
      }
      studyLinkAnalogues {
        ...outcropParts
        studies {
          ...studyParts
          lithostratStudyLinks {
            ...lithostratStudyLinkParts
          }
        }
      }
    }
  }

  ${fragments.lithostratFormationParts}
  ${fragments.lithostratWikiPageParts}
  ${fragments.outcropParts}
  ${fragments.studyParts}
  ${fragments.lithostratStudyLinkParts}
`;

export default function FormationUpdateExamplesRoute() {
  const ctx = useUpdateFormationOutletContext();
  const formationId = ctx.formation.id;

  const { data, loading } = useQuery<
    FormationUpdateExamplesRouteQuery,
    FormationUpdateExamplesRouteQueryVariables
  >(EXAMPLES_TAB, {
    variables: { id: formationId },
  });

  const refetchQueries: [
    PureQueryOptions<FormationUpdateExamplesRouteQueryVariables>,
  ] = [
    {
      query: EXAMPLES_TAB,
      variables: { id: formationId },
    },
  ];

  const formations = data?.lithostratFormationList ?? [];
  const formation = formations.find(f => f.id === formationId);

  const outcropLinks = R.sortBy(
    R.prop('name'),
    formation?.outcropLinkAnalogues ?? [],
  );
  const studyLinks = R.sortBy(
    R.prop('name'),
    formation?.studyLinkAnalogues ?? [],
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!formation) return <NotFound />;

  if (!formation.wikiPage?.published) {
    return (
      <Alert status="info">
        This formation's wiki page needs to be published before examples may be
        added.
      </Alert>
    );
  }

  return (
    <ExampleManager
      targetType={LithostratLinkTargetType.Formation}
      targetId={formationId}
      refetchQueries={refetchQueries}
      outcropLinks={outcropLinks}
      studyLinks={studyLinks}
    />
  );
}
