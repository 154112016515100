import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { bookmarkCollectionParts } from '~/apollo/fragments';
import type {
  BookmarkCollectionPartsFragment,
  UpdateBookmarkCollectionMutation,
  UpdateBookmarkCollectionMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { BookmarkCollectionFormValues } from '~/utils/modules/bookmark';
import {
  bookmarkCollectionValidationSchema,
  initialBookmarkCollection,
  toBookmarkCollectionInput,
} from '~/utils/modules/bookmark';
import { BookmarkCollectionFormFields } from './BookmarkCollectionFormFields';

const UPDATE_COLLECTION = gql`
  mutation UpdateBookmarkCollection(
    $collectionId: Int!
    $collection: BookmarkCollectionInput!
  ) {
    updateBookmarkCollection(
      collectionId: $collectionId
      collection: $collection
    ) {
      ...bookmarkCollectionParts
    }
  }

  ${bookmarkCollectionParts}
`;

type Props = {
  children: (showModal: () => void) => JSX.Element;
  collection: Pick<
    BookmarkCollectionPartsFragment,
    'id' | 'name' | 'description'
  >;
};

export function UpdateCollectionModal({ children, collection }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [updateCollection, { loading, error }] = useMutation<
    UpdateBookmarkCollectionMutation,
    UpdateBookmarkCollectionMutationVariables
  >(UPDATE_COLLECTION, {});

  async function handleSubmit(values: BookmarkCollectionFormValues) {
    const variables = {
      collectionId: collection.id,
      collection: toBookmarkCollectionInput(values),
    };

    try {
      await updateCollection({ variables });
      toast.success('Collection updated successfully.');
      hideModal();
    } catch (err) {
      console.log('Error updating collection', err);
      toast.error(
        'There was a problem saving the collection. Please try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialBookmarkCollection(collection)}
          validationSchema={bookmarkCollectionValidationSchema}
        >
          <Form>
            <Modal.Body heading="Edit Collection">
              <div className="space-y-4">
                <BookmarkCollectionFormFields disabled={loading} />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
