import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import * as fragments from '~/apollo/fragments';
import type { ExportVomGeoreferencesQuery } from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { saveAs } from '~/utils/export';
import { createKmlDocument, createKmlFolder } from '~/utils/kml';

const EXPORT_VOM_GEOREFERENCES = gql`
  query ExportVomGeoreferences {
    virtualOutcropModelList {
      ...vomParts
      georeference {
        ...georeferenceParts
      }
    }
  }

  ${fragments.vomParts}
  ${fragments.georeferenceParts}
`;

export default function ExportGeoreferencesAllVomsRoute() {
  const { data, loading, error } = useQuery<ExportVomGeoreferencesQuery>(
    EXPORT_VOM_GEOREFERENCES,
    {},
  );

  const vomList = data?.virtualOutcropModelList ?? [];

  async function handleClick() {
    const vomFolders = vomList
      .map(vom => {
        if (!vom.georeference?.length) return null;
        return createKmlFolder(
          vom.name,
          `${vom.name} Georeferences`,
          vom.georeference,
        );
      })
      .filter(folder => !!folder);

    const document = await createKmlDocument(vomFolders);
    saveAs(
      document,
      'SafariDB Virtual Outcrop Models.kml',
      'application/vnd;charset=utf-8',
    );
  }

  if (error) {
    return (
      <p>An error occurred while loading the VOM list. Please try again.</p>
    );
  }

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading data for all VOMs, this may take a long time...
      </SpinnerPlaceholder>
    );
  }

  return (
    <div className="text-center">
      {data && (
        <Button
          type="button"
          color="primary"
          className="gap-1"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faCloudDownload} /> Save KML
        </Button>
      )}
    </div>
  );
}
