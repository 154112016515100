import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useReducer } from 'react';
import { Button } from 'react-daisyui';
import type { FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { cn } from '~/utils/common';

type Props = {
  /** Callback function fired after all files have been attempted to be uploaded */
  onAllSettled?: () => any;
};

type QueueItemStatus = 'pending' | 'uploading' | 'success' | 'failed';
type QueueItem = {
  tempId: string;
  file: File;
  isPublic: boolean;
  status: QueueItemStatus;
  error?: string | null;
};

type UploadQueueState = {
  status: 'idle' | 'active';
  items: QueueItem[];
};

type UploadQueueAction =
  | { type: 'ADD_ITEM'; payload: QueueItem }
  | { type: 'REMOVE_ITEM'; payload: string }
  | { type: 'SET_ITEM_PUBLIC'; payload: { tempId: string; isPublic: boolean } }
  | { type: 'QUEUE_STARTED' }
  | { type: 'QUEUE_DONE' }
  | { type: 'ITEM_STARTED'; payload: string }
  | { type: 'ITEM_SUCCESS'; payload: string }
  | { type: 'ITEM_FAILED'; payload: { tempId: string; error: string } }
  | { type: 'CLEAR_QUEUE' };

function uploadQueueReducer(
  state: UploadQueueState,
  action: UploadQueueAction,
): UploadQueueState {
  console.log('Action dispatched:', action);

  const findItemIndex = (tempId: string) => {
    const index = state.items.findIndex(item => item.tempId === tempId);
    if (index === -1) throw new Error('Could not find item');
    return index;
  };

  const findItem = (tempId: string) => {
    const item = state.items.find(item => item.tempId === tempId);
    if (!item) throw new Error(`Could not find item ${tempId}`);
    return item;
  };

  if (action.type === 'ADD_ITEM') {
    return { ...state, items: R.append(action.payload, state.items) };
  }

  if (action.type === 'REMOVE_ITEM') {
    return {
      ...state,
      items: state.items.filter(item => item.tempId !== action.payload),
    };
  }

  if (action.type === 'SET_ITEM_PUBLIC') {
    const { tempId, isPublic } = action.payload;
    const nextItems = state.items.map(item => {
      if (item.tempId === tempId) return { ...item, isPublic };
      return item;
    });
    return { ...state, items: nextItems };
  }

  if (action.type === 'QUEUE_STARTED') {
    return { ...state, status: 'active' };
  }
  if (action.type === 'QUEUE_DONE') {
    return { ...state, status: 'idle' };
  }

  if (action.type === 'ITEM_STARTED') {
    const index = findItemIndex(action.payload);
    const item: QueueItem = {
      ...findItem(action.payload),
      status: 'uploading',
    };
    return { ...state, items: R.update(index, item, state.items) };
  }
  if (action.type === 'ITEM_SUCCESS') {
    const index = findItemIndex(action.payload);
    const item: QueueItem = { ...findItem(action.payload), status: 'success' };
    return { ...state, items: R.update(index, item, state.items) };
  }
  if (action.type === 'ITEM_FAILED') {
    const { tempId, error } = action.payload;
    const index = findItemIndex(tempId);
    const item: QueueItem = {
      ...findItem(tempId),
      status: 'failed',
      error,
    };
    return { ...state, items: R.update(index, item, state.items) };
  }

  if (action.type === 'CLEAR_QUEUE') {
    return {
      ...state,
      items: [],
    };
  }

  return state;
}

const initialQueue = (): UploadQueueState => ({
  status: 'idle',
  items: [],
});

function CmsFileUploader({ onAllSettled }: Props) {
  const [queue, dispatch] = useReducer(uploadQueueReducer, initialQueue());

  const handleDrop = (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      dispatch({
        type: 'ADD_ITEM',
        payload: {
          tempId: v4(),
          status: 'pending',
          file: files[i],
          isPublic: false,
        },
      });
    }
  };

  function handleDropRejected(rejects: FileRejection[]) {
    const names = rejects.map(r => r.file.name).join('\n');
    toast.error(`The following files weren't accepted:\n${names}`);
  }

  const handleRemoveItem = (tempId: string) => () =>
    dispatch({ type: 'REMOVE_ITEM', payload: tempId });

  const handleUploadSuccess = (item: QueueItem) =>
    dispatch({ type: 'ITEM_SUCCESS', payload: item.tempId });

  const handleUploadFailed = (item: QueueItem, error: string) =>
    dispatch({
      type: 'ITEM_FAILED',
      payload: { tempId: item.tempId, error },
    });

  const setItemPublic = (tempId: string, isPublic: boolean) => () =>
    dispatch({
      type: 'SET_ITEM_PUBLIC',
      payload: { tempId, isPublic },
    });

  async function uploadFile(item: QueueItem) {
    dispatch({ type: 'ITEM_STARTED', payload: item.tempId });

    try {
      const body = new FormData();
      body.append('file', item.file);
      body.append('isPublic', String(item.isPublic));

      await fetch('/api/v3/file/cms', { method: 'post', body });

      handleUploadSuccess(item);
    } catch (err) {
      let message = 'An error occurred while uploading the file.';
      if (err instanceof Error && err.message) {
        message = err.message;
      }
      handleUploadFailed(item, message);
    }
  }

  async function processQueue() {
    dispatch({ type: 'QUEUE_STARTED' });

    // Upload each pending item one by one
    const toUpload = queue.items.filter(item => item.status === 'pending');
    for (let i = 0; i < toUpload.length; i++) {
      await uploadFile(toUpload[i]);
    }

    dispatch({ type: 'QUEUE_DONE' });

    if (onAllSettled) {
      onAllSettled();
    }
  }

  const clearQueue = () => dispatch({ type: 'CLEAR_QUEUE' });

  function statusClass(status: QueueItemStatus): string {
    switch (status) {
      case 'pending':
        return 'text-muted';
      case 'uploading':
        return 'text-info';
      case 'success':
        return 'text-success';
      case 'failed':
        return 'text-danger';
    }
  }

  console.log('Queue state:', queue);

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Upload Files</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {queue.status === 'idle' && (
          <Dropzone
            onDrop={handleDrop}
            multiple
            maxSize={100_000_000}
            onDropRejected={handleDropRejected}
          >
            {({ getRootProps, getInputProps }) => (
              <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <span>Drop files here or click to browse.</span>
              </DropzoneContainer>
            )}
          </Dropzone>
        )}

        <div
          style={{
            marginTop: '10px',
            wordBreak: 'break-all',
          }}
        >
          {queue.items.map(item => (
            <div
              key={item.tempId}
              style={{
                marginBottom: '10px',
                backgroundColor: 'hsl(0, 0%, 98%)',
                border: '1px solid hsl(0, 0%, 95%)',
                padding: '15px',
                fontSize: '10pt',
              }}
            >
              <Button
                color="ghost"
                size="xs"
                className="float-right"
                type="button"
                onClick={handleRemoveItem(item.tempId)}
                disabled={queue.status === 'active'}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>

              <div className="font-bold break-all">{item.file.name}</div>

              <div className="form-control">
                <label className="label cursor-pointer justify-start gap-2">
                  <input
                    type="checkbox"
                    value="whatever"
                    onChange={setItemPublic(item.tempId, !item.isPublic)}
                    checked={item.isPublic}
                    className="checkbox"
                    disabled={item.status !== 'pending'}
                  />
                  Public
                </label>
              </div>

              <div
                className={cn('text-center', statusClass(item.status))}
                style={{ cursor: 'default' }}
              >
                {item.status}
              </div>

              {item.error && <div className="text-danger">{item.error}</div>}

              <SpinnerPlaceholder show={item.status === 'uploading'} />
            </div>
          ))}
        </div>
      </Panel.Body>

      {queue.items.length > 0 && (
        <Panel.Footer className="text-center space-x-2">
          <Button
            type="button"
            color="primary"
            size="sm"
            onClick={processQueue}
            disabled={queue.status === 'active'}
          >
            Start Upload
          </Button>

          {queue.status === 'idle' && (
            <Button type="button" color="ghost" size="sm" onClick={clearQueue}>
              Clear queue
            </Button>
          )}
        </Panel.Footer>
      )}
    </Panel>
  );
}

export default CmsFileUploader;
