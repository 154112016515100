import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Checkbox } from 'react-daisyui';
import * as fragments from '~/apollo/fragments';
import type {
  StudyKeyParametersTabQuery,
  StudyKeyParametersTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { Heading } from '~/components/common/Heading';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersPanel } from '~/components/keyParameter/KeyParametersPanel';
import { QualityParametersTableStudy } from '~/components/study/QualityParametersTableStudy';
import { useStudyOutletContext } from '~/routes/study/$studyId';

const STUDY_KEY_PARAMETERS_TAB = gql`
  query StudyKeyParametersTab($id: Int!) {
    studyList(id: $id) {
      id
      dominantGeologyType
      keyParameters {
        ...keyParametersParts
      }
      keyParametersFromAe {
        ...keyParametersFromAeParts
      }
      outcrops {
        id
        keyParameters {
          ...keyParametersParts
        }
      }
      qualityParameters {
        ...studyQualityParametersParts
      }
    }
  }

  ${fragments.keyParametersParts}
  ${fragments.keyParametersFromAeParts}
  ${fragments.studyQualityParametersParts}
`;

export default function StudyKeyParametersRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const [includeAE, setIncludeAE] = useState(true);
  const [includeOC, setIncludeOC] = useState(true);

  const { data, loading } = useQuery<
    StudyKeyParametersTabQuery,
    StudyKeyParametersTabQueryVariables
  >(STUDY_KEY_PARAMETERS_TAB, {
    variables: { id: studyId },
  });

  const studyList = data?.studyList ?? [];
  const study = studyList.find(s => s.id === studyId);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  const kpsFromStudy = study.keyParameters;
  const kpsFromAEs = study.keyParametersFromAe.map(kp => ({
    ...kp,
    dominant: false,
  }));
  const kpsFromOutcrops = study.outcrops.flatMap(oc => oc.keyParameters);

  const keyParameters = [
    ...kpsFromStudy,
    ...(includeAE ? kpsFromAEs : []),
    ...(includeOC ? kpsFromOutcrops : []),
  ];

  const showAEOption = kpsFromAEs.length > 0;

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      <div className="lg:col-span-2">
        <KeyParametersPanel
          noHeading
          keyParameters={keyParameters}
          dominantGeologyType={study.dominantGeologyType}
          forceShow
        >
          {(kps, tabs) => (
            <>
              <div className="flex justify-between items-end">
                <Heading level={3}>Key Parameters</Heading>
                <div>{tabs}</div>
              </div>

              <Panel>
                <Panel.Body>{kps}</Panel.Body>
                <Panel.Footer className="flex justify-center items-center gap-4">
                  <div className="form-control">
                    <label className="label justify-start cursor-pointer gap-2">
                      <Checkbox
                        checked={includeOC}
                        onChange={() => setIncludeOC(v => !v)}
                        size="sm"
                      />
                      <span className="label-text">Include Outcrop Data</span>
                    </label>
                  </div>
                  {showAEOption && (
                    <div className="form-control">
                      <label className="label justify-start cursor-pointer gap-2">
                        <Checkbox
                          checked={includeAE}
                          onChange={() => setIncludeAE(v => !v)}
                          size="sm"
                        />
                        <span className="label-text">
                          Include Architectural Element Data
                        </span>
                      </label>
                    </div>
                  )}
                </Panel.Footer>
              </Panel>
            </>
          )}
        </KeyParametersPanel>
      </div>

      <div>
        <Heading level={3}>Quality Control Parameters</Heading>
        <Panel>
          <Panel.Body>
            <QualityParametersTableStudy study={study} noHeading />
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
}
