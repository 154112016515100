import { useQuery } from '@apollo/client';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import type { ButtonProps } from 'react-daisyui';
import { Button } from 'react-daisyui';
import { OUTCROP_EXAMPLES } from '~/apollo/operations/analogueSearch';
import type {
  KeyParametersPartsFragment,
  SearchOutcropsQuery,
  SearchOutcropsQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { ucwords } from '~/utils/text';
import { SearchResultItem } from './SearchResultItem';

interface QueryParams {
  geologyType: string[];
  grossDepositionalEnvironment: string[];
  depositionalEnvironment: string[];
  basinType: string[];
  climate: string[];
}

function defaultQuery(
  geologyType: string[],
  keyParameters: KeyParametersPartsFragment[],
  basinType: string,
  climate?: string,
): QueryParams {
  const gdeKPs = keyParameters
    .map(kp => kp.grossDepositionalEnvironment)
    .filter((gde): gde is string => !!gde);
  const gdeOptions = R.uniq(gdeKPs);

  const deKPs = keyParameters
    .map(kp => kp.depositionalEnvironment)
    .filter((de): de is string => !!de);
  const deOptions = R.uniq(deKPs);

  const climateOptions = climate ? [climate] : [];

  return {
    geologyType,
    grossDepositionalEnvironment: gdeOptions,
    depositionalEnvironment: deOptions,
    basinType: [basinType],
    climate: climateOptions,
  };
}

type ToggleButtonProps = {
  value: string;
  isSelected: boolean;
  onClick: ButtonProps['onClick'];
};
function ToggleButton({ value, isSelected, onClick }: ToggleButtonProps) {
  return (
    <div style={{ marginBottom: '3px' }}>
      <Button
        type="button"
        color={isSelected ? 'primary' : 'ghost'}
        size="sm"
        onClick={onClick}
        className="block w-full h-auto p-2"
      >
        <div className="flex gap-2 text-left">
          <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
          <div className="text-left font-medium text-sm">{ucwords(value)}</div>
        </div>
      </Button>
    </div>
  );
}

type Props = {
  outcropId: number;
  geologyType: string[];
  keyParameters: KeyParametersPartsFragment[];
  basinType: string;
  climate?: string;
};

export function AnalogueSearch({
  outcropId,
  geologyType,
  basinType,
  climate,
  keyParameters,
}: Props) {
  const availableOptions = defaultQuery(
    geologyType,
    keyParameters,
    basinType,
    climate,
  );
  const [query, setQuery] = useState<QueryParams>(availableOptions);
  const { data, loading } = useQuery<
    SearchOutcropsQuery,
    SearchOutcropsQueryVariables
  >(OUTCROP_EXAMPLES, { variables: query });

  const ocResults = (data?.searchOutcrops.outcrops ?? []).filter(
    oc => oc.id !== outcropId,
  );
  const outcrops = R.sortBy(oc => oc.name, ocResults);

  function isSelected(key: keyof QueryParams, value: string): boolean {
    return query[key].includes(value);
  }

  const handleToggle = (key: keyof QueryParams, value: string) => () => {
    let nextQuery: QueryParams;
    let nextOpts: string[];

    if (isSelected(key, value)) {
      nextOpts = R.pipe(
        R.pathOr<string[]>([], [key]),
        R.reject(R.equals(value)),
      )(query);
    } else {
      nextOpts = R.pipe(
        R.pathOr<string[]>([], [key]),
        R.append(value),
        R.uniq,
      )(query);
    }

    nextQuery = R.assoc(key, nextOpts, query);
    setQuery(nextQuery);
  };

  return (
    <div className="grid lg:grid-cols-4 gap-6">
      <div className="lg:col-span-3">
        <SpinnerPlaceholder show={loading}>
          Searching for matching analogues...
        </SpinnerPlaceholder>

        {!loading && (
          <>
            {outcrops.map(oc => (
              <SearchResultItem key={oc.id} outcrop={oc} />
            ))}
            {!outcrops.length && (
              <div
                className="text-center text-muted"
                style={{ marginTop: '20px' }}
              >
                <i>No analogues found matching the selected filters.</i>
              </div>
            )}
          </>
        )}
      </div>

      <div>
        {availableOptions.grossDepositionalEnvironment.map(gde => (
          <ToggleButton
            key={gde}
            value={gde}
            isSelected={isSelected('grossDepositionalEnvironment', gde)}
            onClick={handleToggle('grossDepositionalEnvironment', gde)}
          />
        ))}

        {availableOptions.depositionalEnvironment.map(de => (
          <ToggleButton
            key={de}
            value={de}
            isSelected={isSelected('depositionalEnvironment', de)}
            onClick={handleToggle('depositionalEnvironment', de)}
          />
        ))}

        {availableOptions.basinType.map(bt => (
          <ToggleButton
            key={bt}
            value={bt}
            isSelected={isSelected('basinType', bt)}
            onClick={handleToggle('basinType', bt)}
          />
        ))}

        {availableOptions.climate.map(c => (
          <ToggleButton
            key={c}
            value={c}
            isSelected={isSelected('climate', c)}
            onClick={handleToggle('climate', c)}
          />
        ))}
      </div>
    </div>
  );
}
