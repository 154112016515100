import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import { dataSearchMeasurementParts, dmvParts } from '~/apollo/fragments';
import type {
  OutcropMeasurementsTabQuery,
  OutcropMeasurementsTabQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FormikField } from '~/components/common/FormikField';
import { FormikCheckboxArrayDropdown } from '~/components/common/FormikField/FormikCheckboxArrayDropdown';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { EmbeddableDataSearch } from '~/components/dataSearch/EmbeddableDataSearch';
import {
  DataSearchContextProvider,
  useDataSearchContext,
} from '~/components/dataSearch/dataSearchContext';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';
import type { DataSearchFormValues } from '~/utils/modules/dataSearch';
import { dataSearchInitialValues } from '~/utils/modules/dataSearch';

type Outcrop = OutcropMeasurementsTabQuery['outcropList'][number];
type Study = Outcrop['studies'][number];

function MeasurementsPageContent({ studies }: { studies: Study[] }) {
  const { measurements, searchInput } = useDataSearchContext();
  const { values, submitForm } = useFormikContext<DataSearchFormValues>();

  const hasCachedAxes = searchInput.dataTypeX && searchInput.dataTypeY;

  useEffect(() => {
    if (
      values.graphType === 'crossPlot' &&
      values.crossPlot.dataTypeX &&
      values.crossPlot.dataTypeY &&
      (!measurements.length || !hasCachedAxes)
    ) {
      submitForm();
    } else if (
      values.graphType === 'histogram' &&
      values.histogram.dataType &&
      (!measurements.length || !hasCachedAxes)
    ) {
      submitForm();
    }
    // We only want to fire this once on page load, if it has pre-filled values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.graphType]);

  type Study = (typeof studies)[number];

  function studyHasMeasurements(study: Study) {
    const totalMeasurements = study.architecturalElements.reduce(
      (acc, ae) => acc + ae.measurementCount,
      0,
    );
    return totalMeasurements > 0;
  }

  const studyOptions = studies.map(study => ({
    value: study.id,
    date: study.dataHistory?.date,
    label: (
      <div>
        <div>
          <strong>
            {[study.dataHistory?.date, study.dataHistory?.collectedBy].join(
              ', ',
            )}
          </strong>
        </div>
        <div className="text-sm">{study.name}</div>
      </div>
    ),
    disabled: !studyHasMeasurements(study),
  }));

  const sortedStudyOptions = R.sortWith(
    [R.ascend(opt => opt.disabled), R.descend(opt => opt.date ?? Infinity)],
    studyOptions,
  );

  return (
    <Panel>
      <Panel.Heading>
        <div className="flex justify-between items-center gap-6">
          <Panel.Title>Measurements</Panel.Title>
          <div className="flex items-center gap-2">
            <span className="text-sm text-slate-500">Studies</span>
            <Field
              name="studyIds"
              component={FormikField}
              type={FormikCheckboxArrayDropdown}
              options={sortedStudyOptions}
              dropdownBtnClass="select-sm"
              align="end"
            />
          </div>
        </div>
      </Panel.Heading>

      <Panel.Body>
        <EmbeddableDataSearch />
      </Panel.Body>
    </Panel>
  );
}

const OUTCROP_MEASUREMENTS_TAB = gql`
  query OutcropMeasurementsTab(
    $outcropId: Int!
    $outcropIds: [Int!]!
    $studyIds: [Int!]!
  ) {
    outcropList(id: $outcropId) {
      id
      studies {
        id
        name
        architecturalElements {
          id
          measurementCount
        }
        dataHistory {
          date
          collectedBy
        }
      }
      defaultMeasurementsView {
        ...dmvParts
      }
    }

    suggestedDataTypes(outcropIds: $outcropIds, studyIds: $studyIds) {
      dataTypeX
      dataTypeY
      measurements {
        ...dataSearchMeasurementParts
      }
    }
  }

  ${dataSearchMeasurementParts}
  ${dmvParts}
`;

export default function OutcropMeasurementsTab() {
  const { outcrop } = useOutcropOutletContext();

  const { data, loading } = useQuery<
    OutcropMeasurementsTabQuery,
    OutcropMeasurementsTabQueryVariables
  >(OUTCROP_MEASUREMENTS_TAB, {
    variables: {
      outcropId: outcrop.id,
      outcropIds: [outcrop.id],
      studyIds: [],
    },
  });

  const studies = useMemo(() => {
    const ocStudies = data?.outcropList.find(
      oc => oc.id === outcrop.id,
    )?.studies;
    return R.sortBy(s => s.name, ocStudies ?? []);
  }, [outcrop.id, data]);

  // Only select studies that have measurement data, the others will be disabled
  const initialStudyIds = useMemo(() => {
    return studies
      .filter(s => s.architecturalElements.find(ae => ae.measurementCount > 0))
      .map(s => s.id);
  }, [studies]);

  const dmv = data?.outcropList.find(
    oc => oc.id === outcrop.id,
  )?.defaultMeasurementsView;

  const initialValues = dataSearchInitialValues();
  initialValues.outcropIds = [outcrop.id];
  initialValues.studyIds = initialStudyIds;

  const initialMeasurements = dmv
    ? undefined
    : (data?.suggestedDataTypes.measurements ?? []);

  if (dmv) {
    initialValues.graphType =
      dmv.graphType === 'CROSS_PLOT' ? 'crossPlot' : 'histogram';
    initialValues.crossPlot.dataTypeX = dmv.crossPlotDataTypeX;
    initialValues.crossPlot.dataTypeY = dmv.crossPlotDataTypeY;
    initialValues.crossPlot.logScaleX = dmv.crossPlotLogScaleX ?? false;
    initialValues.crossPlot.logScaleY = dmv.crossPlotLogScaleY ?? false;
    initialValues.histogram.dataType = dmv.histogramDataType;
  } else {
    const dataTypeX = data?.suggestedDataTypes.dataTypeX ?? null;
    const dataTypeY = data?.suggestedDataTypes.dataTypeY ?? null;

    if (dataTypeX && dataTypeY) {
      initialValues.graphType = 'crossPlot';
      initialValues.crossPlot.dataTypeX = dataTypeX;
      initialValues.crossPlot.dataTypeY = dataTypeY;
    } else if (dataTypeX && !dataTypeY) {
      initialValues.graphType = 'histogram';
      initialValues.histogram.dataType = dataTypeX;
    }
  }

  if (loading) {
    return <SpinnerPlaceholder />;
  }

  return (
    <DataSearchContextProvider
      initialValues={initialValues}
      initialMeasurements={initialMeasurements}
    >
      <MeasurementsPageContent studies={studies} />
    </DataSearchContextProvider>
  );
}
