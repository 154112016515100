import type {
  CrossSectionInput,
  CrossSectionPartsFragment,
  FaciesInput,
  FaciesPartsFragment,
  FilePartsFragment,
  GeoreferencePartsFragment,
  GigaPanInput,
  GigaPanPartsFragment,
  OutcropPartsFragment,
  PictureInput,
  PicturePartsFragment,
  ProductionInput,
  ProductionPartsFragment,
  ReservoirModelInput,
  ReservoirModelPartsFragment,
  SedimentaryLogInput,
  SedimentaryLogPartsFragment,
  TrainingImageInput,
  TrainingImagePartsFragment,
  VariogramInput,
  VariogramPartsFragment,
  WellLogInput,
  WellLogPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { FileParent } from '~/apollo/generated/v3/graphql';
import type { Picture as SOPictureParts } from '~/components/upload/supportingObject/SupportingObjectPictureList';
import { yup } from '~/utils/validation';

export type SupportObjectParentType = 'outcrop' | 'study';

/** Client-side type for differentiating SO types */
export type SupportObjectType =
  | 'crossSection'
  | 'sedimentaryLog'
  | 'facies'
  | 'wellLog'
  | 'production'
  | 'reservoirModel'
  | 'trainingImage'
  | 'variogram'
  | 'gigaPan';

// Used by the REST endpoint for uploading files
export type SupportObjectParent =
  | 'CROSS_SECTION'
  | 'SEDIMENTARY_LOG'
  | 'FACIES'
  | 'WELL_LOG'
  | 'PRODUCTION'
  | 'RESERVOIR_MODEL'
  | 'TRAINING_IMAGE'
  | 'VARIOGRAM'
  | 'GIGA_PAN';

type SO<T, K extends { [key: string]: any }> = T & K & OutcropTag;
type Georeferences = { georeference: GeoreferencePartsFragment[] };
type Pictures = { pictures: SOPictureParts[] };
type Files = { files: FilePartsFragment[] };
type OutcropTag = { outcropTag?: OutcropPartsFragment | null };

export type FaciesSO = SO<FaciesPartsFragment, Pictures>;
export type CrossSectionSO = SO<
  CrossSectionPartsFragment,
  Georeferences & Pictures & Files
>;
export type SedimentaryLogSO = SO<
  SedimentaryLogPartsFragment,
  Pictures & Files & Georeferences
>;
export type WellLogSO = SO<WellLogPartsFragment, Pictures & Files>;
export type ProductionSO = SO<ProductionPartsFragment, Pictures & Files>;
export type ReservoirModelSO = SO<
  ReservoirModelPartsFragment,
  Pictures & Files
>;
export type TrainingImageSO = SO<TrainingImagePartsFragment, Pictures & Files>;
export type VariogramSO = SO<VariogramPartsFragment, Pictures & Files>;
export type GigaPanSO = SO<GigaPanPartsFragment, Pictures>;

export type SupportObject =
  | CrossSectionSO
  | FaciesSO
  | SedimentaryLogSO
  | WellLogSO
  | ProductionSO
  | ReservoirModelSO
  | TrainingImageSO
  | VariogramSO
  | GigaPanSO;

export type SupportObjectFormValues = {
  name: string;
  description?: string;
  outcropTagId?: string | number;
  author?: string;
  comments?: string;
  scale?: string;
  interpretation?: string;
  gigaPanHash?: string;
  nugget?: string;
  sill?: string;
  range?: string;
  published: boolean;
  priority?: string;
};

export type SupportObjectField = {
  name: keyof SupportObjectFormValues;
  label: string;
  helpText?: string;
};

export const fields: Record<string, SupportObjectField> = {
  NAME: { name: 'name', label: 'Name' },
  DESCRIPTION: { name: 'description', label: 'Description' },
  AUTHOR: { name: 'author', label: 'Author' },
  SCALE: { name: 'scale', label: 'Scale' },
  COMMENTS: { name: 'comments', label: 'Comments' },
  INTERPRETATION: { name: 'interpretation', label: 'Interpretation' },
  GIGA_PAN_HASH: { name: 'gigaPanHash', label: 'GigaPan Hash' },
  NUGGET: {
    name: 'nugget',
    label: 'Nugget',
    helpText:
      'Nugget n: The height of the jump of the semivariogram at the discontinuity at the origin.',
  },
  SILL: {
    name: 'sill',
    label: 'Sill',
    helpText:
      'Sill s: Limit of the variogram tending to infinity lag distances.',
  },
  RANGE: {
    name: 'range',
    label: 'Range',
    helpText:
      'Range r: The distance in which the difference of the variogram from the sill becomes negligible. In models with a fixed sill, it is the distance at which this is first reached; for models with an asymptotic sill, it is conventionally taken to be the distance when the semivariance first reaches 95% of the sill.',
  },
  PUBLISHED: {
    name: 'published',
    label: 'Published',
    helpText: 'Only published entities will be shown to non-administrators.',
  },
  PRIORITY: {
    name: 'priority',
    label: 'Priority',
  },
};

export function supportObjectFields(soType: SupportObjectType) {
  switch (soType) {
    case 'crossSection':
    case 'sedimentaryLog':
      return [
        fields.NAME,
        fields.DESCRIPTION,
        fields.AUTHOR,
        fields.SCALE,
        fields.COMMENTS,
        fields.PRIORITY,
      ];

    case 'facies':
      return [
        fields.NAME,
        fields.DESCRIPTION,
        fields.AUTHOR,
        fields.INTERPRETATION,
        fields.COMMENTS,
        fields.PRIORITY,
      ];

    case 'gigaPan':
      return [fields.NAME, fields.GIGA_PAN_HASH, fields.PRIORITY];

    case 'variogram':
      return [
        fields.NAME,
        fields.DESCRIPTION,
        fields.AUTHOR,
        fields.COMMENTS,
        fields.NUGGET,
        fields.SILL,
        fields.RANGE,
        fields.PRIORITY,
      ];

    default:
      return [
        fields.NAME,
        fields.DESCRIPTION,
        fields.AUTHOR,
        fields.COMMENTS,
        fields.PRIORITY,
      ];
  }
}

// All types except facies and gigapans support files
export function hasFiles(soType: SupportObjectType) {
  const noFiles: SupportObjectType[] = ['facies', 'gigaPan'];
  return !noFiles.includes(soType);
}

// All types support pictures
export const hasPictures = (soType: SupportObjectType) => true;

export function hasGeoreferences(soType: SupportObjectType) {
  const withGeorefs: SupportObjectType[] = [
    'crossSection',
    'sedimentaryLog',
    'gigaPan',
  ];
  return withGeorefs.includes(soType);
}

export function toSOInputType(
  type: SupportObjectType,
  fv: SupportObjectFormValues,
) {
  const valToIntOrNull = (val?: string | number) => {
    if (typeof val === 'number') return val;
    return val?.length ? parseInt(val) : null;
  };
  const valToFloatOrNull = (val?: string) =>
    val?.length ? parseFloat(val) : null;

  switch (type) {
    case 'crossSection':
      return {
        name: fv.name.trim(),
        description: fv.description?.trim() || '',
        scale: fv.scale?.trim() || null,
        author: fv.author?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies CrossSectionInput;

    case 'facies':
      return {
        name: fv.name.trim(),
        description: fv.description?.trim() || '',
        interpretation: fv.interpretation?.trim() || null,
        author: fv.author?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies FaciesInput;

    case 'variogram':
      return {
        name: fv.name.trim(),
        description: fv.description?.trim() || null,
        author: fv.author?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        nugget: valToFloatOrNull(fv.nugget),
        range: valToFloatOrNull(fv.range),
        sill: valToFloatOrNull(fv.sill),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies VariogramInput;

    case 'production':
      return {
        name: fv.name.trim(),
        author: fv.author?.trim() || null,
        description: fv.description?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies ProductionInput;

    case 'reservoirModel':
      return {
        name: fv.name.trim(),
        author: fv.author?.trim() || null,
        description: fv.description?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies ReservoirModelInput;

    case 'sedimentaryLog':
      return {
        name: fv.name.trim(),
        author: fv.author?.trim() || '',
        description: fv.description?.trim() || '',
        comments: fv.comments?.trim() || null,
        scale: fv.scale?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies SedimentaryLogInput;

    case 'trainingImage':
      return {
        name: fv.name.trim(),
        author: fv.author?.trim() || null,
        description: fv.description?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies TrainingImageInput;

    case 'wellLog':
      return {
        name: fv.name.trim(),
        author: fv.author?.trim() || null,
        description: fv.description?.trim() || null,
        comments: fv.comments?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies WellLogInput;

    case 'gigaPan':
      return {
        name: fv.name.trim(),
        gigaPanHash: fv.gigaPanHash?.trim() || null,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      } satisfies GigaPanInput;

    default:
      const input: any = {
        ...fv,
        outcropTagId: valToIntOrNull(fv.outcropTagId),
        published: fv.published,
        priority: castToNullableNumber(fv.priority ?? ''),
      };
      return input;
  }
}

const castToString = (val: any) =>
  typeof val !== 'undefined' && val !== null ? String(val) : '';

export function initialVariogram(
  v?: VariogramPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(v?.name),
    author: castToString(v?.author),
    comments: castToString(v?.comments),
    description: castToString(v?.description),
    outcropTagId: v?.outcropTagId ?? '',
    nugget: castToString(v?.nugget),
    range: castToString(v?.range),
    sill: castToString(v?.sill),
    published: v?.published ?? true,
    priority: castToString(v?.priority),
  };
}
export function initialCrossSection(
  cs?: CrossSectionPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(cs?.name),
    description: castToString(cs?.description),
    author: castToString(cs?.author),
    scale: castToString(cs?.scale),
    comments: castToString(cs?.comments),
    outcropTagId: cs?.outcropTagId ?? '',
    published: cs?.published ?? true,
    priority: castToString(cs?.priority),
  };
}
export function initialSedimentaryLog(
  sl?: SedimentaryLogPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(sl?.name),
    description: castToString(sl?.description),
    author: castToString(sl?.author),
    scale: castToString(sl?.scale),
    comments: castToString(sl?.comments),
    outcropTagId: sl?.outcropTagId ?? '',
    published: sl?.published ?? true,
    priority: castToString(sl?.priority),
  };
}
export function initialFacies(
  f?: FaciesPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(f?.name),
    description: castToString(f?.description),
    author: castToString(f?.author),
    interpretation: castToString(f?.interpretation),
    comments: castToString(f?.comments),
    outcropTagId: f?.outcropTagId ?? '',
    published: f?.published ?? true,
    priority: castToString(f?.priority),
  };
}
export function initialGigaPan(
  gp?: GigaPanPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(gp?.name),
    gigaPanHash: castToString(gp?.gigaPanHash),
    outcropTagId: gp?.outcropTagId ?? '',
    published: gp?.published ?? true,
    priority: castToString(gp?.priority),
  };
}

export function initialWellLog(
  wl?: WellLogPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(wl?.name),
    description: castToString(wl?.description),
    author: castToString(wl?.author),
    comments: castToString(wl?.comments),
    outcropTagId: wl?.outcropTagId ?? '',
    published: wl?.published ?? true,
    priority: castToString(wl?.priority),
  };
}
export function initialProduction(
  p?: ProductionPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(p?.name),
    description: castToString(p?.description),
    author: castToString(p?.author),
    comments: castToString(p?.comments),
    outcropTagId: p?.outcropTagId ?? '',
    published: p?.published ?? true,
    priority: castToString(p?.priority),
  };
}
export function initialReservoirModel(
  rm?: ReservoirModelPartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(rm?.name),
    description: castToString(rm?.description),
    author: castToString(rm?.author),
    comments: castToString(rm?.comments),
    outcropTagId: rm?.outcropTagId ?? '',
    published: rm?.published ?? true,
    priority: castToString(rm?.priority),
  };
}
export function initialTrainingImage(
  ti?: TrainingImagePartsFragment,
): SupportObjectFormValues {
  return {
    name: castToString(ti?.name),
    description: castToString(ti?.description),
    author: castToString(ti?.author),
    comments: castToString(ti?.comments),
    outcropTagId: ti?.outcropTagId ?? '',
    published: ti?.published ?? true,
    priority: castToString(ti?.priority),
  };
}

export type SOParts =
  | VariogramPartsFragment
  | CrossSectionPartsFragment
  | SedimentaryLogPartsFragment
  | FaciesPartsFragment
  | GigaPanPartsFragment
  | WellLogPartsFragment
  | ProductionPartsFragment
  | ReservoirModelPartsFragment
  | TrainingImagePartsFragment;

export function initialSupportingObject(
  entity?: SOParts,
  soType?: SupportObjectType,
) {
  switch (entity?.__typename) {
    case 'CrossSection':
      return initialCrossSection(entity);
    case 'Variogram':
      return initialVariogram(entity);
    case 'SedimentaryLog':
      return initialSedimentaryLog(entity);
    case 'Facies':
      return initialFacies(entity);
    case 'GigaPan':
      return initialGigaPan(entity);
    case 'WellLog':
      return initialWellLog(entity);
    case 'Production':
      return initialProduction(entity);
    case 'ReservoirModel':
      return initialReservoirModel(entity);
    case 'TrainingImage':
      return initialTrainingImage(entity);
    default:
      switch (soType) {
        case 'crossSection':
          return initialCrossSection();
        case 'variogram':
          return initialVariogram();
        case 'sedimentaryLog':
          return initialSedimentaryLog();
        case 'facies':
          return initialFacies();
        case 'gigaPan':
          return initialGigaPan();
        case 'wellLog':
          return initialWellLog();
        case 'production':
          return initialProduction();
        case 'reservoirModel':
          return initialReservoirModel();
        case 'trainingImage':
          return initialTrainingImage();
        default:
          throw new Error('SO type not provided');
      }
  }
}

export interface PictureFormValues {
  name: string;
  description: string;
  type: string;
  author: string;
  scale: string;
  comments: string;
  outcropTagId: string | number;
  priority: string | number;
  published: boolean;
}

type PictureUpdateParts = Pick<
  PicturePartsFragment,
  | 'name'
  | 'description'
  | 'type'
  | 'author'
  | 'scale'
  | 'comments'
  | 'outcropTagId'
  | 'priority'
  | 'published'
>;

export function initialPicture(picture?: PictureUpdateParts) {
  return {
    name: picture?.name ?? '',
    description: picture?.description ?? '',
    type: picture?.type ?? '',
    author: picture?.author ?? '',
    scale: picture?.scale ?? '',
    comments: picture?.comments ?? '',
    outcropTagId: picture?.outcropTagId ?? '',
    priority: picture?.priority ?? '',
    published: picture?.published ?? true,
  };
}

export const pictureValidationSchema = () =>
  yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    type: yup.string(),
    author: yup.string(),
    scale: yup.string(),
    comments: yup.string(),
    outcropTagId: yup.number().label('outcrop tag'),
    priority: yup.number(),
  });

export function fileParentFromSOType(soType: SupportObjectType): FileParent {
  switch (soType) {
    case 'crossSection':
      return FileParent.CrossSection;
    case 'production':
      return FileParent.Production;
    case 'reservoirModel':
      return FileParent.ReservoirModel;
    case 'sedimentaryLog':
      return FileParent.SedimentaryLog;
    case 'trainingImage':
      return FileParent.TrainingImage;
    case 'variogram':
      return FileParent.Variogram;
    case 'wellLog':
      return FileParent.WellLog;

    case 'facies':
    case 'gigaPan':
    default:
      throw new Error(`${soType} is not a valid FileParent`);
  }
}
export function soTypeFromFileParent(fp: FileParent): SupportObjectType {
  switch (fp) {
    case FileParent.CrossSection:
      return 'crossSection';
    case FileParent.Production:
      return 'production';
    case FileParent.ReservoirModel:
      return 'reservoirModel';
    case FileParent.SedimentaryLog:
      return 'sedimentaryLog';
    case FileParent.TrainingImage:
      return 'trainingImage';
    case FileParent.Variogram:
      return 'variogram';
    case FileParent.WellLog:
      return 'wellLog';

    default:
      throw new Error(`${fp} is not a valid FileParent`);
  }
}

const castToNullableNumber = (value: string | number) => {
  if (typeof value === 'number') return value;
  else if (!value.length) return null;

  const result = parseInt(value);
  if (Number.isNaN(result)) return null;
  return result;
};

export function formValuesToPictureInput(
  values: PictureFormValues,
): PictureInput {
  return {
    ...values,
    priority: castToNullableNumber(values.priority),
    outcropTagId: castToNullableNumber(values.outcropTagId),
    published: values.published ?? true,
  };
}
