import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  UpdateLiteratureReferenceMutation,
  UpdateLiteratureReferenceMutationVariables,
  UploadStudyUpdateLiteratureTabQuery,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import type { LiteratureFormValues } from '~/utils/modules/literatureReference';
import {
  defaultLiterature,
  toLiteratureReferenceInput,
  validationSchema,
} from '~/utils/modules/literatureReference';
import { LiteratureFormFields } from './LiteratureFormFields';

export const UPDATE_LITERATURE_REFERENCE = gql`
  mutation UpdateLiteratureReference(
    $id: Int!
    $literature: LiteratureReferenceInput!
  ) {
    updateLiteratureReference(id: $id, literature: $literature) {
      id
    }
  }
`;

type Props = {
  studyId: number;
  literature: UploadStudyUpdateLiteratureTabQuery['studyList'][number]['literatureReferences'][number];
  onUpdateSuccess: () => void;
  refetchQueries: PureQueryOptions[];
};

export function UpdateLiteratureForm({
  studyId,
  literature,
  onUpdateSuccess,
  refetchQueries,
}: Props) {
  const [updateLiterature, { loading, error }] = useMutation<
    UpdateLiteratureReferenceMutation,
    UpdateLiteratureReferenceMutationVariables
  >(UPDATE_LITERATURE_REFERENCE, {
    refetchQueries,
  });

  async function handleSubmit(values: LiteratureFormValues) {
    try {
      await updateLiterature({
        variables: {
          id: literature.id,
          literature: toLiteratureReferenceInput(values),
        },
      });

      toast.success('Literature reference saved successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error saving literature reference', err);
      toast.error('There was a prolem saving the literature reference.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultLiterature(literature)}
      validationSchema={validationSchema}
    >
      <Form className="space-y-4">
        <LiteratureFormFields studyId={studyId} />
        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
