import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateOutcropStudyLinkMutation,
  CreateOutcropStudyLinkMutationVariables,
  Outcrop,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import { Panel } from '~/components/common/Panel';
import { yup } from '~/utils/validation';

export const CREATE_OUTCROP_STUDY_LINK = gql`
  mutation CreateOutcropStudyLink($outcropId: Int!, $studyId: Int!) {
    createOutcropStudyLink(outcropId: $outcropId, studyId: $studyId)
  }
`;

type CreateLinkFormValues = {
  outcropId: string;
};

const validationSchema = yup.object({
  outcropId: yup.number().integer().label('outcrop').required(),
});

type Props = {
  studyId: number;
  outcrops: Pick<Outcrop, 'id' | 'name'>[];
  refetchQueries: PureQueryOptions[];
};

export function CreateOutcropLinkForm({
  studyId,
  outcrops,
  refetchQueries,
}: Props) {
  const [createLink, { loading, error }] = useMutation<
    CreateOutcropStudyLinkMutation,
    CreateOutcropStudyLinkMutationVariables
  >(CREATE_OUTCROP_STUDY_LINK, { refetchQueries });

  async function handleSubmit(
    values: CreateLinkFormValues,
    formikBag: FormikHelpers<CreateLinkFormValues>,
  ) {
    const outcropId = parseInt(values.outcropId);

    try {
      await createLink({ variables: { outcropId, studyId } });
      toast.success('Outcrop link saved successfully.');
      formikBag.resetForm();
    } catch (err) {
      console.log('Error saving outcrop link', err);
      toast.error('There was a problem saving the outcrop link.');
    }
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Add an Outcrop Link</Panel.Title>
      </Panel.Heading>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ outcropId: '' }}
        validationSchema={validationSchema}
      >
        <Form>
          <Panel.Body className="space-y-4">
            <Field
              name="outcropId"
              label="Outcrop"
              component={FormikField}
              type="select"
              options={outcrops.map(outcrop => ({
                value: outcrop.id,
                label: `${outcrop.name} [${outcrop.id}]`,
              }))}
            />

            <FormErrors graphQLError={error} />
          </Panel.Body>
          <Panel.Footer className="flex justify-end">
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary"
              loading={loading}
            >
              Save
            </Button>
          </Panel.Footer>
        </Form>
      </Formik>
    </Panel>
  );
}

export default CreateOutcropLinkForm;
