import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { measurementParts } from '~/apollo/fragments';
import type {
  AePartsFragment,
  CreateMeasurementMutation,
  CreateMeasurementMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { useModalState } from '~/hooks/modal';
import type { MeasurementFormValues } from '~/utils/modules/architecturalMeasurement';
import {
  formValuesToMeasurementInput,
  initialMeasurementFormValues,
  validationSchema,
} from '~/utils/modules/architecturalMeasurement';
import { MeasurementFormFields } from './MeasurementFormFields';

const CREATE_MEASUREMENT = gql`
  mutation CreateMeasurement(
    $architecturalElementId: Int!
    $architecturalMeasurement: ArchitecturalMeasurementInput!
  ) {
    createArchitecturalMeasurement(
      architecturalElementId: $architecturalElementId
      architecturalMeasurement: $architecturalMeasurement
    ) {
      ...measurementParts
    }
  }

  ${measurementParts}
`;

type Props = {
  studyId: AePartsFragment['studyId'];
  architecturalElementId: number;
  refetchQueries: PureQueryOptions[];
  children: (showModal: (event: React.MouseEvent) => void) => React.ReactNode;
};

export function CreateMeasurementModal({
  studyId,
  architecturalElementId,
  refetchQueries,
  children,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createMeasurement, { loading, error }] = useMutation<
    CreateMeasurementMutation,
    CreateMeasurementMutationVariables
  >(CREATE_MEASUREMENT, { refetchQueries });

  async function handleSubmit(values: MeasurementFormValues) {
    const measurementInput = formValuesToMeasurementInput(
      values,
      architecturalElementId,
    );
    try {
      await createMeasurement({
        variables: {
          architecturalElementId,
          architecturalMeasurement: measurementInput,
        },
      });

      toast.success('Measurement created successfully.');
      hideModal();
    } catch (err) {
      console.log('Error creating measurement', err);
      toast.error('There was a problem creating the measurement.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialMeasurementFormValues()}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body heading="Create Architectural Measurement">
              <div className="space-y-4">
                <MeasurementFormFields />
                <FormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>

              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}
