import React from 'react';
import type {
  DiagramAreaPartsFragment,
  DiagramPartsFragment,
  FilePartsFragment,
} from '~/apollo/generated/v3/graphql';
import { linkTarget } from '~/utils/modules/diagram';

type Diagram = DiagramPartsFragment & {
  diagramAreas: DiagramAreaPartsFragment[];
  file?: FilePartsFragment | null;
};
type DiagramArea = Diagram['diagramAreas'][number];
type PolygonVertex = DiagramArea['path'][number];

export type Props = {
  diagram: Diagram;
};

export function DiagramRenderer({ diagram }: Props) {
  const handleClick = (area: DiagramArea) => (e: React.MouseEvent) => {
    e.preventDefault();

    const url = linkTarget(area.linkTargetType, area.linkTargetId);
    if (url) {
      window.open(url);
    } else {
      console.log(
        `Clicked on area with unknown route: targetType '${area.linkTargetType}', targetId: '${area.linkTargetId}'`,
      );
    }
  };

  const clipPath = (vertices: PolygonVertex[]) =>
    vertices.map(({ x, y }) => `${x}% ${y}%`).join(', ');

  return (
    <>
      <div className="relative max-w-full inline-block">
        {diagram.file?.signedUrl && (
          <img
            src={diagram.file.signedUrl}
            alt="diagram image"
            className="w-full"
          />
        )}

        {diagram.diagramAreas.map(area => (
          <div
            key={area.id}
            title={area.label}
            onClick={handleClick(area)}
            className="absolute top-0 left-0 right-0 bottom-0 bg-transparent hover:bg-secondary hover:bg-opacity-50 cursor-pointer"
            style={{
              clipPath: `polygon(${clipPath(area.path)})`,
            }}
          />
        ))}
      </div>
    </>
  );
}
