import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  UpdateLithostratMemberMutation,
  UpdateLithostratMemberMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { UPDATE_LITHOSTRAT_MEMBER } from '~/apollo/operations/lithostrat';
import { FormErrors } from '~/components/common/FormErrors';
import { MemberFormFields } from '~/components/upload/lithostrat/member/MemberFormFields';
import { useMemberUpdateOutletContext } from '~/routes/upload/lithostrat/member/$memberId';
import type { MemberFormValues } from '~/utils/modules/lithostrat';
import {
  formValuesToLithostratMemberInput,
  initialMember,
  memberValidationSchema,
} from '~/utils/modules/lithostrat';

export default function MemberUpdateDetailsRoute() {
  const { member } = useMemberUpdateOutletContext();

  const [updateMember, { loading, error }] = useMutation<
    UpdateLithostratMemberMutation,
    UpdateLithostratMemberMutationVariables
  >(UPDATE_LITHOSTRAT_MEMBER, {});

  async function handleSubmit(values: MemberFormValues) {
    const memberInput = formValuesToLithostratMemberInput(values);

    try {
      await updateMember({
        variables: {
          id: member.id,
          member: memberInput,
        },
      });
      toast.success('Member updated successfully.');
    } catch (err) {
      console.log('Error updating member', err);
      toast.error('There was a problem updating the member.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialMember(member)}
      validationSchema={memberValidationSchema()}
    >
      <Form className="space-y-4">
        <MemberFormFields />
        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
