import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  PagePartsFragment,
  UpdatePageMutation,
  UpdatePageMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import type { PageFormValues } from '~/utils/modules/page';
import { defaultPage, pageValidationSchema } from '~/utils/modules/page';
import { PageFormFields } from './PageFormFields';

const UPDATE_PAGE = gql`
  mutation UpdatePage($id: Int!, $page: PageInput!) {
    updatePage(id: $id, page: $page) {
      ...pageParts
    }
  }

  ${fragments.pageParts}
`;

type Props = {
  page: PagePartsFragment;
};

const UpdatePageForm: React.FC<Props> = ({ page }: Props) => {
  const [updatePage, { loading, error }] = useMutation<
    UpdatePageMutation,
    UpdatePageMutationVariables
  >(UPDATE_PAGE, {});

  async function handleSubmit(values: PageFormValues) {
    try {
      await updatePage({ variables: { id: page.id, page: values } });
      toast.success('Page updated successfully.');
    } catch (err) {
      console.log('Error updating page', err);
      toast.error('There was a problem updating the page.');
    }
  }

  return (
    <Formik
      key={page.id}
      onSubmit={handleSubmit}
      initialValues={defaultPage(page)}
      validationSchema={pageValidationSchema}
    >
      <Form>
        <PageFormFields />
        <FormErrors graphQLError={error} />

        <div className="text-center mt-4">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default UpdatePageForm;
