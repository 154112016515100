import type { PureQueryOptions } from '@apollo/client';
import { faPencil, faPlus, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Badge, Button } from 'react-daisyui';
import type { SuperadminPageQuery } from '~/apollo/generated/v3/graphql';
import { LocalDate } from '~/components/common/LocalDate';
import { Panel } from '~/components/common/Panel';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { CreateUserModal } from '../CreateUserModal';
import { AdminUserList } from './AdminUserList';
import UpdateCompanyModal from './UpdateCompanyModal';

type Props = {
  company: SuperadminPageQuery['companyList'][number];
  usersExpanded: boolean;
  refetchQueries: PureQueryOptions[];
};

export function AdminCompanyListItem({
  company,
  usersExpanded: allUsersExpanded,
  refetchQueries,
}: Props) {
  const [localUsersExpanded, setLocalUsersExpanded] = useState(false);
  const [projectsExpanded, setProjectsExpanded] = useState(false);

  const toggleUserListVisibility = () => setLocalUsersExpanded(val => !val);

  const toggleProjectListVisibility = () => setProjectsExpanded(val => !val);

  useEffect(() => {
    setLocalUsersExpanded(allUsersExpanded);
  }, [allUsersExpanded]);

  const activeUsers = company.user.filter(user => !user.accountLocked);

  const projects = R.pipe(
    () => company.studyAccess,
    sa => sa.map(sa => sa.project),
    p => p.filter((p): p is NonNullable<typeof p> => !!p),
    R.sortBy(R.prop('name')),
  )();

  return (
    <Panel>
      <Panel.Heading>
        <div className="float-right space-x-1">
          <CreateUserModal company={company} refetchQueries={refetchQueries}>
            {toggleCreateUserModal => (
              <Button
                type="button"
                color="primary"
                size="xs"
                className="gap-1"
                onClick={toggleCreateUserModal}
                disabled={company.isAadConfigured}
              >
                <FontAwesomeIcon icon={faPlus} /> Create User
              </Button>
            )}
          </CreateUserModal>
          <UpdateCompanyModal company={company}>
            {toggleUpdateModal => (
              <Button
                type="button"
                color="primary"
                size="xs"
                className="gap-1"
                onClick={toggleUpdateModal}
              >
                <FontAwesomeIcon icon={faPencil} /> Edit
              </Button>
            )}
          </UpdateCompanyModal>
        </div>
        <Panel.Title>
          <strong>{company.name}</strong>
        </Panel.Title>
        <div className="text-sm text-slate-600">
          {activeUsers.length} active users
          {company.lastActivity && (
            <>
              {' - '}
              <span className="text-slate-500">
                Last Activity <LocalDate date={company.lastActivity} />
              </span>
            </>
          )}
          {company.activityCount && (
            <>
              {' - '}
              <span className="text-slate-500">
                Activity Count {company.activityCount}
              </span>
            </>
          )}
        </div>
      </Panel.Heading>

      <Panel.Body>
        <CompanyDetails company={company} />

        <hr className="my-3" />

        <Button
          type="button"
          color="ghost"
          size="sm"
          onClick={toggleUserListVisibility}
          className="gap-1"
        >
          Users
          <Badge color="ghost">
            {company.user.filter(u => !u.accountLocked).length}
          </Badge>
          <ExpandedIcon expanded={localUsersExpanded} className="ml-2" />
        </Button>

        {localUsersExpanded && (
          <div className="mt-3 ">
            <AdminUserList userList={company.user} />
          </div>
        )}

        <hr className="my-3" />

        <Button
          type="button"
          onClick={toggleProjectListVisibility}
          color="ghost"
          size="sm"
          className="gap-1"
        >
          Projects <Badge color="ghost">{projects.length}</Badge>
          <ExpandedIcon expanded={projectsExpanded} />
        </Button>

        {projectsExpanded && (
          <div className="space-x-1">
            {projects.map(p => (
              <Badge key={p.id} color="ghost">
                {p.name}
              </Badge>
            ))}
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
}

function DetailRow({ label, value }: { label: ReactNode; value: ReactNode }) {
  return (
    <>
      <div className="text-sm text-muted">{label}</div>
      <div className="lg:col-span-11 text-base">{value}</div>
    </>
  );
}

function CompanyDetails({ company }: { company: Props['company'] }) {
  return (
    <div className="grid lg:grid-cols-12 gap-x-4">
      <DetailRow
        label="Registration"
        value={
          company.isAadConfigured ? (
            <>
              <FontAwesomeIcon icon={faUserTie} className="mr-1 text-info" />
              SSO Configured
            </>
          ) : (
            <>
              <EnabledIndicator
                value={company.allowRegister}
                className="mr-1"
              />
              {!company.allowRegister && <strong>NOT</strong>} Allowed
            </>
          )
        }
      />

      <DetailRow
        label="Valid domains"
        value={
          <div className="space-x-1">
            {company.mails.map(mail => (
              <Badge key={mail} color="ghost">
                {mail}
              </Badge>
            ))}
          </div>
        }
      />

      <DetailRow label="Safari level" value={company.safariLevel} />
    </div>
  );
}
