import type { PureQueryOptions } from '@apollo/client';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import {
  faBan,
  faMapMarkerAlt,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Card } from 'react-daisyui';
import type { UploadOutcropFieldPicsPageQuery } from '~/apollo/generated/v3/graphql';
import { CoordinatesText } from '~/components/upload/fieldPicture/BulkFieldPictureUploader/CoordinatesText';
import { DeleteFieldPicture } from '~/components/upload/supportingObject/fieldPicture/DeleteFieldPicture';
import { UpdateFieldPictureForm } from '~/components/upload/supportingObject/fieldPicture/UpdateFieldPictureForm';

type CardMode = 'display' | 'edit';

type FieldPic =
  UploadOutcropFieldPicsPageQuery['outcropList'][number]['fieldPictures'][number];

type SetCardModeFn = React.Dispatch<React.SetStateAction<CardMode>>;

type FieldPictureCardProps = {
  fieldPicture: FieldPic;
  refetchQueries: PureQueryOptions[];
};

export function FieldPictureCard({
  fieldPicture,
  refetchQueries,
}: FieldPictureCardProps) {
  const [cardMode, setCardMode] = useState<CardMode>('display');

  if (cardMode === 'edit') {
    return (
      <EditModeCard
        fieldPicture={fieldPicture}
        refetchQueries={refetchQueries}
        setCardMode={setCardMode}
      />
    );
  }

  return (
    <DisplayModeCard
      fieldPicture={fieldPicture}
      refetchQueries={refetchQueries}
      setCardMode={setCardMode}
    />
  );
}

type DisplayModeCardProps = {
  fieldPicture: FieldPic;
  setCardMode: SetCardModeFn;
  refetchQueries: PureQueryOptions[];
};

function DisplayModeCard({
  fieldPicture,
  setCardMode,
  refetchQueries,
}: DisplayModeCardProps) {
  return (
    <Card compact bordered>
      <Card.Body>
        <div className="flex justify-between items-start gap-6">
          <div
            className="aspect-square w-32 border border-slate-300 bg-cover bg-center"
            style={{
              backgroundImage: `url(${fieldPicture.file.signedUrl})`,
            }}
          ></div>

          <div className="grow space-y-2">
            <Card.Title>{fieldPicture.file.name}</Card.Title>
            {fieldPicture.author && (
              <div>
                <strong>Author: </strong>
                {fieldPicture.author}
              </div>
            )}
            {fieldPicture.description && (
              <div>
                <strong>Description: </strong>
                {fieldPicture.description}
              </div>
            )}
          </div>

          <div className="text-right space-y-2">
            <div>
              <button
                type="button"
                className="btn btn-xs btn-ghost gap-1"
                onClick={() => setCardMode('edit')}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>

              <DeleteFieldPicture
                fieldPictureId={fieldPicture.id}
                refetchQueries={refetchQueries}
              />
            </div>

            <div>
              <LocationAccuracyIndicator
                approximate={fieldPicture.locationApproximate}
              />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

type EditModeCardProps = {
  fieldPicture: FieldPic;
  setCardMode: SetCardModeFn;
  refetchQueries: PureQueryOptions[];
};

function EditModeCard({
  fieldPicture,
  setCardMode,
  refetchQueries,
}: EditModeCardProps) {
  return (
    <Card key={fieldPicture.id} compact bordered className="shadow-md">
      <Card.Body>
        <div className="flex justify-between">
          <div>
            <Card.Title>{fieldPicture.file.name}</Card.Title>
          </div>
          <div className="pb-2 pl-2 space-x-1">
            <button
              type="button"
              className="btn btn-xs btn-ghost gap-1"
              onClick={() => setCardMode('display')}
            >
              <FontAwesomeIcon icon={faBan} /> Cancel
            </button>

            <DeleteFieldPicture
              fieldPictureId={fieldPicture.id}
              refetchQueries={refetchQueries}
            />
          </div>
        </div>

        <div className="flex justify-between items-start gap-6">
          <div className="space-y-6 text-center">
            <img
              src={fieldPicture.file.signedUrl}
              className="w-48 h-auto shadow-md border border-slate-300"
              alt={fieldPicture.file.name ?? 'Field picture image'}
            />
          </div>

          <div className="grow space-y-4">
            {fieldPicture.location && (
              <CoordinatesText
                exifLocation={fieldPicture.location}
                location={fieldPicture.location}
              />
            )}

            <div>
              <UpdateFieldPictureForm
                fieldPicture={fieldPicture}
                onUpdateSuccess={() => setCardMode('display')}
              />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

function LocationAccuracyIndicator({ approximate }: { approximate: boolean }) {
  if (approximate) {
    return (
      <span className="text-warning font-bold">
        <FontAwesomeIcon icon={faCircleDot} /> Approximate
      </span>
    );
  } else {
    return (
      <span className="text-success font-bold">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> Precise
      </span>
    );
  }
}
