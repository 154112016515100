import type { PureQueryOptions } from '@apollo/client';
import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import type {
  CreateAeMutation,
  CreateAeMutationVariables,
  OutcropPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { Modal } from '~/components/common/Modal';
import { AEFormFields } from '~/components/upload/architecturalElement/AEFormFields';
import { useModalState } from '~/hooks/modal';
import type { AEFormValues } from '~/utils/modules/architecturalElement';
import {
  initialAEFormValues,
  toAEInputV3,
  validationSchema,
} from '~/utils/modules/architecturalElement';

const CREATE_AE_MUTATION = gql`
  mutation CreateAE(
    $studyId: Int!
    $architecturalElement: ArchitecturalElementInput!
  ) {
    createArchitecturalElement(
      studyId: $studyId
      architecturalElement: $architecturalElement
    ) {
      id
    }
  }
`;

type Props = {
  studyId: number;
  outcrops: OutcropPartsFragment[];
  refetchQueries: PureQueryOptions[];
  children(showModal: (e: React.MouseEvent) => void): JSX.Element;
};

export function CreateAEModal({
  studyId,
  outcrops,
  refetchQueries,
  children,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [createAE, { loading, error }] = useMutation<
    CreateAeMutation,
    CreateAeMutationVariables
  >(CREATE_AE_MUTATION, { refetchQueries });

  async function handleSubmit(values: AEFormValues) {
    try {
      await createAE({
        variables: {
          studyId,
          architecturalElement: toAEInputV3(values),
        },
      });

      hideModal();
      toast.success('Architectural Element created successfully.');
    } catch (err) {
      console.log('Error creating AE', err);
      toast.error('There was a problem creating the Architectural Element.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal size="lg" open={show} onHide={hideModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialAEFormValues()}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body heading="Create Architectural Element">
              <AEFormFields action="create" outcrops={outcrops} />
              <FormErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer className="gap-2">
              <Button
                type="button"
                color="ghost"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </Button>

              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
}

export default CreateAEModal;
