import { gql } from '~/apollo/client-v3';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import * as fragments from '~/apollo/fragments';
import type {
  UpdateBookmarkMutation,
  UpdateBookmarkMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { FormErrors } from '~/components/common/FormErrors';
import { FormikField } from '~/components/common/FormikField';
import type { Bookmark, BookmarkFormValues } from '~/utils/modules/bookmark';
import {
  bookmarkValidationSchema,
  initialBookmark,
} from '~/utils/modules/bookmark';

const UPDATE_BOOKMARK = gql`
  mutation UpdateBookmark($bookmarkId: Int!, $note: String) {
    updateBookmark(bookmarkId: $bookmarkId, note: $note) {
      ...bookmarkParts
      company {
        ...companyParts
      }
      user {
        ...publicUserParts
      }
    }
  }

  ${fragments.bookmarkParts}
  ${fragments.companyParts}
  ${fragments.publicUserParts}
`;

type Props = {
  bookmark: Bookmark;
  onUpdateSuccess?: () => void;
};

export function UpdateBookmark({ bookmark, onUpdateSuccess }: Props) {
  const [updateBookmark, { loading, error }] = useMutation<
    UpdateBookmarkMutation,
    UpdateBookmarkMutationVariables
  >(UPDATE_BOOKMARK, {});

  async function handleSubmit(
    values: BookmarkFormValues,
    helpers: FormikHelpers<BookmarkFormValues>,
  ) {
    const trimmedVal = values.note.trim();
    const note = trimmedVal || null;

    try {
      const res = await updateBookmark({
        variables: { bookmarkId: bookmark.id, note },
      });
      const savedBookmark = res.data?.updateBookmark;
      if (!savedBookmark) throw new Error('Error parsing save response');
      helpers.resetForm({ values: initialBookmark(savedBookmark) });
      toast.success('Bookmark saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving bookmark', err);
      toast.error('There was a problem saving the bookmark. Please try again.');
    }
  }

  return (
    <Formik
      initialValues={initialBookmark(bookmark)}
      onSubmit={handleSubmit}
      validationSchema={bookmarkValidationSchema}
    >
      <Form className="space-y-2">
        <Field
          name="note"
          component={FormikField}
          type="textarea"
          rows={4}
          placeholder="Note (optional)"
        />

        <FormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
