import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router';
import React, { Fragment } from 'react';
import type { AboutMenuItem as MenuItem } from '~/components/about/SideNavigation';
import { createMenuStructure } from '~/components/about/SideNavigation';
import { useAuth } from '~/contexts/auth';
import { aboutRoute } from '~/paths';

type AboutMenuItemProps = {
  to: string;
  children: React.ReactNode;
};
function AboutMenuItem({ to, children }: AboutMenuItemProps) {
  const baseClasses = `block px-4 py-2 text-sm text-zinc-700`;
  const activeClasses = `${baseClasses} bg-zinc-100`;

  return (
    <Menu.Item>
      {({ active }) => (
        <Link to={to} className={active ? activeClasses : baseClasses}>
          {children}
        </Link>
      )}
    </Menu.Item>
  );
}

type AboutLinkProps = {
  item: MenuItem;
};
const AboutLink: React.FC<AboutLinkProps> = ({ item }) => (
  <>
    <AboutMenuItem to={aboutRoute(item.pageName)}>{item.title}</AboutMenuItem>

    {item.subMenu?.map(subItem => (
      <div key={subItem.pageName} className="pl-3">
        <AboutLink item={subItem} />
      </div>
    ))}
  </>
);

export function AboutItems() {
  const { isAuthenticated } = useAuth();
  return (
    <>
      {createMenuStructure(isAuthenticated).map(item => (
        <AboutLink key={item.pageName} item={item} />
      ))}
    </>
  );
}

export function HamburgerMenu() {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          id="hamburgerMenu"
          className="px-3 py-3 rounded-md text-base text-zinc-300 hover:bg-zinc-500 hover:text-white"
        >
          <span className="sr-only">Open hamburger menu</span>
          <FontAwesomeIcon icon={faBars} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <AboutItems />
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
