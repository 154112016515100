import { useMutation } from '@apollo/client';
import {
  faFileDownload,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import type { FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { gql } from '~/apollo/client-v3';
import { measurementParts } from '~/apollo/fragments';
import type {
  ArchitecturalMeasurementBulkPreprocessResult,
  BulkCreateMeasurementsMutation,
  BulkCreateMeasurementsMutationVariables,
  BulkUploadArchitecturalMeasurementInput,
  BulkUploadError,
  BulkValidateMeasurementsMutation,
  BulkValidateMeasurementsMutationVariables,
} from '~/apollo/generated/v3/graphql';
import { Confirm } from '~/components/common/Confirm';
import { DropzoneContainer } from '~/components/common/DropzoneContainer';
import { Heading } from '~/components/common/Heading';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { uploadStudyUpdateAEsRoute } from '~/paths';
import { useStudyAEsOutletContext } from '~/routes/upload/model/study/$studyId/architectural-elements';
import { saveAsCSV } from '~/utils/export';
import type { MeasurementExportFields } from '~/utils/modules/architecturalMeasurement';
import {
  bulkUploadMeasurementFields,
  csvRowToBulkMeasurementInput,
  formatAsCSV,
  formatProcessedMeasurementForBulkUpload,
} from '~/utils/modules/architecturalMeasurement';
import { ucwords } from '~/utils/text';

const BULK_VALIDATE_MEASUREMENTS = gql`
  mutation BulkValidateMeasurements(
    $studyId: Int!
    $measurements: [BulkUploadArchitecturalMeasurementInput!]!
  ) {
    bulkValidateArchitecturalMeasurement(
      studyId: $studyId
      measurements: $measurements
    ) {
      architecturalMeasurement {
        architecturalElementName
        completeness
        dataType
        quality
        value
      }
      errors {
        field
        errorMessage
      }
    }
  }
`;

const BULK_CREATE_MEASUREMENTS = gql`
  mutation BulkCreateMeasurements(
    $studyId: Int!
    $measurements: [BulkUploadArchitecturalMeasurementInput!]!
  ) {
    bulkCreateArchitecturalMeasurement(
      studyId: $studyId
      measurements: $measurements
    ) {
      ...measurementParts
    }
  }

  ${measurementParts}
`;

const hasFieldErrors = (fieldName: string, errors: BulkUploadError[]) => {
  return !!errors.find(err => err.field === fieldName);
};

const formattingGuidelines = (
  <ul className="list-disc">
    <li>
      The file format must be <b>.csv</b>
    </li>
    <li>
      The column separator must be a semicolon{' '}
      <b
        style={{
          backgroundColor: '#dddddd',
          padding: '0 5px 0 5px',
          fontFamily: 'monospace',
        }}
      >
        ;
      </b>
      .
    </li>
    <li>The first row will be ignored.</li>
    <li>
      The columns must be in the following order:
      <ol className="list-decimal">
        {bulkUploadMeasurementFields.map(field => (
          <li key={field}>
            {ucwords(field)}
            {field === 'architecturalElementName' && (
              <div className="text-muted">
                <em>('name' field on architectural element)</em>
              </div>
            )}
          </li>
        ))}
      </ol>
    </li>
  </ul>
);

const exampleMeasurements: MeasurementExportFields[] = [
  {
    id: -1,
    outcropArchitecturalElementId: -1,
    outcropArchitecturalElement: {
      name: 'Test AE 1',
    },
    completeness: 'complete',
    dataType: 'horizontal_width',
    quality: 'level_1',
    value: 100,
  },
  {
    id: -1,
    outcropArchitecturalElementId: -1,
    outcropArchitecturalElement: {
      name: 'Test AE 1',
    },
    completeness: 'complete',
    dataType: 'horizontal_length',
    quality: 'level_1',
    value: 123,
  },
  {
    id: -1,
    outcropArchitecturalElementId: -1,
    outcropArchitecturalElement: {
      name: 'Test AE 2',
    },
    completeness: 'complete',
    dataType: 'horizontal_width',
    quality: 'level_3',
    value: 456,
  },
  {
    id: -1,
    outcropArchitecturalElementId: -1,
    outcropArchitecturalElement: {
      name: 'Test AE 2',
    },
    completeness: 'complete',
    dataType: 'horizontal_length',
    quality: 'level_3',
    value: 4567,
  },
  {
    id: -1,
    outcropArchitecturalElementId: -1,
    outcropArchitecturalElement: {
      name: 'Test AE 2',
    },
    completeness: 'complete',
    dataType: 'thickness',
    quality: 'level_3',
    value: 789,
  },
];
const exampleMeasurementsCSV = formatAsCSV(exampleMeasurements);

export default function BulkMeasurementImportRoute() {
  const { study, refetchQueries } = useStudyAEsOutletContext();
  const studyId = study.id;

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [validationResult, setValidationResult] =
    useState<ArchitecturalMeasurementBulkPreprocessResult[]>();

  const [bulkValidateMeasurements, { loading: loadingBulkValidate }] =
    useMutation<
      BulkValidateMeasurementsMutation,
      BulkValidateMeasurementsMutationVariables
    >(BULK_VALIDATE_MEASUREMENTS, {});

  const [
    bulkCreateMeasurements,
    { loading: loadingBulkCreate, error: errorBulkCreate },
  ] = useMutation<
    BulkCreateMeasurementsMutation,
    BulkCreateMeasurementsMutationVariables
  >(BULK_CREATE_MEASUREMENTS, { refetchQueries });

  const handleValidate = async (
    inputs: BulkUploadArchitecturalMeasurementInput[],
  ) => {
    if (!inputs)
      throw new Error('No measurements could be parsed for validation.');

    try {
      const res = await bulkValidateMeasurements({
        variables: { studyId, measurements: inputs },
      });

      setValidationResult(res.data?.bulkValidateArchitecturalMeasurement);

      const numMeasurements =
        res.data?.bulkValidateArchitecturalMeasurement.length ?? 0;
      const numErrors =
        res.data?.bulkValidateArchitecturalMeasurement.filter(
          result => result.errors.length > 0,
        ).length ?? 0;

      if (numMeasurements > 0 && numErrors === 0) {
        setStep(3);
      }
    } catch (err) {
      toast.error(
        'There was a problem running the validation, check the console for raw output.',
      );
      console.log('Error validating AE input:', err);
    }
  };

  const handleBulkCreate = async () => {
    if (!validationResult?.length) {
      throw new Error('Nothing to create.');
    }
    try {
      const measurements = validationResult
        .map(r => r.architecturalMeasurement)
        .map(formatProcessedMeasurementForBulkUpload);

      const res = await bulkCreateMeasurements({
        variables: { studyId, measurements },
      });

      const createdMeasurements = res.data?.bulkCreateArchitecturalMeasurement;
      if (createdMeasurements?.length) {
        toast.success(
          `Successfully created ${createdMeasurements.length} measurements.`,
        );
      }
      navigate(uploadStudyUpdateAEsRoute(studyId));
    } catch (err) {
      toast.error('There was a problem saving one or more measurements.');
      console.log('Error bulk creating measurements', err);
    }
  };

  const handleDrop = async (files: File[]) => {
    setValidationResult(undefined);

    const contents = await files[0].text();

    if (!contents) {
      toast.error(
        `Couldn't read file or there was an error parsing its contents.`,
      );
      return;
    }

    const content = Papa.parse(contents, {
      // No header setting - we'll just ignore the first row and transform the data manually
      header: false,
      skipEmptyLines: true,
    });
    console.log('Raw CSV input:', content.data);

    const measurementInputs: BulkUploadArchitecturalMeasurementInput[] =
      content.data
        .slice(1)
        .map(item => csvRowToBulkMeasurementInput(item as string[]));
    console.log('Parsed input:', measurementInputs);

    handleValidate(measurementInputs);
    setStep(2);
  };

  const downloadExampleCSV = () => {
    saveAsCSV(exampleMeasurementsCSV, 'Example Measurements Import.csv');
  };

  function handleDropRejected(rejects: FileRejection[]) {
    const names = rejects.map(r => r.file.name).join('\n');
    toast.error(`The following files weren't accepted:\n${names}`);
  }

  const numMeasurements = validationResult?.length ?? 0;
  const numErrors =
    validationResult?.filter(result => result.errors.length > 0).length ?? 0;

  return (
    <>
      <PageHeading>Import Architectural Measurements</PageHeading>

      <div className="space-y-6">
        <Panel variant={step === 1 ? 'primary' : 'default'}>
          <Panel.Heading>
            <Panel.Title>
              <strong>Step 1</strong>: Select CSV file
            </Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="float-right">
              <Button
                type="button"
                color="primary"
                size="sm"
                className="gap-1"
                onClick={downloadExampleCSV}
              >
                <FontAwesomeIcon icon={faFileDownload} /> Download example file
              </Button>
            </div>

            <div className="space-y-2">
              <p>
                Select a CSV file that contains the Architectural Elements you
                wish to upload.
              </p>

              <Confirm
                onConfirm={() => {}}
                title="Formatting Guidelines"
                text={formattingGuidelines}
                submitText="Ok"
                showCancel={false}
              >
                {showGuidelines => (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={showGuidelines}
                    className="gap-1"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    File formatting guidelines
                  </Button>
                )}
              </Confirm>

              <Dropzone
                onDrop={handleDrop}
                multiple={false}
                maxSize={100_000_000}
                onDropRejected={handleDropRejected}
              >
                {({ getRootProps, getInputProps }) => (
                  <DropzoneContainer {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop a CSV file here, or click to browse.</p>
                  </DropzoneContainer>
                )}
              </Dropzone>
            </div>
          </Panel.Body>
        </Panel>

        {step >= 2 && (
          <Panel variant={step === 2 ? 'primary' : 'default'}>
            <Panel.Heading>
              <Panel.Title>
                <strong>Step 2</strong>: Verify data
              </Panel.Title>
            </Panel.Heading>

            <Panel.Body>
              <p>
                Verify that the CSV file was processed correctly and correct any
                errors.
              </p>

              <SpinnerPlaceholder show={loadingBulkValidate} />

              <div
                style={{
                  width: '100%',
                  maxHeight: '600px',
                  overflow: 'scroll',
                  position: 'relative',
                }}
              >
                <table className="table table-compact table-zebra w-full">
                  <thead>
                    <tr>
                      <th
                        style={{
                          position: 'sticky',
                          top: 0,
                          background: 'rgba(255, 255, 255, 0.95)',
                        }}
                      />
                      {bulkUploadMeasurementFields.map(field => (
                        <th
                          key={field}
                          style={{
                            position: 'sticky',
                            top: 0,
                            background: 'rgba(255, 255, 255, 0.95)',
                          }}
                        >
                          {ucwords(field)}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {validationResult?.map((result, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <th>{i + 2}</th>
                          {bulkUploadMeasurementFields.map(field => (
                            <td
                              key={`${field}-${i}`}
                              style={
                                hasFieldErrors(field, result.errors)
                                  ? {
                                      border: '2px solid red',
                                      backgroundColor: 'hsl(0, 100%, 90%)',
                                    }
                                  : {}
                              }
                            >
                              {String(result.architecturalMeasurement[field])}
                            </td>
                          ))}
                        </tr>
                        {result.errors.length > 0 && (
                          <tr>
                            <td
                              colSpan={bulkUploadMeasurementFields.length + 1}
                              className="text-danger"
                              style={{
                                border: '2px solid red',
                                backgroundColor: 'hsl(0, 100%, 90%)',
                              }}
                            >
                              <strong>Row {i + 2}</strong> contained the
                              following errors:
                              <ul style={{ marginBottom: 0 }}>
                                {result.errors.map((err, j) => (
                                  <li key={`${err.field}-${j}`}>
                                    {err.errorMessage}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="text-center" style={{ marginTop: '10px' }}>
                <b>{numMeasurements}</b> total measurements to be uploaded
                {numErrors > 0 && (
                  <div>
                    <span className="text-danger">{numErrors}</span> rows
                    containing errors.
                    <p>Please correct these errors and re-upload the file.</p>
                  </div>
                )}
              </div>
            </Panel.Body>
          </Panel>
        )}

        {step >= 3 && (
          <Panel variant={step === 3 ? 'primary' : 'default'}>
            <Panel.Heading>
              <Panel.Title>Step 3: Upload bulk data</Panel.Title>
            </Panel.Heading>

            <Panel.Body className="space-y-4">
              <p>
                The measurements have been validated and are ready to be saved.
              </p>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBulkCreate}
                  disabled={loadingBulkCreate}
                >
                  Save Measurements
                </button>

                <SpinnerPlaceholder show={loadingBulkCreate} />
              </div>

              {errorBulkCreate && (
                <div className="alert alert-error mt-4">
                  <div>
                    <Heading level={3}>Error</Heading>
                    <p>There was a problem creating a measurement.</p>
                    <ul>
                      {errorBulkCreate.graphQLErrors.map((err, i) => (
                        <li key={i}>{err.message}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </Panel.Body>
          </Panel>
        )}
      </div>
    </>
  );
}
