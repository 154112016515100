import { gql } from '~/apollo/client-v3';
import { useQuery } from '@apollo/client';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Papa from 'papaparse';
import * as R from 'ramda';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import * as fragments from '~/apollo/fragments';
import type { ExportOutcropsPageQuery } from '~/apollo/generated/v3/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import { uploadOutcropUpdateRoute } from '~/paths';
import { saveAsCSV } from '~/utils/export';
import { toISODateString } from '~/utils/text';

const EXPORT_OUTCROPS_PAGE = gql`
  query ExportOutcropsPage {
    outcropList {
      ...outcropParts
      insertedAt
      updatedAt
      thumbnail {
        id
      }
      region {
        name
        location {
          country
        }
      }
      basin {
        name
        description
        basinType
        climate
        terrestrialMarine
        waterDepth
        catchmentArea
      }
      sourceArea {
        name
        climate
        description
        geography
        structure
        distanceToSourceArea
        distanceToShoreline
        distanceToShelfEdge
      }
      qualityParameters {
        ...outcropQualityParametersParts
      }
      keyParameters {
        ...keyParametersParts
      }
      startAge {
        ...lithostratAgeParts
      }
      georeferences {
        id
        dataType
      }
      virtualOutcropModels {
        id
      }
      studies {
        id
      }
      paleoMaps {
        id
      }
      pictures {
        id
      }
      crossSections {
        id
      }
      facies {
        id
      }
      sedimentaryLogs {
        id
      }
      wellLogs {
        id
      }
      reservoirModels {
        id
      }
      production {
        id
      }
      trainingImages {
        id
      }
      variograms {
        id
      }
      gigaPans {
        id
      }
      lithostratOutcropLinks {
        id
      }
      center {
        lat
        lng
      }
      outline {
        id
      }
    }
  }

  ${fragments.outcropParts}
  ${fragments.outcropQualityParametersParts}
  ${fragments.keyParametersParts}
  ${fragments.lithostratAgeParts}
`;

function formatDate(date?: Date | string | null | undefined) {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
}

type OutcropResult = ExportOutcropsPageQuery['outcropList'][number];

const vomCount = (outcrop: OutcropResult) =>
  (outcrop.virtualOutcropModels ?? []).length;

const hasPaleoMaps = (outcrop: OutcropResult) => outcrop.paleoMaps.length > 0;

const kps = (outcrop: OutcropResult) => outcrop.keyParameters;

const hasTextValue = (value?: string | null) => !!value && value.length > 0;

const boolToText = (val: boolean) => (val ? 'yes' : 'no');

class Row {
  ID: number;
  Name: string;
  Region: string;
  Country: string;
  'Outcrop Type': string;
  'Geology Type': string;
  'Analogue Category': string;
  'Description Text': string; // (yes/no)
  'Lithology and Depositional Environment': string; // (yes/no)
  'Litho- and Sequence Stratigraphy': string; // (yes/no)
  'Analogues and Notable Aspects': string; // (yes/no)
  'Location and Accessibility': string; // (yes/no)
  'Key References': string; // (yes/no)
  'Introduction': string; // (yes/no)
  'Key Words': string; // (yes/no)
  'Diagenesis and Petrophysical Properties': string; // (yes/no)
  'Structural Geology': string; // (yes/no)
  'Ready to Approve': string; // (yes/no)
  Approved: string; // (yes/no)
  'QC Completed': string; // (yes/no)
  'Basin Name'?: string;
  'Basin Description'?: string; // (yes/no)
  'Basin Type'?: string;
  Climate?: string;
  'Terrestrial/Marine'?: string;
  'Water Depth'?: string;
  'Catchment Area'?: number | null;
  'Source Area Name'?: string | null;
  'Source Area Description'?: string; // (yes/no)
  Geography?: string | null;
  Structure?: string | null;
  'Source Area Climate'?: string | null;
  'Distance to SA'?: number | null;
  'Distance to Shoreline'?: number | null;
  'Disctane to Shelf Edge'?: number | null;
  'Depositional Dip Direction'?: string | null;
  'Outcrop Orientation': string;
  'Outcrop 3D control'?: string;
  'Exposure Quality'?: string;
  'Structural Complexity'?: string;
  'Dataset Scale'?: string;
  GDE: string;
  DE: string;
  SE: string;
  AE: string; // (yes/no)
  'Virtual Outcrops': number;
  Palaeogeography: string; // (yes/no)
  Georeferences: string; // (yes/no)
  'Geological Age'?: string; // (system value)
  Studies: number; // (count)
  Lithostratigraphy: string; // (yes/no)
  Thumbnail: string; // (yes/no)
  Pictures: number; // (count)
  Facies: number; // (count)
  'Cross Sections': number; // (count)
  'Sedimentary Logs': number; // (count)
  'Well logs': number; // (count)
  Production: number; // (count)
  'Reservoir Models': number; // (count)
  'Training Images': number; // (count)
  Variograms: number; // (count)
  Panoramas: number; // (count)
  'Has Center': string; // (yes/no)
  'Has Outline': string; // (yes/no)
  'Dominant geology type': string;
  'Systems tract': string;
  'Shoreline tractory': string;
  'Dune shape': string;
  'Channel morphology': string;
  'River profile location': string;
  'Dominant lithology': string;
  'Water temperature': string;
  'Net to gross': string;
  'Diagenetic setting': string;
  'Diagenetic process': string;
  'Diagenetic geometry': string;
  'Tectonic setting': string;
  'Syn sedimentary deformation': string;
  'Fault rocks membranes': string;
  'Interaction network': string;
  'Reactivation1st phase': string;
  'Reactivation2nd phase': string;
  'Symmetry geometry': string;
  'Multiple folds': string;
  'Secondary structures': string;
  'Lateral aggregation': string;
  'Inserted At': string;
  'Updated At': string;

  constructor(oc: OutcropResult) {
    this['ID'] = oc.id;
    this['Name'] = oc.name;
    this['Region'] = oc.region.name ?? '';
    this['Country'] = oc.region?.location.country ?? '';
    this['Outcrop Type'] = oc.outcropType ?? '';
    this['Geology Type'] = oc.geologyType.join(', ');
    this['Analogue Category'] = oc.outcropCategory ?? '';
    this['Description Text'] = boolToText(hasTextValue(oc.description));
    this['Lithology and Depositional Environment'] = boolToText(
      hasTextValue(oc.environments),
    );
    this['Litho- and Sequence Stratigraphy'] = boolToText(
      hasTextValue(oc.stratigraphy),
    );
    this['Analogues and Notable Aspects'] = boolToText(
      hasTextValue(oc.notablesAndAnalogues),
    );
    this['Location and Accessibility'] = boolToText(hasTextValue(oc.location));
    this['Key References'] = boolToText(hasTextValue(oc.safari));

    this['Introduction'] = boolToText(hasTextValue(oc.introduction));
    this['Key Words'] = boolToText(hasTextValue(oc.keyWords));
    this['Diagenesis and Petrophysical Properties'] = boolToText(
      hasTextValue(oc.diagenesisAndPetrophysicalProperties),
    );
    this['Structural Geology'] = boolToText(hasTextValue(oc.structuralGeology));
    this['Ready to Approve'] = boolToText(oc.readyForApproval);
    this['Approved'] = boolToText(oc.approved);
    this['QC Completed'] = boolToText(oc.qcCompleted);
    this['Basin Name'] = oc.basin?.name ?? '';
    this['Basin Description'] = boolToText(hasTextValue(oc.basin?.description));
    this['Basin Type'] = oc.basin?.basinType ?? '';
    this['Climate'] = oc.basin?.climate ?? '';
    this['Terrestrial/Marine'] = oc.basin?.terrestrialMarine ?? '';
    this['Water Depth'] = oc.basin?.waterDepth ?? '';
    this['Catchment Area'] = oc.basin?.catchmentArea;
    this['Source Area Name'] = oc.sourceArea?.name;
    this['Source Area Description'] = boolToText(
      hasTextValue(oc.sourceArea?.description),
    );
    this['Geography'] = oc.sourceArea?.geography;
    this['Structure'] = oc.sourceArea?.structure;
    this['Source Area Climate'] = oc.sourceArea?.climate;
    this['Distance to SA'] = oc.sourceArea?.distanceToSourceArea;
    this['Distance to Shoreline'] = oc.sourceArea?.distanceToShoreline;
    this['Disctane to Shelf Edge'] = oc.sourceArea?.distanceToShelfEdge;
    this['Depositional Dip Direction'] = oc.depositionalDipDirection;
    this['Outcrop Orientation'] = oc.orientation ?? '';
    this['Outcrop 3D control'] = oc.qualityParameters?.outcrop_3d_control ?? '';
    this['Exposure Quality'] = oc.qualityParameters?.exposureQuality ?? '';
    this['Structural Complexity'] =
      oc.qualityParameters?.structuralComplexity ?? '';
    this['Dataset Scale'] = oc.qualityParameters?.datasetScale ?? '';
    this['GDE'] = kps(oc)
      .map(kp => kp.grossDepositionalEnvironment)
      .join(', ');
    this['DE'] = kps(oc)
      .map(kp => kp.depositionalEnvironment)
      .join(', ');
    this['SE'] = kps(oc)
      .map(kp => kp.depositionalSubEnvironment)
      .join(', ');
    this['AE'] = boolToText(
      kps(oc).map(kp => kp.architecturalElement).length > 0,
    );
    this['Virtual Outcrops'] = vomCount(oc);
    this['Palaeogeography'] = boolToText(hasPaleoMaps(oc));
    this['Georeferences'] = boolToText(
      !!oc.georeferences && oc.georeferences.length > 0,
    );
    this['Geological Age'] = oc.startAge?.system ?? '';
    this['Studies'] = oc.studies?.length || 0;
    this['Lithostratigraphy'] = boolToText(
      !!oc.lithostratOutcropLinks && oc.lithostratOutcropLinks.length > 0,
    );
    this['Thumbnail'] = boolToText(!!oc.thumbnail?.id);
    this['Pictures'] = oc.pictures?.length || 0;
    this['Facies'] = oc.facies?.length || 0;
    this['Cross Sections'] = oc.crossSections?.length || 0;
    this['Sedimentary Logs'] = oc.sedimentaryLogs?.length || 0;
    this['Well logs'] = oc.wellLogs?.length || 0;
    this['Production'] = oc.production?.length || 0;
    this['Reservoir Models'] = oc.reservoirModels?.length || 0;
    this['Training Images'] = oc.trainingImages?.length || 0;
    this['Variograms'] = oc.variograms?.length || 0;
    this['Panoramas'] = oc.gigaPans?.length || 0;
    this['Has Center'] = boolToText(oc.center !== null);
    this['Has Outline'] = boolToText(!!oc.outline);

    // Additional fields
    this['Dominant geology type'] = oc.dominantGeologyType ?? '';
    this['Systems tract'] = oc.systemsTract?.join(', ') ?? '';
    this['Shoreline tractory'] = oc.shorelineTractory?.join(', ') ?? '';
    this['Dune shape'] = oc.duneShape?.join(', ') ?? '';
    this['Channel morphology'] = oc.channelMorphology?.join(', ') ?? '';
    this['River profile location'] = oc.riverProfileLocation?.join(', ') ?? '';
    this['Dominant lithology'] = oc.dominantLithology?.join(', ') ?? '';
    this['Water temperature'] = oc.waterTemperature?.join(', ') ?? '';
    this['Net to gross'] = oc.netToGross ?? '';
    this['Diagenetic setting'] = oc.diageneticSetting?.join(', ') ?? '';
    this['Diagenetic process'] = oc.diageneticProcess?.join(', ') ?? '';
    this['Diagenetic geometry'] = oc.diageneticGeometry?.join(', ') ?? '';
    this['Tectonic setting'] = oc.tectonicSetting?.join(', ') ?? '';
    this['Syn sedimentary deformation'] =
      oc.synSedimentaryDeformation?.join(', ') ?? '';
    this['Fault rocks membranes'] = oc.faultRocksMembranes?.join(', ') ?? '';
    this['Interaction network'] = oc.interactionNetwork?.join(', ') ?? '';
    this['Reactivation1st phase'] = oc.reactivation1stPhase?.join(', ') ?? '';
    this['Reactivation2nd phase'] = oc.reactivation2ndPhase?.join(', ') ?? '';
    this['Symmetry geometry'] = oc.symmetryGeometry?.join(', ') ?? '';
    this['Multiple folds'] = oc.multipleFolds?.join(', ') ?? '';
    this['Secondary structures'] = oc.secondaryStructures?.join(', ') ?? '';
    this['Lateral aggregation'] = oc.lateralAggregation?.join(', ') ?? '';
    this['Inserted At'] = formatDate(oc.insertedAt) ?? '';
    this['Updated At'] = formatDate(oc.updatedAt) ?? '';
  }
}

export default function ExportOutcropsRoute() {
  useBreadcrumb('routes/upload/export/outcrops', 'Outcrop Master Sheet');

  const { data, loading } = useQuery<ExportOutcropsPageQuery>(
    EXPORT_OUTCROPS_PAGE,
    {},
  );

  const outcrops = R.sortBy(R.prop('name'), data?.outcropList ?? []);

  const rows = outcrops.map(oc => new Row(oc));

  const fields = rows.length ? (Object.keys(rows[0]) as Array<keyof Row>) : [];

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    rows,
    'ID',
    'Name',
    'exportOutcropsList',
  );

  function handleSave() {
    const data = Papa.unparse(rows);
    const date = toISODateString(new Date());
    saveAsCSV(data, `Outcrops Export ${date}.csv`);
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <>
      <div className="container-fluid">
        <div className="float-right">
          <Button
            type="button"
            variant="link"
            onClick={handleSave}
            className="gap-1"
          >
            <FontAwesomeIcon icon={faFileCsv} /> Save as CSV
          </Button>
        </div>

        <PageHeading>Outcrop Master Sheet</PageHeading>
        <p>Master list of all outcrops</p>
      </div>

      <div className="">
        <table className="table table-compact text-sm">
          <thead className="sticky top-0 bg-sky-100">
            <tr>
              {fields.map(field => (
                <th key={field}>
                  <SortTrigger colName={field} sortIndicatorProps={siProps}>
                    <span style={{ fontSize: '9pt' }}>{field}</span>
                  </SortTrigger>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {items.map(item => (
              <tr key={item.ID}>
                {fields.slice(0, 1).map(fieldName => (
                  <td key={fieldName} className="sticky left-0 bg-sky-100">
                    <Link
                      to={uploadOutcropUpdateRoute(item.ID)}
                      target="_blank"
                      className="link"
                    >
                      {item[fieldName]}
                    </Link>
                  </td>
                ))}
                {fields.slice(1).map(fieldName => (
                  <td key={fieldName} className="border border-slate-100">
                    {item[fieldName]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
