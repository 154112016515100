import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type {
  UploadMemberListRouteQuery,
  UploadMemberListRouteQueryVariables,
} from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { CreateMemberModal } from '~/components/upload/lithostrat/member/CreateMemberModal';
import { useSortFilter } from '~/hooks/data';
import { uploadLithostratMemberUpdateRoute } from '~/paths';
import { truncateText } from '~/utils/common';
import { mostSpecificAge } from '~/utils/modules/lithostratAge';

export const UPLOAD_MEMBER_LIST = graphql(`
  query UploadMemberListRoute($formationId: Int) {
    lithostratMemberList(formationId: $formationId) {
      ...lithostratMemberParts
      lithostratType {
        ...lithostratTypeParts
      }
      startAge {
        ...lithostratAgeParts
      }
      wikiPage {
        ...lithostratWikiPageParts
      }
      formation {
        ...lithostratFormationParts
      }
    }
  }
`);

type Member = UploadMemberListRouteQuery['lithostratMemberList'][number];
type SortableMember = Member & {
  wikiPublished: boolean;
  age: string | null;
};

type Props = {
  formationId?: number;
};

export function MemberList({ formationId }: Props) {
  const variables: UploadMemberListRouteQueryVariables = {};
  if (formationId) variables.formationId = formationId;

  const { data, loading } = useQuery(UPLOAD_MEMBER_LIST, { variables });

  const members: SortableMember[] =
    data?.lithostratMemberList.map(m => ({
      ...m,
      wikiPublished: m.wikiPage?.published || false,
      age: mostSpecificAge(m.startAge),
    })) ?? [];

  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps: fsProps,
  } = useSortFilter(members, 'name', 'name', 'uploadMemberList');

  if (loading) return <SpinnerPlaceholder />;

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>
          Members
          <small className="text-muted" style={{ marginLeft: '10px' }}>
            (<strong>{items.length}</strong> results displayed)
          </small>
        </Panel.Title>

        <CreateMemberModal formationId={formationId}>
          {showCreateModal => (
            <Button
              type="button"
              color="primary"
              size="xs"
              onClick={showCreateModal}
              className="gap-1"
            >
              <FontAwesomeIcon icon={faPlus} /> Create Member
            </Button>
          )}
        </CreateMemberModal>
      </Panel.Heading>
      <Panel.Body>
        <FilterSearch {...fsProps} />

        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="id" sortIndicatorProps={siProps}>
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger
                  colName="lithostratType.name"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Type
                </SortTrigger>
              </th>
              <th>Description</th>
              <th>
                <SortTrigger
                  colName="age"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Start Age
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="lithostratOrder"
                  sortIndicatorProps={siProps}
                >
                  Order
                </SortTrigger>
              </th>
              <th className="text-center w-1/12">
                <SortTrigger
                  colName="wikiPublished"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Published
                </SortTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map(member => (
              <tr key={member.id}>
                <td>{member.id}</td>
                <td>
                  <Link
                    to={uploadLithostratMemberUpdateRoute(member.id)}
                    className="link"
                  >
                    {member.name}
                  </Link>
                </td>
                <td>{member.lithostratType?.name}</td>
                <td>{truncateText(member.description, 100)}</td>
                <td>{member.age}</td>
                <td className="text-center">{member.lithostratOrder}</td>
                <td className="text-center">
                  <EnabledIndicator value={member.wikiPublished} />
                </td>
              </tr>
            ))}

            <NoItemsRow show={!items.length} colSpan={6} />
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
}
