import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import type { ReactNode } from 'react';
import { envVars } from '~/environment';

export const inMemoryCache = new InMemoryCache({
  possibleTypes: {},
  typePolicies: {},
});

const v4Link = new HttpLink({
  uri: envVars.VITE_GRAPHQL_URL_V4,
  credentials: 'include',
});

export const v4Client = new ApolloClient({
  cache: inMemoryCache,
  link: v4Link,
  ssrMode: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export { gql } from '@apollo/client';

export function ApolloProviderV4({ children }: { children: ReactNode }) {
  return <ApolloProvider client={v4Client}>{children}</ApolloProvider>;
}
