import type { PureQueryOptions } from '@apollo/client';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { Button } from 'react-daisyui';
import { type BookmarkCollectionPartsFragment } from '~/apollo/generated/v3/graphql';
import { FilterSearch } from '~/components/common/FilterSearch';
import { ScaleTransition } from '~/components/common/ScaleTransition';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import type {
  Bookmark,
  BookmarkFilterableType,
} from '~/utils/modules/bookmark';
import {
  bookmarkTypeFilterMergeFn,
  bookmarkTypeOptionsSortFn,
  prettyFilterableType,
  toStandardizedBookmark,
} from '~/utils/modules/bookmark';
import { BookmarkItem } from './BookmarkItem';

type Props = {
  bookmarks: Bookmark[];
  collections: BookmarkCollectionPartsFragment[];
  refetchQueries: PureQueryOptions[];
  sortStateKey: string;
};

export function BookmarkList({
  bookmarks,
  collections,
  refetchQueries,
  sortStateKey,
}: Props) {
  const standardizedBMs = bookmarks.map(toStandardizedBookmark);

  const { items, filterSearchProps, sortIndicatorProps, isFiltered } =
    useSortFilter(
      standardizedBMs,
      'targetName',
      'targetName',
      sortStateKey,
      'desc',
      bookmarkTypeFilterMergeFn,
      bookmarkTypeOptionsSortFn,
    );

  if (!bookmarks.length) return null;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center gap-6">
        <div className="flex-grow">
          <FilterSearch
            {...filterSearchProps}
            showAlphabet={false}
            stick={false}
            renderSearch={input => <div>{input}</div>}
          />
        </div>

        <div className="flex justify-end items-center gap-1">
          <Disclosure defaultOpen={isFiltered}>
            {({ open }) => (
              <>
                <ScaleTransition show={open}>
                  <Disclosure.Panel static>
                    <div className="flex justify-end items-center gap-1">
                      <SortTrigger
                        colName="targetName"
                        sortIndicatorProps={sortIndicatorProps}
                      >
                        Name
                      </SortTrigger>
                      <SortTrigger
                        colName="insertedAt"
                        sortIndicatorProps={sortIndicatorProps}
                      >
                        Created
                      </SortTrigger>
                      <SortTrigger
                        colName="filterableType"
                        sortIndicatorProps={sortIndicatorProps}
                        filterable
                        renderFilterOption={val => {
                          if (val === 'PICTURE') return 'Picture';
                          return prettyFilterableType(
                            val as BookmarkFilterableType,
                          );
                        }}
                      >
                        Type
                      </SortTrigger>
                      <SortTrigger
                        colName="user.name"
                        sortIndicatorProps={sortIndicatorProps}
                        filterable
                      >
                        Author
                      </SortTrigger>
                      <SortTrigger
                        colName="hasNote"
                        sortIndicatorProps={sortIndicatorProps}
                        filterable
                        renderFilterOption={val => {
                          if (val === 'true') return 'Has a note';
                          return 'Does not have a note';
                        }}
                      >
                        Note
                      </SortTrigger>
                      <SortTrigger
                        colName="isInCollection"
                        sortIndicatorProps={sortIndicatorProps}
                        filterable
                        renderFilterOption={val => {
                          if (val === 'true') return 'Is in a collection';
                          return 'Is not in a collection';
                        }}
                      >
                        Collection
                      </SortTrigger>
                    </div>
                  </Disclosure.Panel>
                </ScaleTransition>

                <Disclosure.Button
                  as={Button}
                  color={open ? undefined : 'ghost'}
                  size="sm"
                  className="gap-1"
                >
                  <FontAwesomeIcon icon={faSort} /> Sort
                </Disclosure.Button>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div className="space-y-3">
        {items.map(bookmark => (
          <BookmarkItem
            key={bookmark.id}
            bookmark={bookmark}
            collections={collections}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>
    </div>
  );
}
