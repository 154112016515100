import invariant from 'tiny-invariant';
import type {
  OutcropSubregionInput,
  UpdateOutcropSubregionPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { yup } from '~/utils/validation';

export type OutcropSubregionFormValues = {
  name: string;
  description: string;
  projectId: number | null;
  outcropIds: number[];
  regionIds: number[];
  cameraLatitude: number;
  cameraLongitude: number;
  cameraHeight: number;
  cameraHeading: number;
  cameraPitch: number;
  cameraRoll: number;
};

export function outcropSubregionInitialFormValues(
  sr?: UpdateOutcropSubregionPartsFragment,
): OutcropSubregionFormValues {
  return {
    name: sr?.name ?? '',
    description: sr?.description ?? '',
    projectId: sr?.projectId ?? null,
    regionIds: sr?.regions.map(r => r.id) ?? [],
    outcropIds: sr?.outcrops.map(oc => oc.id) ?? [],
    cameraLongitude: sr?.defaultCamera?.longitude ?? 0,
    cameraLatitude: sr?.defaultCamera?.latitude ?? 0,
    cameraHeight: sr?.defaultCamera?.height ?? 0,
    cameraHeading: sr?.defaultCamera?.heading ?? 0,
    cameraPitch: sr?.defaultCamera?.pitch ?? 0,
    cameraRoll: sr?.defaultCamera?.roll ?? 0,
  };
}

export const outcropSubregionValidationSchema = yup.object({
  name: yup.string().min(1).required(),
  description: yup.string().nullable().optional(),
  projectId: yup.number().integer().positive().required(),
  outcropIds: yup.array(yup.number().integer().required()).min(1).required(),
  regionIds: yup.array(yup.number().integer().required()).min(1).required(),
  cameraLatitude: yup.number(),
  cameraLongitude: yup.number(),
  cameraHeight: yup.number(),
  cameraHeading: yup.number(),
  cameraPitch: yup.number(),
  cameraRoll: yup.number(),
});

export function outcropSubregionFormValuesToInput(
  fv: OutcropSubregionFormValues,
): OutcropSubregionInput {
  invariant(fv.projectId, 'Project ID missing!');

  return {
    name: fv.name,
    description: fv.description,
    projectId: fv.projectId,
    regionIds: fv.regionIds,
    outcropIds: fv.outcropIds,
    defaultCamera: {
      latitude: fv.cameraLatitude,
      longitude: fv.cameraLongitude,
      height: fv.cameraHeight,
      heading: fv.cameraHeading,
      pitch: fv.cameraPitch,
      roll: fv.cameraRoll,
    },
  };
}
