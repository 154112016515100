import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { RadialProgress } from 'react-daisyui';
import { pollTilingMutation } from '~/components/upload/vom/modelData/states/UploadingState';

const INTERVAL_DELAY = 1000 * 5;

export function DownloadingState({
  cesiumAssetId,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  refetchQueries: PureQueryOptions[];
}) {
  const [pollTiling, { data, loading }] = useMutation(pollTilingMutation, {
    variables: { id: cesiumAssetId },
    refetchQueries,
  });

  useEffect(() => {
    const interval = window.setInterval(pollTiling, INTERVAL_DELAY);
    pollTiling();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [cesiumAssetId, pollTiling]);

  const total = data?.cesiumAssetPollTiling?.result?.cesiumDownloadTotal;
  const todo = data?.cesiumAssetPollTiling?.result?.cesiumDownloadTodo ?? 0;
  const progress = total ? Math.floor(((total - todo) / total) * 100) : 0;

  return (
    <div className="text-center">
      <RadialProgress value={progress} color="success">
        <span className={loading ? 'text-muted' : undefined}>{progress}%</span>
      </RadialProgress>
    </div>
  );
}
